/** @jsxImportSource @emotion/react */
import { COLORS } from 'helpers/colors';
import { Positions } from 'helpers/constants';

const ToolTip = ({
  title,
  children,
  position,
}: {
  title: string;
  children: React.ReactNode;
  position?: Positions;
}) => {
  return (
    <div
      css={{
        position: 'relative',
        display: 'inline-block',
        ':hover': {
          'span:last-of-type': {
            visibility: 'visible',
            opacity: 1,
          },
        },
      }}
    >
      {children}
      <span
        css={{
          visibility: 'hidden',
          position: 'absolute',
          textAlign: 'center',
          padding: '0.313rem',
          zIndex: 1,
          opacity: 0,
          fontWeight: 500,
          lineHeight: '0.75rem',
          fontSize: '0.688rem',
          color: COLORS.white,
          transition: 'opacity 0.3s',
          background: COLORS.black3,
          boxShadow: `0.125rem 0.125rem 0.375rem ${COLORS.black3}`,
          backdropFilter: 'blur(0.5rem)',
          borderRadius: '0.125rem',
          ...((position === undefined || position === Positions.Bottom) && {
            top: '135%',
            left: '50%',
            transform: 'translateX(-50%)',
          }),
          ...(position === Positions.Right && {
            top: '0',
            left: '125%',
          }),
          ...(position === Positions.Top && {
            bottom: '125%',
            left: '50%',
            transform: 'translateX(-50%)',
          }),
          ...(position === Positions.Left && {
            top: '0',
            bottom: 'auto',
            right: '125%',
          }),
        }}
      >
        {title}
      </span>
    </div>
  );
};

export default ToolTip;
