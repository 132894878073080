/** @jsxImportSource @emotion/react */
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CXButton,
  CommonModal,
  ErrorMessageWrapper,
  SpinningLoader,
  UnsavedConfirmationModal,
  notify,
} from 'components';
import { mq } from 'helpers';
import { postDocNotes } from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import { DOC_NOTES_STAGES } from 'helpers/constants';
import { documentNotesSchema } from 'helpers/validation';
import { ISearchTableResult } from 'models/SearchData';

export interface IDocNotesForm {
  note: string;
}

const NotesModal = ({
  isOpen,
  setIsOpen,
  title,
  document,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  document: ISearchTableResult;
}): JSX.Element => {
  const [noteStage, setNoteStage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isCrossClicked, setIsCrossClicked] = useState(false);

  const { note, _id, title: documentTitle } = document;

  const defaultValues = {
    note: '',
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, touchedFields, isValid, isSubmitting },
    watch,
  } = useForm<IDocNotesForm>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(documentNotesSchema),
  });

  const handleCancel = (isCross?: boolean) => {
    if (
      (noteStage === DOC_NOTES_STAGES.VIEW_NOTES && !isCross) ||
      (noteStage !== DOC_NOTES_STAGES.VIEW_NOTES && touchedFields.note)
    ) {
      setIsConfirmationOpen(true);
    } else if (noteStage === DOC_NOTES_STAGES.EDIT_NOTES && !isCross) {
      setNoteStage(DOC_NOTES_STAGES.VIEW_NOTES);
    } else {
      setIsOpen(false);
    }
  };

  const handleSave = () => {
    if (noteStage === DOC_NOTES_STAGES.VIEW_NOTES) {
      setNoteStage(DOC_NOTES_STAGES.EDIT_NOTES);
    } else {
      void handleSubmit((data) => postSaveDocNotes(data, _id))();
    }
  };

  const handleConfirmation = async (isConfirm: boolean) => {
    setIsConfirmationOpen(false);
    if (isConfirm) {
      if (noteStage === DOC_NOTES_STAGES.VIEW_NOTES && isValid) {
        await handleSubmit(() =>
          postSaveDocNotes(
            {
              note: '',
            },
            _id,
          ),
        )();
      } else if (noteStage === DOC_NOTES_STAGES.EDIT_NOTES && !isCrossClicked) {
        reset({
          note,
        });
        setNoteStage(DOC_NOTES_STAGES.VIEW_NOTES);
      } else {
        setIsOpen(false);
      }
    }
  };

  const postSaveDocNotes = async (
    docData: IDocNotesForm,
    documentId: string,
  ) => {
    const resp = await postDocNotes({
      documentId: documentId,
      note: docData.note,
    });

    if (resp && resp.result) {
      notify({
        message: docData.note
          ? 'Saved document notes'
          : 'Deleted document notes',
        type: 'success',
      });
      if (noteStage === DOC_NOTES_STAGES.ADD_NOTES) {
        setNoteStage(DOC_NOTES_STAGES.VIEW_NOTES);
      } else {
        setIsOpen(false);
      }
    } else {
      notify({
        message: resp.errMsg
          ? resp.errMsg
          : `Cannot ${docData.note ? 'Save' : 'Delete'} document notes`,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setIsCrossClicked(false);
    if (!note) {
      setNoteStage(DOC_NOTES_STAGES.ADD_NOTES);
      reset({
        note: '',
      });
    } else {
      setNoteStage(DOC_NOTES_STAGES.VIEW_NOTES);
      reset({
        note,
      });
    }
  }, [note, isOpen]);

  const hasError = !!get(errors, 'note');

  return (
    <CommonModal
      isOpen={isOpen}
      setIsOpen={() => {
        setIsCrossClicked(true);
        handleCancel(true);
      }}
      title={title}
    >
      <div
        css={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'column' }}
      >
        <form
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span css={{ fontWeight: '700', fontSize: '1.5rem' }}>
            {documentTitle}
          </span>
          <textarea
            {...register('note')}
            disabled={noteStage === DOC_NOTES_STAGES.VIEW_NOTES}
            css={{
              background: COLORS.white,
              border: `0.063rem solid ${COLORS.grey18}`,
              borderRadius: '0.25rem',
              padding: '0.625rem',
              marginTop: '1.25rem',
              height: '12.5rem',
              resize: 'none',
              ':focus-visible': {
                outline: 'none',
              },
              ...(noteStage === DOC_NOTES_STAGES.VIEW_NOTES && {
                background: COLORS.white4,
              }),
              ...(hasError && {
                border: `0.125rem solid ${COLORS.red}`,
              }),
            }}
            placeholder="Add notes to your document here..."
          ></textarea>
          {errors && <ErrorMessageWrapper errors={errors} dataField="note" />}
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '2.5rem',
              justifyContent: 'flex-end',
              [mq[3]]: {
                flexDirection: 'column',
              },
            }}
          >
            {!isSubmitting && (
              <>
                <CXButton
                  variant="primary-outline"
                  type="button"
                  onClick={() => handleCancel()}
                  customStyles={{
                    fontSize: '0.813rem',
                    [mq[3]]: {
                      width: '100%',
                    },
                  }}
                >
                  {noteStage === DOC_NOTES_STAGES.VIEW_NOTES
                    ? 'Delete'
                    : noteStage === DOC_NOTES_STAGES.EDIT_NOTES
                    ? 'Back'
                    : 'Cancel'}
                </CXButton>

                <div css={{ marginRight: '1rem' }} />
                <CXButton
                  variant="primary"
                  type="button"
                  disabled={
                    noteStage === DOC_NOTES_STAGES.ADD_NOTES &&
                    watch('note').length === 0
                  }
                  onClick={handleSave}
                  customStyles={{
                    fontSize: '0.813rem',
                    [mq[3]]: {
                      marginTop: '1rem',
                      width: '100%',
                    },
                  }}
                >
                  {noteStage === DOC_NOTES_STAGES.VIEW_NOTES ? 'Edit' : 'Save'}
                </CXButton>
              </>
            )}

            {isSubmitting && (
              <SpinningLoader
                customTextStyles={{
                  fontStyle: 'italic',
                }}
              />
            )}
          </div>
        </form>
      </div>
      <UnsavedConfirmationModal
        isOpen={isConfirmationOpen}
        setIsOpen={handleConfirmation}
        title={`${
          noteStage === DOC_NOTES_STAGES.VIEW_NOTES ? 'Delete' : 'Unsaved'
        } notes`}
        confirmationText={
          noteStage === DOC_NOTES_STAGES.VIEW_NOTES
            ? 'Are you sure you want to delete this notes?'
            : 'Are you sure you want to leave?'
        }
        infoText={
          noteStage === DOC_NOTES_STAGES.VIEW_NOTES
            ? ''
            : 'Your content has not been properly saved yet!'
        }
        confirmBtnText={
          noteStage === DOC_NOTES_STAGES.VIEW_NOTES ? 'Delete' : 'Leave'
        }
      />
    </CommonModal>
  );
};

export default NotesModal;
