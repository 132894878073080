/** @jsxImportSource @emotion/react */
import { CSSProperties } from '@emotion/serialize';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CXButton,
  CheckBox,
  CommonModal,
  FormTextBox,
  RadioButton,
} from 'components';
import { mq } from 'helpers';
import { postUserRoleCheck } from 'helpers/apiCaller';
import {
  ADD_USER_ROLE_STAGES,
  PATTERNS,
  USER_GROUP,
  USER_ROLE,
} from 'helpers/constants';
import { IUserRoleSearchResultData } from 'models/SearchData';

interface IUserRoleAddForm {
  userRoles: string[];
  userGroup: string;
  newUser: string;
}

const CommonDisplayItem = ({
  label,
  value,
  customStyles,
}: {
  label: string;
  value: string;
  customStyles?: CSSProperties;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        ...customStyles,
      }}
    >
      <span
        css={{
          fontWeight: '400',
          fontSize: '1.5rem',
          marginBottom: '1rem',
        }}
      >
        {label}
      </span>
      <span>{value}</span>
    </div>
  );
};

const UserRoleModal = ({
  isOpen,
  setIsOpen,
  submitUserRole,
  isAddUser,
  editUser,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  submitUserRole: ({
    userRoles,
    userGroup,
    newUser,
  }: {
    userRoles: string[];
    userGroup: string;
    newUser?: string;
  }) => void;
  isAddUser?: boolean;
  editUser?: IUserRoleSearchResultData;
}): JSX.Element => {
  const [validNewUser, setValidNewUser] = useState('');
  const [addUserRoleStage, setAddUserRoleStage] = useState<string>('');

  const defaultValues = {
    userGroup: USER_GROUP.NONE.value,
    userRoles: [],
    newUser: '',
  };

  const { handleSubmit, control, getValues, setError, reset, register } =
    useForm<IUserRoleAddForm>({
      defaultValues: defaultValues,
    });

  const userRoleKeys = Object.keys(USER_ROLE);

  const checkUserRole = async (data: IUserRoleAddForm) => {
    if (data.newUser) {
      const resp = await postUserRoleCheck(data.newUser);
      if (resp.name) {
        setValidNewUser(resp.name);
      } else if (resp.errMsg) {
        setError('newUser', {
          message: resp.errMsg,
        });
      }
    }
  };

  useEffect(() => {
    setValidNewUser('');
    if (isAddUser) {
      reset(defaultValues);
      setAddUserRoleStage(ADD_USER_ROLE_STAGES.VERIFY_USER);
    } else if (editUser) {
      reset({
        userGroup: editUser.group,
        userRoles: editUser.roles,
      });
      setAddUserRoleStage('');
    }
  }, [editUser?.galacxyId, isOpen]);

  useEffect(() => {
    if (isAddUser) {
      validNewUser
        ? setAddUserRoleStage(ADD_USER_ROLE_STAGES.ALLOW_ADD)
        : setAddUserRoleStage(ADD_USER_ROLE_STAGES.VERIFY_USER);
    }
  }, [validNewUser]);

  return (
    <CommonModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={isAddUser ? 'Add new user' : editUser?.name || ''}
    >
      <div
        css={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'column' }}
      >
        {addUserRoleStage === ADD_USER_ROLE_STAGES.VERIFY_USER && (
          <>
            <form onSubmit={handleSubmit(checkUserRole)}>
              <span css={{ fontWeight: '400', fontSize: '1.5rem' }}>
                Enter full GalaCXy ID
              </span>
              <div
                css={{
                  position: 'relative',
                }}
              >
                <FormTextBox
                  label="Please enter GalaCXyID eg ENGAAA"
                  dataField="newUser"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please input GalaCXyID',
                    },
                    pattern: {
                      value: PATTERNS.VALID_UPPERCASE,
                      message: 'GalaCXy ID should be in uppercase',
                    },
                  }}
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '2.5rem',
                }}
              >
                <div css={{ flex: 1 }} />
                <CXButton
                  variant="primary-outline"
                  type="submit"
                  customStyles={{
                    fontSize: '0.813rem',
                    [mq[2]]: {
                      width: '100%',
                    },
                  }}
                >
                  Next
                </CXButton>
              </div>
            </form>
          </>
        )}

        {addUserRoleStage === ADD_USER_ROLE_STAGES.ALLOW_ADD && (
          <>
            <div
              css={{
                display: 'flex',
                marginBottom: '2rem',
              }}
            >
              <CommonDisplayItem
                label="GalaCXyID"
                value={getValues().newUser}
                customStyles={{
                  marginRight: '2rem',
                }}
              />
              <CommonDisplayItem label="Name" value={validNewUser} />
            </div>
          </>
        )}

        {(addUserRoleStage === ADD_USER_ROLE_STAGES.ALLOW_ADD ||
          !isAddUser) && (
          <>
            <span css={{ fontWeight: '400', fontSize: '1.5rem' }}>
              Select the roles for this user
            </span>
            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '70%',
                '& span': {
                  flexBasis: '50%',
                  marginBottom: '10px',
                  [mq[1]]: {
                    flexBasis: '100%',
                  },
                },
              }}
            >
              {userRoleKeys
                .filter((key) =>
                  USER_ROLE[key].isShowInRoleAssignment !== undefined
                    ? USER_ROLE[key].isShowInRoleAssignment
                    : true,
                )
                .sort()
                .map((key) => {
                  const value = USER_ROLE[key].name;
                  const label = USER_ROLE[key].gridDisplay;
                  return (
                    <span key={key}>
                      <CheckBox
                        label={label}
                        value={value}
                        defaultChecked={
                          (editUser?.roles || defaultValues.userRoles).indexOf(
                            value,
                          ) > -1
                        }
                        {...register('userRoles')}
                      />
                    </span>
                  );
                })}
            </div>

            <span
              css={{
                fontWeight: '400',
                fontSize: '1.5rem',
                marginBottom: '0.2rem',
              }}
            >
              Select the user group for this user
            </span>
            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '70%',
                '& div': {
                  flexBasis: '50%',
                  marginBottom: '10px',
                  [mq[1]]: {
                    flexBasis: '100%',
                  },
                },
              }}
            >
              {Object.keys(USER_GROUP).map((key) => (
                <RadioButton
                  value={USER_GROUP[key].value}
                  key={key}
                  id={key}
                  label={USER_GROUP[key].display}
                  defaultChecked={
                    (editUser?.group || defaultValues.userGroup) ===
                    USER_GROUP[key].value
                  }
                  {...register('userGroup')}
                />
              ))}
            </div>

            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '2.5rem',
                [mq[1]]: {
                  flexDirection: 'column',
                },
              }}
            >
              {addUserRoleStage === ADD_USER_ROLE_STAGES.ALLOW_ADD && (
                <CXButton
                  variant="primary-outline"
                  type="button"
                  customStyles={{
                    fontSize: '0.813rem',
                    [mq[1]]: {
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    setValidNewUser('');
                  }}
                >
                  Back
                </CXButton>
              )}
              <div css={{ flex: 1 }} />
              <CXButton
                variant="primary"
                type="button"
                customStyles={{
                  fontSize: '0.813rem',
                  [mq[1]]: {
                    marginTop: '1rem',
                    width: '100%',
                  },
                }}
                onClick={() => {
                  setIsOpen(false);
                  submitUserRole({ ...getValues() });
                }}
              >
                Save {isAddUser ? 'and add user' : null}
              </CXButton>
            </div>
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default UserRoleModal;
