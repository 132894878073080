/** @jsxImportSource @emotion/react */
import { jsx, keyframes } from '@emotion/react';
import { CSSProperties } from '@emotion/serialize';

import { mq } from 'helpers';

import loadingIcon from 'assets/images/icon_loading.png';
import loadingIconNew from 'assets/images/icon_loading_new.png';

const SpinningLoader = ({
  loaderText,
  isNewLoadingIcon,
  customStyles = {},
  customTextStyles = {},
}: {
  loaderText?: string;
  isNewLoadingIcon?: boolean;
  customStyles?: CSSProperties;
  customTextStyles?: CSSProperties;
}): JSX.Element => {
  const rotation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

  customStyles = isNewLoadingIcon
    ? ({
        alignItems: 'center',
        flex: 1,
        marginTop: '6.125rem',
        [mq[1]]: {
          marginTop: '1rem',
        },
      } as CSSProperties)
    : customStyles;

  customTextStyles = isNewLoadingIcon
    ? ({
        fontSize: '1rem',
        marginTop: '0.5rem',
      } as CSSProperties)
    : customTextStyles;

  return (
    <div css={{ display: 'flex', flexDirection: 'column', ...customStyles }}>
      <img
        src={isNewLoadingIcon ? loadingIconNew : loadingIcon}
        alt="Loading Icon"
        css={{
          width: '38px',
          height: '38px',
          animation: `${rotation} 1s infinite`,
        }}
      />
      <span css={{ ...customTextStyles }}>
        {loaderText ? loaderText : null}
      </span>
    </div>
  );
};

export default SpinningLoader;
