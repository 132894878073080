/** @jsxImportSource @emotion/react */
import { ErrorMessage as ErrorMessageContainer } from '@hookform/error-message';
import { FieldErrorsImpl } from 'react-hook-form';

import { COLORS } from 'helpers/colors';

const ErrorMessageWrapper = ({
  errors,
  dataField,
}: {
  errors: FieldErrorsImpl;
  dataField: string;
}) => {
  return (
    <div>
      <ErrorMessageContainer
        errors={errors}
        name={dataField}
        render={({ message }: { message: string }) => (
          <span css={{ fontSize: '0.75rem', color: COLORS.red }}>
            {message}
          </span>
        )}
      />
    </div>
  );
};

export default ErrorMessageWrapper;
