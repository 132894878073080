/** @jsxImportSource @emotion/react */
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import {
  ApprovalStatus,
  CXButton,
  CheckBox,
  CommonContainer,
  CustomLabel,
  PreviewPdf,
  RejectModal,
  SpinningLoader,
  Svg,
  TextBox,
  UploadDocumentModal,
  VideoPlayer,
  notify,
} from 'components';
import { downloadFile, getUrlPath, isViewerDetailDocType, mq } from 'helpers';
import {
  getApprovalDetail,
  getAuthorDocDetail,
  getDocument,
  getViewerDocDetail,
  postApproval,
  postCheckDocument,
} from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import {
  APPROVAL_DETAIL_VIEW_MODE,
  ATTACHMENT_LINK_TEXT,
  AUDIENCE_GROUP_ARR,
  CUSTOM_LABELS,
  DOCUMENT_TYPE,
  LOADING,
  RISK_ASSESSMENT_ARR,
  URL_PATH,
  WORK_FLOW_STATUS,
} from 'helpers/constants';
import {
  formatInDaysHoursMins,
  formatToFullDate,
  formatToFullDateTime,
} from 'helpers/date';
import { IDocDetail } from 'models/SearchData';
import {
  CancelFlowSection,
  PreviewDocModal,
} from 'screens/ApprovalDetail/components';
import ProfileContext from 'store/ProfileContext';

const ApprovalDetail = ({
  isApprover,
  isAuthor,
  userGroup,
}: {
  isApprover?: boolean;
  isAuthor?: boolean;
  userGroup?: string;
}): JSX.Element => {
  const { id } = useParams();
  const { isShowSidebar } = useContext(ProfileContext);
  const [data, setData] = useState<IDocDetail>();
  const [file, setFile] = useState<Blob>();
  const [isConfirm, setIsConfirm] = useState(false);
  const [documentCheck, setDocumentCheck] = useState({
    submitted: false,
    checked: false,
  });
  const [rejectReason, setRejectReason] = useState<string>('');
  const [newDocNum, setNewDocNum] = useState<string>('');
  const [submitTime, setSubmitTime] = useState(0);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isPreviewDocModalOpen, setIsPreviewDocModalOpen] = useState(false);
  const [isInValid, setIsInValid] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const urlPath = getUrlPath({
    isApprover,
    isViewer: !isAuthor && !isApprover,
  });

  useEffect(() => {
    const getData = async () => {
      if (id) {
        let resp;
        if (isAuthor) {
          resp = await getAuthorDocDetail(id, userGroup);
        } else if (isApprover) {
          resp = await getApprovalDetail(id);
        } else {
          resp = await getViewerDocDetail(id, userGroup);
        }

        if (resp) {
          setData(resp);
        } else {
          setIsInValid(true);
        }
      }
    };

    void getData();
  }, [id, isAuthor, submitTime, isFormModalOpen]);

  useEffect(() => {
    const getData = async () => {
      if (data?.fileName && !isViewerDetailDocType(data.docType)) {
        const fileResp = await getDocument({
          fileName: data.fileName,
          isApprover,
          isAuthor,
          userGroup,
        });

        if (!fileResp) {
          return;
        }

        setFile(fileResp);
      }
    };

    void getData();
  }, [data?.fileName]);

  let ataSubchapter = '';

  if (data?.ata.section) {
    ataSubchapter += data?.ata.section;
  }

  if (data?.ata.page) {
    ataSubchapter += `-${data?.ata.page}`;
  }

  if (data?.ata.figure) {
    ataSubchapter += `-${data?.ata.figure}`;
  }

  const RiskAssessAttachmentDisplayBox = ({
    title,
    dataText,
    attachmentFile,
  }: {
    title: string;
    dataText: string;
    attachmentFile: string;
  }) => (
    <div css={{ display: 'flex', flexDirection: 'column' }}>
      <span css={{ fontWeight: '700' }}>{title}</span>
      <span>
        {dataText.replace(ATTACHMENT_LINK_TEXT, '')}
        <a
          href={`/${urlPath}/download/riskAccessAttachment/${attachmentFile}`}
          target="_blank"
        >
          <span css={{ color: COLORS.blue5, fontWeight: 'bold' }}>
            {ATTACHMENT_LINK_TEXT}
          </span>
        </a>
      </span>
    </div>
  );

  const CommonDisplayBox = ({
    title,
    dataText,
    customStyles,
  }: {
    title: string;
    dataText: string;
    customStyles?: CSSProperties;
  }) => (
    <div css={{ display: 'flex', flexDirection: 'column', ...customStyles }}>
      <span css={{ fontWeight: '700' }}>{title}</span>
      <span>{dataText}</span>
    </div>
  );

  const CommonLabel = ({
    labelType,
    customStyles,
  }: {
    labelType: {
      name: string;
      label: string;
      backgroundColor: string;
    };
    customStyles?: CSSProperties;
  }) => (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: '1rem',
        [mq[2]]: {
          marginTop: '0.2rem',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <CustomLabel customStyles={customStyles} labelType={labelType} />
        <span css={{ marginLeft: '0.3rem' }}>{labelType.name}</span>
      </div>
      <div />
    </div>
  );

  let riskAssessmentText = '';

  if (data?.riskAssessment) {
    const obj = RISK_ASSESSMENT_ARR.find(
      (x) => x.value === data.riskAssessment,
    );

    if (obj) {
      riskAssessmentText = obj.name;
    }
  }

  const submitApproval = async (isApproved: boolean, approveType = '') => {
    if (id) {
      const result = await postApproval(
        id,
        isApproved,
        rejectReason,
        newDocNum,
      );

      let message = '';

      if (result) {
        if (approveType === APPROVAL_DETAIL_VIEW_MODE.ASSIGN_DOC_NUM) {
          message = `Number assigned successfully`;
        } else if (approveType === APPROVAL_DETAIL_VIEW_MODE.PUBLISH) {
          message = `Document published successfully`;
        } else {
          message = `Document has been successfully ${
            isApproved ? 'approved' : 'rejected'
          }`;
        }

        setIsConfirm(false);
        setSubmitTime(submitTime + 1);
      } else {
        message = `Unable to ${isApproved ? 'approve' : 'reject'} the document`;
      }

      const type = result ? 'success' : 'error';

      notify({
        message,
        type,
      });
    }
  };

  const submitAssignNum = async () => {
    if (newDocNum) {
      const result = await postCheckDocument(data?.docType || '', newDocNum);
      if (result) {
        setDocumentCheck({
          submitted: true,
          checked: true,
        });
        setIsPreviewDocModalOpen(true);
      } else {
        setDocumentCheck({
          submitted: true,
          checked: false,
        });
      }
    }
  };

  const triggerDownloadFile = () => {
    if (file) {
      downloadFile({ file, fileName: data?.fileName });
    } else {
      notify({
        message: 'File not available',
        type: 'error',
      });
    }
  };

  if (isInValid) {
    return <CommonContainer>Invalid Document Id</CommonContainer>;
  }

  const NO_RISK_ASSESS_TEXT = RISK_ASSESSMENT_ARR.find(
    (r) => r.value === 'N',
  )?.name;

  let audienceGroupTextArr;
  if (data?.audienceGroupArr) {
    audienceGroupTextArr = data.audienceGroupArr.map(
      (a) => AUDIENCE_GROUP_ARR.find((obj) => obj.value === a)?.name,
    );
  }

  const isAogDoc = data?.docType === DOCUMENT_TYPE.AOG.name;

  return (
    <CommonContainer>
      {data ? (
        <div
          css={{
            display: 'flex',
            flex: 1,
            padding: '1rem 1rem 6rem 1rem',
            flexDirection: 'column',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '0.875rem',
            }}
          >
            <NavLink to={'/' + (urlPath !== URL_PATH.VIEWER ? urlPath : '')}>
              <span
                css={{
                  color: COLORS.blue5,
                  textTransform: 'capitalize',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {urlPath} List
              </span>
            </NavLink>

            <span
              css={{
                marginLeft: '0.5rem',
                color: COLORS.grey14,
                fontWeight: 'bold',
              }}
            >
              /
            </span>
            <span css={{ marginLeft: '0.5rem' }}>{data.title}</span>
          </div>
          <h1
            css={{
              marginTop: '0.875rem',
              fontSize: '2rem',
              fontWeight: 'bold',
              color: data.cancelStatus ? COLORS.red : COLORS.grey3,
            }}
          >
            {data.title}
          </h1>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              [mq[2]]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
            }}
          >
            <div
              css={{
                fontSize: '1rem',
                display: 'flex',
                flexDirection: 'row',
                [mq[2]]: {
                  flexDirection: 'column',
                },
              }}
            >
              <div css={{ marginRight: '1rem' }}>
                <span>Effective from </span>
                <span css={{ fontWeight: '700', marginLeft: '0.5rem' }}>
                  {formatToFullDate(data.effectiveFrom)}
                </span>
              </div>
              {
                <div>
                  <span css={{ marginRight: '0.5rem' }}>Expires on</span>
                  <span css={{ fontWeight: '700' }}>
                    {data.effectiveTo
                      ? formatToFullDate(data.effectiveTo)
                      : '--'}
                  </span>
                </div>
              }
            </div>
            <div css={{ flex: 1, [mq[2]]: { display: 'none' } }} />
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                [mq[2]]: { marginTop: '1rem', fontSize: '0.875rem' },
              }}
            >
              <span>By</span>
              <span
                css={{
                  marginLeft: '0.5rem',
                  color: COLORS.blue5,
                  fontWeight: '500',
                }}
              >
                {data.creator}
              </span>
              <div css={{ marginLeft: '1rem' }}>
                {(data.cancellationStatus || data.approvalStatus) && (
                  <ApprovalStatus
                    values={
                      data.cancellationStatus
                        ? data.cancellationStatus
                        : data.approvalStatus
                    }
                    isExpired={data.isExpired}
                    status={data.status}
                    cancelStatus={data.cancelStatus}
                    isCancellation={!!data.cancellationStatus}
                  />
                )}
              </div>
            </div>
            {data.isAbleEdit && (
              <span
                role="presentation"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsFormModalOpen(true);
                }}
              >
                <Svg icon="edit" />
              </span>
            )}
            <UploadDocumentModal
              isOpen={isFormModalOpen}
              setIsOpen={setIsFormModalOpen}
              isEdit={true}
              editFormData={{
                documentId: data._id,
                publication: data.docType,
                isUrgent: data.isUrgent,
                documentNumber: data.documentNumber,
                documentTitle: data.title,
                description: data.description,
                reasonsForIssue: data.reasonsForIssue,
                operatorArr: data.operatorArr,
                fleetArr: data.fleetArr,
                ataChapterArr: data.ata.chapterArr,
                ataSection: data.ata.section,
                ataPage: data.ata.page,
                ataFigure: data.ata.figure,
                isMorRelated: data.isMorRelated,
                isAdRelated: data.isAdRelated,
                isDelayDriver: data.isDelayDriver,
                isAogDriver: data.isAogDriver,
                isCxCabinManual: data.isCxCabinManual,
                isPcrCic: data.isPcrCic,
                isPermanent: data.isPermanent,
                affectedProcess: data.affectedProcess,
                riskAssessment: data.riskAssessment,
                assignee: data.assignee,
                fileName: data.fileName,
                riskAttachmentFileName: data.riskAttachmentFileName,
                riskAssessmentReason: data.riskAssessmentReason,
                supersedeArr: data.supersedeArr,
                audienceGroupArr: data.audienceGroupArr,
                effectiveAt: data.effectiveFrom.toString(),
                applicability: data.applicability,
                tempAmendYears: data.tempAmendYears,
                tempAmendMonths: data.tempAmendMonths,
              }}
            />
          </div>
          <CancelFlowSection
            data={data}
            isAuthor={isAuthor}
            setSubmitTime={() => {
              setSubmitTime(submitTime + 1);
            }}
          />
          {data.supersedeArr.length > 0 && (
            <div
              css={{
                marginTop: '1.5rem',
                backgroundColor: COLORS.green3,
                paddingLeft: '0.75rem',
                paddingTop: '0.875rem',
                paddingBottom: '0.875rem',
              }}
            >
              <span>Supersedes</span>
              <span css={{ marginLeft: '1.25rem', fontWeight: '600' }}>
                {data.supersedeArr.join(', ')}
              </span>
            </div>
          )}
          <div
            css={{
              marginTop: '1.6rem',
              display: 'flex',
              flexDirection: 'row',
              [mq[1]]: { flexDirection: 'column-reverse' },
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flex: 2,
                [mq[2]]: { display: 'flex', flexDirection: 'column' },
              }}
            >
              <div css={{ display: 'flex', flex: '0.5' }}>
                <CommonDisplayBox
                  customStyles={{
                    flex: '1',
                    [mq[2]]: {
                      flex: '0.5',
                      marginBottom: '1rem',
                    },
                  }}
                  title="Doc Type"
                  dataText={data.docType}
                />
                {data.documentNumber && (
                  <CommonDisplayBox
                    customStyles={{
                      flex: '1',
                    }}
                    title="Doc Num"
                    dataText={data.documentNumber || '--'}
                  />
                )}
              </div>
              <div css={{ display: 'flex', flex: '1' }}>
                <CommonDisplayBox
                  customStyles={{
                    flex: '1',
                  }}
                  title="ATA"
                  dataText={data.ata.chapterArr?.join(', ') || ''}
                />
                {isAogDoc ? (
                  <>
                    <CommonDisplayBox
                      customStyles={{
                        flex: '1',
                      }}
                      title="AOG Hours"
                      dataText={
                        data.aogDelayTimeMin
                          ? formatInDaysHoursMins(data.aogDelayTimeMin)
                          : '--'
                      }
                    />
                    <CommonDisplayBox
                      customStyles={{
                        flex: '1',
                      }}
                      title="A/C Reg"
                      dataText={data.aogTailRegistration || '--'}
                    />
                  </>
                ) : (
                  <>
                    <CommonDisplayBox
                      customStyles={{
                        flex: '2',
                      }}
                      title="Section/Page/Fig"
                      dataText={ataSubchapter || '--'}
                    />
                    <CommonDisplayBox
                      customStyles={{
                        flex: '1',
                      }}
                      title="Effectivity"
                      dataText={data.operatorArr.join(', ')}
                    />
                  </>
                )}
              </div>
            </div>
            <div
              css={{
                flex: 0.8,
                display: 'flex',
                [mq[1]]: {
                  margin: '1rem 0rem',
                },
                [mq[2]]: {
                  margin: '1rem 0rem',
                  fontSize: '0.75rem',
                },
              }}
            >
              <div
                css={{
                  flex: 1,
                  [mq[1]]: { display: 'none' },
                }}
              />
              <div
                css={{
                  display: 'flex',
                  flexFlow: 'wrap',
                  width: '40%',
                  flexBasis: 'content',
                  [mq[1]]: { width: '100%' },
                }}
              >
                {data.isMorRelated ? (
                  <CommonLabel labelType={CUSTOM_LABELS.MOR_RELATED} />
                ) : null}
                {data.isAdRelated ? (
                  <CommonLabel labelType={CUSTOM_LABELS.AD_RELATED} />
                ) : null}
                {data.isDelayDriver ? (
                  <CommonLabel labelType={CUSTOM_LABELS.DELAY_DRIVER} />
                ) : null}
                {data.isAogDriver ? (
                  <CommonLabel
                    customStyles={{
                      fontSize: '0.75rem',
                      width: 'auto',
                      color: COLORS.black,
                    }}
                    labelType={CUSTOM_LABELS.AOG_DRIVER}
                  />
                ) : null}
                {data.aogIsRtb ? (
                  <CommonLabel
                    customStyles={{
                      fontSize: '0.75rem',
                      width: 'auto',
                      padding: '0.2rem',
                    }}
                    labelType={CUSTOM_LABELS.RETURN_TO_BLOCK}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div
            css={{
              display: 'flex',
            }}
          >
            <div
              css={{
                display: 'flex',
                flex: 1,
                [mq[2]]: {
                  flexDirection: 'column',
                },
              }}
            >
              <div css={{ marginTop: '1.5rem', flex: 1.8 }}>
                <CommonDisplayBox
                  title="Fleet Type"
                  dataText={data.fleetArr.join(', ')}
                />
              </div>
              {isAogDoc && (
                <div
                  css={{
                    display: 'flex',
                    flex: 2,
                  }}
                >
                  <div
                    css={{
                      marginTop: '1.5rem',
                      flex: 1.5,
                      [mq[1]]: {
                        flex: 0.6,
                      },
                    }}
                  >
                    <CommonDisplayBox
                      title="Flight number"
                      dataText={data.aogFlightNumber || '--'}
                    />
                  </div>
                  <div css={{ marginTop: '1.5rem', flex: 1 }}>
                    <CommonDisplayBox
                      title="Station"
                      dataText={data.aogStation || '--'}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              css={{
                flex: 1,
                [mq[1]]: {
                  flex: 0.1,
                },
              }}
            />
          </div>

          {data.docType === DOCUMENT_TYPE.TR.name && (
            <div
              css={{
                marginTop: '1.5rem',
                display: 'flex',
                [mq[1]]: { flexDirection: 'column-reverse' },
              }}
            >
              <div css={{ flex: '1' }}>
                <span css={{ fontWeight: '700' }}>
                  Temporary amendment to be removed after {data.tempAmendYears}{' '}
                  Years {data.tempAmendMonths} Months
                </span>
              </div>
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <CheckBox
                  name="cxCabinManual"
                  label="CX Cabin Manual"
                  defaultChecked={data.isCxCabinManual}
                />
                <CheckBox
                  name="prcCic"
                  label="PCR/CIC/COC"
                  defaultChecked={data.isPcrCic}
                />
                <CheckBox
                  name="permanent"
                  label="Permanent"
                  defaultChecked={data.isPermanent}
                />
              </div>
            </div>
          )}

          <div css={{ marginTop: '1.5rem' }}>
            <CommonDisplayBox
              title="Description"
              dataText={data.description || '--'}
            />
          </div>
          {(isAuthor || isApprover) && (
            <>
              <div css={{ marginTop: '1.5rem' }}>
                <CommonDisplayBox
                  title="Reason"
                  dataText={data.reasonsForIssue || '--'}
                />
              </div>
              {data.affectedProcess && (
                <div css={{ marginTop: '1.5rem' }}>
                  <CommonDisplayBox
                    title="Affected Processes and Sections"
                    dataText={data.affectedProcess || '--'}
                  />
                </div>
              )}
              <div css={{ marginTop: '1.5rem' }}>
                <CommonDisplayBox
                  title="Audience/Recipient Groups"
                  dataText={audienceGroupTextArr?.join(', ') || '--'}
                />
              </div>
              {riskAssessmentText !== NO_RISK_ASSESS_TEXT &&
              data.riskAttachmentFileName ? (
                <div css={{ marginTop: '1.5rem' }}>
                  <RiskAssessAttachmentDisplayBox
                    title="Risk Assessment"
                    dataText={riskAssessmentText}
                    attachmentFile={data.riskAttachmentFileName}
                  />
                </div>
              ) : (
                <div css={{ marginTop: '1.5rem' }}>
                  <CommonDisplayBox
                    title="Risk Assessment"
                    dataText={riskAssessmentText || '--'}
                  />
                </div>
              )}
              {data.riskAssessmentReason ? (
                <div css={{ marginTop: '1.5rem' }}>
                  <CommonDisplayBox
                    title="Reason (if no risk assessment required)"
                    dataText={data.riskAssessmentReason}
                  />
                </div>
              ) : null}
            </>
          )}

          {!isViewerDetailDocType(data.docType) && data.fileName && (
            <>
              <div css={{ marginTop: '2.6rem' }}>
                <a
                  href={`/${urlPath}/download/${data.fileName}`}
                  target="_blank"
                >
                  <span css={{ color: COLORS.blue5, fontWeight: 'bold' }}>
                    View PDF <Svg icon="expand-window" />
                  </span>
                </a>
              </div>
              <PreviewPdf file={file} isShowSidebar={isShowSidebar} />
              {data.approvalArr.length > 0 ? (
                <>
                  <div css={{ marginTop: '1.5rem' }}>
                    <div
                      css={{
                        height: '1px',
                        backgroundColor: COLORS.grey8,
                      }}
                    />
                  </div>
                  <div css={{ marginBottom: '1.5rem' }}>
                    {data.approvalArr.map((x, index) => {
                      const actionText = x.isApproved
                        ? 'acknowledged and approved'
                        : 'rejected';

                      let roleText = '';

                      const workflowArr = Object.values(WORK_FLOW_STATUS);
                      const obj = workflowArr.find((y) => y.code === x.stage);

                      if (obj && obj.fullName) {
                        roleText = obj.fullName;
                      }

                      return (
                        <div
                          key={index}
                          css={{
                            marginTop: '1rem',
                            display: 'flex',
                            flexDirection: 'row',
                            [mq[2]]: {
                              flexDirection: 'column',
                            },
                          }}
                        >
                          <div
                            css={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span
                              css={{ fontWeight: '700' }}
                            >{`This document was ${actionText} by ${x.createdBy} (${roleText})`}</span>
                            {x.comment ? (
                              <span css={{ marginTop: '0.5rem' }}>
                                {x.comment}
                              </span>
                            ) : null}
                          </div>
                          <div css={{ flex: 1 }} />
                          <span>{formatToFullDateTime(x.createdAt)}</span>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </>
          )}

          {data.videoUrl && (
            <div css={{ marginTop: '1rem' }}>
              <VideoPlayer sourceUrl={data.videoUrl} />
            </div>
          )}

          {data.assignee && (
            <div
              css={{
                marginTop: '1rem',
              }}
            >
              <span>To be approved by: {data.assignee}</span>
            </div>
          )}
          {data.viewMode === APPROVAL_DETAIL_VIEW_MODE.APPROVE_REJECT ? (
            <>
              <div
                css={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  [mq[2]]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <CheckBox
                  name="isConfirm"
                  label="By checking this box I confirm to approve the content of this document and sign off for onward action"
                  defaultChecked={isConfirm}
                  onChange={(e) => {
                    setIsConfirm((e.target as HTMLInputElement).checked);
                  }}
                />

                <div css={{ flex: 1, [mq[1]]: { paddingRight: '1.25rem' } }} />
                <CXButton
                  variant="primary"
                  type="button"
                  customStyles={{
                    [mq[2]]: {
                      width: '100%',
                    },
                  }}
                  disabled={!isConfirm}
                  onClick={async () => {
                    await submitApproval(true);
                  }}
                >
                  Approve
                </CXButton>
              </div>
            </>
          ) : null}
          {data.viewMode === APPROVAL_DETAIL_VIEW_MODE.ASSIGN_DOC_NUM ? (
            <>
              <div
                css={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: '1rem',
                  justifyContent: 'flex-end',
                  [mq[2]]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <div
                  css={{
                    width: '20%',
                    [mq[1]]: { width: '30%' },
                    [mq[2]]: { width: '100%' },
                  }}
                >
                  <TextBox
                    name="newDocNum"
                    label={data.docType + ' Number'}
                    hasError={documentCheck.submitted && !documentCheck.checked}
                    onChange={(e) => {
                      setNewDocNum((e.target as HTMLInputElement).value);
                    }}
                  />

                  {documentCheck.submitted && !documentCheck.checked ? (
                    <small
                      css={{
                        color: COLORS.red,
                        position: 'absolute',
                      }}
                    >
                      *Number already in use, please try another
                    </small>
                  ) : null}
                </div>
                <div
                  css={{
                    flex: 0,
                    paddingLeft: '3rem',
                  }}
                />
                <PreviewDocModal
                  isOpen={isPreviewDocModalOpen}
                  setIsOpen={setIsPreviewDocModalOpen}
                  documentType={data.docType}
                  documentId={data._id}
                  newDocNum={newDocNum || ''}
                  submitApproval={submitApproval}
                />
                <CXButton
                  variant="primary"
                  type="button"
                  customStyles={{
                    width: '15rem',
                    [mq[2]]: {
                      width: '100%',
                      marginTop: '0.75rem',
                      fontSize: '0.813rem',
                    },
                  }}
                  disabled={!newDocNum || !!rejectReason}
                  onClick={() => {
                    void submitAssignNum();
                  }}
                >
                  Assign {data.docType} number
                </CXButton>
              </div>
            </>
          ) : null}
          <div
            css={{
              display: 'flex',
            }}
          >
            {data.viewMode === APPROVAL_DETAIL_VIEW_MODE.PUBLISH && (
              <>
                <div
                  css={{
                    marginTop: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    [mq[2]]: {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      [mq[2]]: {
                        marginBottom: '1rem',
                      },
                    }}
                  >
                    <span css={{ fontWeight: 700 }}>
                      Assigned {data.docType} Number
                    </span>
                    <span css={{ fontWeight: 700, fontSize: '1.5rem' }}>
                      {data.documentNumber}
                    </span>
                  </div>
                </div>
              </>
            )}
            {!isViewerDetailDocType(data.docType) && (
              <div
                css={{
                  alignSelf: 'flex-end',
                  marginLeft: 'auto',
                }}
              >
                <CXButton
                  variant="primary-outline"
                  type="button"
                  customStyles={{
                    width: '15rem',
                    marginTop: '2rem',
                    [mq[2]]: {
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    triggerDownloadFile();
                  }}
                >
                  Download PDF
                </CXButton>
              </div>
            )}
          </div>

          {data.viewMode === APPROVAL_DETAIL_VIEW_MODE.APPROVE_REJECT ||
          data.viewMode === APPROVAL_DETAIL_VIEW_MODE.ASSIGN_DOC_NUM ? (
            <>
              <div
                css={{
                  marginTop: '1.5rem',
                  height: '1px',
                  backgroundColor: COLORS.grey8,
                }}
              />
              <div css={{ marginTop: '2rem' }}>
                <TextBox
                  name="rejectReason"
                  label="Reject reason"
                  hasError={false}
                  onChange={(e) => {
                    setRejectReason((e.target as HTMLInputElement).value);
                  }}
                />
              </div>
              <RejectModal
                isOpen={isRejectModalOpen}
                setIsOpen={setIsRejectModalOpen}
                rejectReason={rejectReason}
                submitApproval={submitApproval}
              />
              <div css={{ marginTop: '1rem' }}>
                <CXButton
                  variant="primary-outline"
                  type="button"
                  disabled={!rejectReason}
                  customStyles={{
                    [mq[2]]: {
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    setIsRejectModalOpen(true);
                  }}
                >
                  Reject
                </CXButton>
              </div>
            </>
          ) : null}
          {data.viewMode === APPROVAL_DETAIL_VIEW_MODE.PUBLISH ? (
            <>
              <div
                css={{
                  marginTop: '3rem',
                  height: '1px',
                  backgroundColor: COLORS.grey8,
                }}
              />
              <div
                css={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  [mq[2]]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                }}
              >
                <div css={{ flex: 1, [mq[1]]: { paddingRight: '1.25rem' } }} />
                <CXButton
                  variant="primary"
                  type="button"
                  customStyles={{
                    width: '15rem',
                    [mq[2]]: {
                      width: '100%',
                    },
                  }}
                  onClick={async () => {
                    await submitApproval(
                      true,
                      APPROVAL_DETAIL_VIEW_MODE.PUBLISH,
                    );
                  }}
                >
                  Publish
                </CXButton>
              </div>
            </>
          ) : null}
          {data.viewMode === APPROVAL_DETAIL_VIEW_MODE.ALLOW_CANCEL && (
            <CancelFlowSection
              data={data}
              isAuthor={isAuthor}
              isShowAllowCancelSection={true}
              setSubmitTime={() => {
                setSubmitTime(submitTime + 1);
              }}
            />
          )}
        </div>
      ) : (
        <SpinningLoader isNewLoadingIcon={true} loaderText={LOADING} />
      )}
    </CommonContainer>
  );
};

export default ApprovalDetail;
