/** @jsxImportSource @emotion/react */
import { COLORS } from 'helpers/colors';
import { CANCEL_WORK_FLOW_STATUS, DOC_STATUS } from 'helpers/constants';

const CancelExpiredTag = ({
  isExpired,
}: {
  isExpired?: boolean;
}): JSX.Element => {
  return (
    <div
      css={{
        padding: '0.25rem',
        backgroundColor: COLORS.red2,
        borderRadius: '4px',
      }}
    >
      <span
        css={{
          fontSize: '0.75rem',
          fontWeight: '500',
          lineHeight: '14px',
          color: COLORS.red,
        }}
      >
        {isExpired
          ? DOC_STATUS.IS_EXPIRED.value
          : CANCEL_WORK_FLOW_STATUS.CANCELLED.shortName}
      </span>
    </div>
  );
};

export default CancelExpiredTag;
