// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import serverConfig from 'config/serverConfig';

// Initialize Firebase
const app = initializeApp(serverConfig.firebase);
const analytics = () => getAnalytics(app);

export default analytics;
