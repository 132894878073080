/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';

import { COLORS } from 'helpers/colors';
import { PAGINATION_LABEL } from 'helpers/constants';

const PaginationItem = ({
  label,
  isActive,
  isDisabled,
  isEllipsis,
  onClick,
}: {
  label: string | number;
  isActive?: boolean;
  isEllipsis?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <li role="presentation" onClick={onClick}>
      <span
        role={'button'}
        tabIndex={0}
        css={{
          'li:first-of-type &': {
            borderBottomLeftRadius: '0.25rem',
            borderTopLeftRadius: '0.25rem',
          },
          'li:last-of-type &': {
            borderTopRightRadius: '0.25rem',
            borderBottomRightRadius: '0.25rem',
          },
          border: `0.063rem solid ${COLORS.grey5}`,
          padding: '0.25rem 0.5rem',
          color: COLORS.grey,
          textDecoration: 'none',
          backgroundColor: 'transparent',
          fontSize: '0.76562rem',
          display: 'block',
          transition:
            'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
          cursor: 'pointer',
          ...(!isDisabled && {
            ':hover': {
              color: COLORS.white,
              backgroundColor: COLORS.grey3,
              outline: 'none',
              boxShadow: 'none',
            },
          }),
          ...(isActive && {
            color: COLORS.white,
            backgroundColor: COLORS.grey3,
            borderColor: COLORS.grey4,
          }),
          ...(isDisabled && {
            borderColor: COLORS.grey5,
            color: COLORS.grey6,
            pointerEvents: 'none',
          }),
          ...(isEllipsis && {
            pointerEvents: 'none',
          }),
        }}
      >
        <span>{label}</span>
      </span>
    </li>
  );
};

const Pagination = ({
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
  noOfPages,
  scrollToView,
}: {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  pageIndex: number;
  noOfPages: number;
  scrollToView?: () => void;
}): JSX.Element => {
  const filterPages = (visiblePages: number[], totalPages: number) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page: number, total: number) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const canPreviousPage = pageIndex - 1 >= 0;
  const canNextPage = pageIndex + 1 < noOfPages;

  return (
    <section
      css={css`
        .pagination > .active > a {
          color: ${COLORS.white};
          background-color: ${COLORS.grey3} !important;
          border-color: ${COLORS.grey4} !important;
        }

        ul.pagination li a:hover {
          background-color: ${COLORS.white};
        }

        ul.pagination li a.active,
        ul.pagination li a:focus {
          color: ${COLORS.white};
          background-color: ${COLORS.grey3};
          outline: none;
          box-shadow: none;
        }
      `}
    >
      <div
        css={{
          display: 'flex',
          marginTop: '3rem',
          fontSize: '1rem',
          lineHeight: '1.28rem',
        }}
      >
        <ul
          css={{
            display: 'flex',
            listStyle: 'none',
            paddingLeft: 0,
          }}
        >
          <PaginationItem
            label={PAGINATION_LABEL.PREV}
            isDisabled={!canPreviousPage}
            onClick={() => {
              previousPage();
              scrollToView && scrollToView();
            }}
          />
          {getVisiblePages(pageIndex + 1, noOfPages).map(
            (page, index, array) => {
              const isEllipsis = array[index - 1] + 1 < page;
              return (
                <Fragment key={page}>
                  {isEllipsis && (
                    <PaginationItem
                      label={PAGINATION_LABEL.ELLIPSIS}
                      isEllipsis
                    />
                  )}
                  <PaginationItem
                    label={page}
                    isActive={pageIndex + 1 === page}
                    onClick={() => {
                      gotoPage(page - 1);
                      scrollToView && scrollToView();
                    }}
                  />
                </Fragment>
              );
            },
          )}
          <PaginationItem
            label={PAGINATION_LABEL.NEXT}
            isDisabled={!canNextPage}
            onClick={() => {
              nextPage();
              scrollToView && scrollToView();
            }}
          />
        </ul>
      </div>
    </section>
  );
};

export default Pagination;
