/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, usePagination, useSortBy, useTable } from 'react-table';

import {
  ApprovalStatus,
  CancelExpiredTag,
  CustomLabel,
  Pagination,
  SortingIcon,
  SpinningLoader,
  Svg,
  ToolTip,
  UploadDocumentModal,
  UserGroupLabel,
  notify,
} from 'components';
import {
  MobileResultItem,
  NotesModal,
  SearchBar,
} from 'components/SearchDocument/components';
import serverConfig from 'config/serverConfig';
import { getDocTypeDisplay, getRedirectUrl, isNonCXUser, mq } from 'helpers';
import { postDocFavorite } from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import {
  CUSTOM_LABELS,
  DOC_STATUS,
  LOADING,
  TABLE_SIZE,
  USER_GROUP,
  WORK_FLOW_STATUS,
} from 'helpers/constants';
import { formatInDaysHoursMins, formatToDate } from 'helpers/date';
import { IAta, ISearchTableResult } from 'models/SearchData';

import bgImage from 'assets/images/depository_bg.png';

const NoResultText = (): JSX.Element => {
  return <div css={{ fontSize: '0.875rem' }}>No matching results found</div>;
};

const LabelInfo = (): JSX.Element => {
  return (
    <div>
      <div css={{ marginTop: '1rem' }}>
        <CustomLabel
          labelType={CUSTOM_LABELS.URGENT_SUBMISSION}
          customStyles={{ fontSize: '0.75rem' }}
          isNameVisible={true}
          isUrgent={true}
        />
      </div>
      <div css={{ display: 'flex' }}>
        <CustomLabel
          labelType={CUSTOM_LABELS.MOR_RELATED}
          customStyles={{ fontSize: '0.75rem' }}
          isNameVisible={true}
        />
        <CustomLabel
          labelType={CUSTOM_LABELS.AD_RELATED}
          customStyles={{ fontSize: '0.75rem' }}
          isNameVisible={true}
        />
        <CustomLabel
          labelType={CUSTOM_LABELS.DELAY_DRIVER}
          customStyles={{ fontSize: '0.75rem' }}
          isNameVisible={true}
        />
        <CustomLabel
          labelType={CUSTOM_LABELS.AOG_DRIVER}
          customStyles={{
            fontSize: '0.75rem',
            width: 'auto',
            color: COLORS.black,
          }}
          isNameVisible={true}
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <CustomLabel
          labelType={CUSTOM_LABELS.RETURN_TO_BLOCK}
          customStyles={{ fontSize: '0.625rem', padding: '0.2rem 0.8rem' }}
          isNameVisible={true}
        />
      </div>
      <div
        css={{
          display: 'flex',
          marginBottom: '0.5rem',
          justifyContent: 'end',
        }}
      >
        <div
          css={{
            marginRight: '0.5rem',
            display: 'flex',
          }}
        >
          <UserGroupLabel
            userGroup={USER_GROUP.HAECO.value}
            customTextStyles={{ fontSize: '0.5rem' }}
          />
          <span
            css={{
              fontSize: '0.75rem',
              marginLeft: '0.2rem',
            }}
          >
            HAECO user
          </span>
        </div>
        <div
          css={{
            display: 'flex',
          }}
        >
          <UserGroupLabel
            userGroup={USER_GROUP.CASL.value}
            customTextStyles={{ fontSize: '0.5rem' }}
          />
          <span
            css={{
              fontSize: '0.75rem',
              marginLeft: '0.2rem',
            }}
          >
            CASL user
          </span>
        </div>
      </div>
    </div>
  );
};

const SearchDocument = ({
  isViewer,
  isApprover,
  userGroup,
  isBookmark,
}: {
  isViewer: boolean;
  isApprover: boolean;
  userGroup?: string;
  isBookmark?: boolean;
}): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [data, setData] = useState<ISearchTableResult[]>([]);
  const [selectedNoteDocument, setSelectedNoteDocument] =
    useState<ISearchTableResult>();
  const [isSearching, setIsSearching] = useState(false);
  const [mobilePageIndex, setMobilePageIndex] = useState(0);

  const myTableRef = useRef<HTMLTableSectionElement>(null);
  const myMobileRef = useRef<HTMLTableSectionElement>(null);

  const navigate = useNavigate();

  const getHeaderWidth = (title: string) => {
    switch (title) {
      case 'header_title':
        return '13rem';
      case 'header_fleetArr':
        return '8rem';
      case 'header_ata':
        return '5rem';
      case 'header_isMorRelated':
        return '3.5rem';
      case 'header_docType':
        return '5rem';
      case 'header_creator':
        return '5rem';
      case 'header_documentNumber':
        return '8rem';
      case 'header_approveStatus':
        return '10rem';
      case 'header_isFavorite':
        return '2rem';
      case 'header_note':
        return '4rem';
      default:
        return '8rem';
    }
  };

  const columns = useMemo<Column<ISearchTableResult>[]>(
    () => [
      {
        accessor: 'fleetArr',
        Header: 'Fleet',
        Cell: ({ cell: { value } }: { cell: { value: string[] } }) => (
          <>{value.join(', ')}</>
        ),
      },
      {
        accessor: 'docType',
        Header: 'Doc Type',
        Cell: ({ row }) => {
          const isShowLabel =
            row.original.userGroup && isNonCXUser(row.original.userGroup);
          return (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span>{getDocTypeDisplay(row.original.docType)}</span>
              {isShowLabel && (
                <span>
                  <UserGroupLabel
                    userGroup={row.original.userGroup}
                    customTextStyles={{
                      fontSize: '0.5rem',
                    }}
                  />
                </span>
              )}
            </div>
          );
        },
      },
      {
        accessor: 'documentNumber',
        Header: 'Ref',
      },
      {
        accessor: 'ata',
        Header: 'ATA',
        Cell: ({ cell: { value } }: { cell: { value: IAta } }) => (
          <>{value.chapterArr?.join(', ') || ''}</>
        ),
      },
      {
        accessor: 'isMorRelated',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '3rem',
              }}
            >
              {row.original.isMorRelated ? (
                <CustomLabel
                  customContainerStyles={{
                    flexBasis: '35%',
                    marginBottom: '0.5rem',
                  }}
                  customStyles={{ marginRight: '0.2rem' }}
                  labelType={CUSTOM_LABELS.MOR_RELATED}
                />
              ) : null}
              {row.original.isAdRelated ? (
                <CustomLabel
                  customContainerStyles={{
                    flexBasis: '35%',
                    marginBottom: '0.5rem',
                  }}
                  customStyles={{ marginRight: '0.2rem' }}
                  labelType={CUSTOM_LABELS.AD_RELATED}
                />
              ) : null}
              {row.original.isDelayDriver ? (
                <CustomLabel
                  customContainerStyles={{
                    flexBasis: '35%',
                    marginBottom: '0.5rem',
                  }}
                  customStyles={{ marginRight: '0.2rem' }}
                  labelType={CUSTOM_LABELS.DELAY_DRIVER}
                />
              ) : null}
              {row.original.isAogDriver ? (
                <CustomLabel
                  customContainerStyles={{
                    flexBasis: '35%',
                    marginBottom: '0.5rem',
                  }}
                  customStyles={{
                    marginRight: '0.2rem',
                    width: 'auto',
                    color: COLORS.black,
                  }}
                  labelType={CUSTOM_LABELS.AOG_DRIVER}
                />
              ) : null}
              {row.original.aogIsRtb ? (
                <CustomLabel
                  customContainerStyles={{
                    flexBasis: '35%',
                    marginBottom: '0.5rem',
                  }}
                  customStyles={{
                    marginRight: '0.2rem',
                    fontSize: '0.625rem',
                    padding: '0.2rem',
                    width: 'auto',
                  }}
                  labelType={CUSTOM_LABELS.RETURN_TO_BLOCK}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        accessor: 'title',
        Header: 'Title',
        disableSortBy: true,
        Cell: ({ row }) => {
          const isCancelled =
            row.original.status === WORK_FLOW_STATUS.CANCELLED.code;

          return (
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              {row.original.isExpired &&
              row.original.supersededByArr.length > 0 ? (
                <span
                  css={{
                    color: COLORS.grey3,
                    fontWeight: '400',
                    fontSize: '0.625rem',
                    lineHeight: '0.8rem',
                  }}
                >
                  {`Superseded by ${row.original.supersededByArr.join(', ')}`}
                </span>
              ) : null}
              <span
                css={{
                  color: COLORS.grey3,
                  fontWeight: '500',
                  fontSize: '0.875rem',
                  lineHeight: '1.1rem',
                }}
              >
                {row.values.title}
              </span>
              {!row.original.isExpired &&
              row.original.supersedeArr.length > 0 ? (
                <span
                  css={{
                    color: COLORS.grey3,
                    fontWeight: '400',
                    fontSize: '0.625rem',
                    lineHeight: '0.8rem',
                  }}
                >
                  {`Supersedes ${row.original.supersedeArr.join(', ')}`}
                </span>
              ) : null}
              {isBookmark && (row.original.isExpired || isCancelled) && (
                <span
                  css={{
                    display: 'flex',
                  }}
                >
                  {row.original.isExpired && <CancelExpiredTag isExpired />}
                  {isCancelled && <CancelExpiredTag />}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Approve Status',
        accessor: 'approveStatus',
        Cell: ({ row }) => {
          return (
            <div css={{ display: 'flex' }}>
              <ApprovalStatus
                values={
                  row.original.cancellationStatus
                    ? row.original.cancellationStatus
                    : row.original.approvalStatus
                }
                isExpired={row.original.isExpired}
                status={row.original.status}
                cancelStatus={row.original.cancelStatus}
                isCancellation={!!row.original.cancellationStatus}
              />
              {row.original.isUrgent &&
              row.original.status !== WORK_FLOW_STATUS.PUBLISHED.code ? (
                <CustomLabel
                  labelType={CUSTOM_LABELS.URGENT_SUBMISSION}
                  isUrgent={true}
                />
              ) : null}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: isViewer ? 'effectiveDate' : 'submissionDate',
        Header: isViewer ? 'Effective Date' : 'Submission Date',
        Cell: ({ cell: { value } }: { cell: { value?: string } }) => (
          <>{formatToDate(value || '')}</>
        ),
      },
      {
        accessor: 'aogDelayTimeMin',
        Header: 'AOG Hours',
        Cell: ({ cell: { value } }: { cell: { value?: number } }) => (
          <>{value ? formatInDaysHoursMins(value) : '--'}</>
        ),
      },
      {
        accessor: 'aogTailRegistration',
        Header: 'A/C Reg',
        Cell: ({ cell: { value } }: { cell: { value?: string } }) => (
          <>{value || '--'}</>
        ),
      },
      {
        accessor: 'creator',
        Header: 'Creator',
      },
      {
        accessor: 'isFavorite',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.isAllowFavoriteNote && (
                <div>
                  <Svg
                    icon={
                      row.original.isFavorite ? 'favorite-active' : 'favorite'
                    }
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        accessor: 'note',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.isAllowFavoriteNote && (
                <div
                  css={{
                    textAlign: 'center',
                  }}
                >
                  <span
                    css={{
                      background: COLORS.white,
                      border: `1px solid ${COLORS.grey8}`,
                      borderRadius: '4px',
                      padding: '0.5rem',
                      fontSize: ' 0.625rem',
                      fontWeight: '600',
                      color: COLORS.grey,
                      '&:hover': {
                        background: COLORS.white4,
                      },
                      ...(row.original.note && {
                        color: COLORS.blue5,
                        border: `0.063rem solid ${COLORS.blue5}`,
                        '&:hover': {
                          background: COLORS.green3,
                        },
                      }),
                    }}
                  >
                    Notes
                  </span>
                </div>
              )}
            </>
          );
        },
      },
    ],
    [isViewer],
  );

  const rowOnClick = (
    e: React.MouseEvent,
    id: string,
    fileName: string,
    docType?: string,
  ) => {
    e.preventDefault();
    const url = getRedirectUrl({
      id,
      fileName,
      isViewer,
      isApprover,
      docType,
      userGroup,
    });
    if (isViewer) {
      const updateDataIndex = data.findIndex((d) => d._id === id && !d.isRead);
      if (updateDataIndex > -1) {
        const updateData = JSON.parse(
          JSON.stringify(data),
        ) as ISearchTableResult[];
        updateData[updateDataIndex].isRead = true;
        setData(updateData);
      }
      window.open(url, '_blank');
      return;
    }
    navigate(url);
  };

  const favoriteDoc = async (documentId: string, isFavorite: boolean) => {
    const resp = await postDocFavorite({
      documentId,
      isFavorite,
    });

    if (resp && resp.result) {
      const updateDataIndex = data.findIndex((d) => d._id === documentId);
      if (updateDataIndex > -1) {
        data[updateDataIndex].isFavorite = isFavorite;
        setData([...data]);
      }
      notify({
        message: isFavorite
          ? 'Successfully saved to my favourited document'
          : 'Document successfully removed from my unfavourited document',
        type: 'success',
      });
    } else {
      notify({
        message: `Cannot ${isFavorite ? 'favorite' : 'unfavorite'} document`,
        type: 'error',
      });
    }
  };

  const handleNoteClick = (item: ISearchTableResult) => {
    setSelectedNoteDocument(item);
    setIsNotesModalOpen(true);
  };

  const hiddenViewerColumns = ['approveStatus', 'creator'];
  const aogColumns = ['aogDelayTimeMin', 'aogTailRegistration'];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    prepareRow,
  } = useTable<ISearchTableResult>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_SIZE,
        hiddenColumns: [
          ...(isViewer ? hiddenViewerColumns : []),
          ...(!serverConfig.isDisableAog && !isViewer ? aogColumns : []),
          ...(serverConfig.isDisableAog ? aogColumns : []),
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  return (
    <div
      css={{
        flex: 1,
        paddingLeft: '1rem',
      }}
    >
      <div
        css={{
          flex: 1,
          marginTop: '1rem',
          marginRight: '1rem',
          fontSize: '2rem',
          textAlign: 'left',
        }}
      >
        {isBookmark
          ? 'My Bookmark'
          : isViewer
          ? 'Repository'
          : isApprover
          ? 'Approver List'
          : 'Author List'}
        {isViewer && !isBookmark && (
          <div css={{ flex: 1, marginTop: '1.5rem' }}>
            <img
              css={{ width: '100%', height: 'auto' }}
              src={bgImage}
              alt="background"
            />
          </div>
        )}
        <section>
          <SearchBar
            setData={setData}
            setSearchingLoading={setIsSearching}
            openModalHandler={() => {
              setIsModalOpen(!isModalOpen);
            }}
            isViewer={isViewer}
            isApprover={isApprover}
            isModalOpen={isModalOpen}
            isNotesModalOpen={isNotesModalOpen}
            isBookmark={isBookmark}
            userGroup={userGroup}
          />
        </section>
        {isSearching && (
          <SpinningLoader isNewLoadingIcon={true} loaderText={LOADING} />
        )}

        {!isSearching && (
          <section
            ref={myTableRef}
            css={{
              [mq[1]]: {
                display: 'none',
              },
            }}
          >
            <div
              css={{
                fontFamily: 'CathaySans Medium',
                fontSize: '1.125rem',
                color: COLORS.black,
              }}
            >
              Document ({data.length})
            </div>
            <div
              css={{
                display: 'flex',
                flex: 1,
                marginTop: '1.5rem',
              }}
            >
              {data.length > 0 ? (
                <table
                  {...getTableProps()}
                  css={{
                    width: '100%',
                  }}
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        css={{ height: '2.5rem' }}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                            )}
                            css={{
                              fontSize: '0.75rem',
                              lineHeight: '1.1875rem',
                              paddingLeft:
                                isViewer &&
                                column.getHeaderProps().key ===
                                  'header_fleetArr'
                                  ? '0.25rem'
                                  : '0',
                              width: getHeaderWidth(
                                column.getHeaderProps().key as string,
                              ),
                            }}
                          >
                            {column.render('Header')}
                            <span>
                              {column.getHeaderProps().key ===
                                'header_isMorRelated' ||
                              column.getHeaderProps().key === 'header_title' ||
                              column.getHeaderProps().key ===
                                'header_approveStatus' ||
                              column.getHeaderProps().key === 'header_note' ||
                              column.getHeaderProps().key ===
                                'header_isFavorite' ? (
                                ''
                              ) : (
                                <SortingIcon
                                  isSorted={column.isSorted}
                                  isSortedDesc={column.isSortedDesc}
                                />
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      let bgColor = COLORS.white;
                      const docStatus = row.original.isExpired
                        ? DOC_STATUS.IS_EXPIRED.name
                        : row.original.isNew
                        ? DOC_STATUS.IS_NEW.name
                        : DOC_STATUS.DEFAULT.name;
                      if (
                        docStatus !== undefined &&
                        (docStatus === DOC_STATUS.IS_EXPIRED.name ||
                          row.original.status ===
                            WORK_FLOW_STATUS.CANCELLED.code)
                      ) {
                        bgColor = COLORS.grey12;
                      } else if (
                        docStatus !== undefined &&
                        docStatus === DOC_STATUS.IS_NEW.name
                      ) {
                        bgColor = COLORS.green2;
                      }

                      const redirectUrl = getRedirectUrl({
                        id: row.original._id,
                        fileName: row.original.fileName,
                        isApprover,
                        isViewer,
                        docType: row.original.docType,
                        userGroup,
                      });

                      return (
                        <tr
                          {...row.getRowProps()}
                          css={{
                            height: '3rem',
                            cursor: 'pointer',
                            backgroundColor: bgColor,
                            ...(isViewer &&
                              !row.original.isRead && {
                                borderLeft: `3px solid ${COLORS.green}`,
                              }),
                          }}
                          onClick={(e: React.MouseEvent) =>
                            rowOnClick(
                              e,
                              row.original._id,
                              row.original.fileName,
                              row.original.docType,
                            )
                          }
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                css={{
                                  textAlign: 'left',
                                  borderBottom: `1px solid ${COLORS.grey7}`,
                                  fontSize: '0.875rem',
                                  lineHeight: '1.28rem',
                                  overflowWrap: 'break-word',
                                  padding: '0.5rem',
                                  position: 'relative',
                                  paddingLeft:
                                    isViewer &&
                                    cell.column.getHeaderProps().key ===
                                      'header_fleetArr'
                                      ? '0.25rem'
                                      : '0',
                                }}
                              >
                                {cell.column.getHeaderProps().key ===
                                'header_isFavorite' ? (
                                  <ToolTip
                                    title={
                                      !row.original.isFavorite
                                        ? 'Favourite'
                                        : 'Unfavourite'
                                    }
                                  >
                                    <span
                                      role="presentation"
                                      onClick={async (e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        await favoriteDoc(
                                          row.original._id,
                                          !row.original.isFavorite,
                                        );
                                      }}
                                    >
                                      {cell.render('Cell')}
                                    </span>
                                  </ToolTip>
                                ) : cell.column.getHeaderProps().key ===
                                  'header_note' ? (
                                  <span
                                    role="presentation"
                                    onClick={(e: React.MouseEvent) => {
                                      e.stopPropagation();
                                      handleNoteClick(row.original);
                                    }}
                                  >
                                    {cell.render('Cell')}
                                  </span>
                                ) : (
                                  <a
                                    css={{
                                      display: 'block',
                                      width: '100%',
                                      height: '100%',
                                      ':hover': {
                                        textDecoration: 'none',
                                      },
                                      '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                      },
                                    }}
                                    href={redirectUrl}
                                  >
                                    {cell.render('Cell')}
                                  </a>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <NoResultText />
              )}
            </div>
          </section>
        )}
        {data.length > 0 && !isSearching && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              [mq[1]]: { display: 'none' },
            }}
          >
            <Pagination
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              pageIndex={pageIndex}
              noOfPages={Math.ceil(data.length / TABLE_SIZE)}
              scrollToView={() => myTableRef?.current?.scrollIntoView()}
            />
            <LabelInfo />
          </div>
        )}
      </div>
      {!isSearching && (
        <section
          ref={myMobileRef}
          css={{
            flex: 1,
            display: 'none',
            marginRight: '1rem',
            [mq[1]]: {
              display: 'flex',
              flexWrap: 'wrap',
            },
          }}
        >
          <div
            css={{
              marginBottom: '0.5rem',
              fontFamily: 'CathaySans Medium',
              fontSize: '1.125rem',
              color: COLORS.black,
            }}
          >
            Document ({data.length})
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingBottom: '3rem',
            }}
          >
            {data.length > 0 ? (
              <>
                {data
                  .slice(
                    mobilePageIndex * TABLE_SIZE,
                    mobilePageIndex * TABLE_SIZE + TABLE_SIZE,
                  )
                  .map((resultItem, index) => (
                    <MobileResultItem
                      key={index}
                      item={resultItem}
                      isViewer={isViewer}
                      isBookmark={isBookmark}
                      isApprover={isApprover}
                      rowOnClick={rowOnClick}
                      favoriteDoc={favoriteDoc}
                      handleMobileDocNoteClick={handleNoteClick}
                      userGroup={userGroup}
                    />
                  ))}
                {/* Mobile Pagination */}
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    [mq[2]]: {
                      flexDirection: 'column-reverse',
                    },
                  }}
                >
                  <Pagination
                    gotoPage={(idx) => setMobilePageIndex(idx)}
                    nextPage={() => setMobilePageIndex(mobilePageIndex + 1)}
                    previousPage={() => setMobilePageIndex(mobilePageIndex - 1)}
                    pageIndex={mobilePageIndex}
                    noOfPages={Math.ceil(data.length / TABLE_SIZE)}
                    scrollToView={() => myMobileRef?.current?.scrollIntoView()}
                  />
                  <LabelInfo />
                </div>
              </>
            ) : (
              <NoResultText />
            )}
          </div>
        </section>
      )}
      <UploadDocumentModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      {selectedNoteDocument && (
        <NotesModal
          isOpen={isNotesModalOpen}
          setIsOpen={setIsNotesModalOpen}
          document={selectedNoteDocument}
          title="Document Notes"
        />
      )}
    </div>
  );
};

export default SearchDocument;
