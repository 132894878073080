/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { SpinningLoader } from 'components';
import { convertRemToPixels, useWindowDimensions } from 'helpers';
import { COLORS } from 'helpers/colors';
import { BREAK_POINTS } from 'helpers/constants';

pdfjs.GlobalWorkerOptions.workerSrc =
  process.env.NODE_ENV === 'development'
    ? new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()
    : `/pdf.worker.min.mjs`;

const PreviewPdf = ({
  file,
  isShowSidebar,
  modal = false,
}: {
  file?: Blob;
  isShowSidebar: boolean;
  modal?: boolean;
}): JSX.Element => {
  const [pageArr, setPageArr] = useState<number[]>([]);
  const { width } = useWindowDimensions();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    const arr = [];
    for (let i = 1; i <= numPages; i += 1) {
      arr.push(i);
    }
    setPageArr(arr);
  };

  const getWidth = () => {
    let widthSub = 0;
    if (width > BREAK_POINTS[0]) {
      widthSub = 550;
    } else if (width > BREAK_POINTS[2] && width <= BREAK_POINTS[1]) {
      widthSub = 200;
    } else if (width <= BREAK_POINTS[2]) {
      widthSub = 100;
    }
    return widthSub;
  };
  return (
    <div
      css={{
        marginTop: '1rem',
        height: '26rem',
        overflow: 'auto',
        border: `0.5px solid ${COLORS.grey16}`,
      }}
    >
      {file ? (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {pageArr.map((x) => (
            <Page
              key={x}
              pageNumber={x}
              width={
                width -
                convertRemToPixels(2) -
                (isShowSidebar ? convertRemToPixels(18) : 36) -
                (modal ? getWidth() : 0)
              }
            />
          ))}
        </Document>
      ) : (
        <>
          <SpinningLoader
            customStyles={{ alignItems: 'center' }}
            loaderText="Generating PDF for preview"
            customTextStyles={{
              fontStyle: 'italic',
            }}
          />
        </>
      )}
    </div>
  );
};

export default PreviewPdf;
