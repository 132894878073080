import axios, { AxiosRequestHeaders, ResponseType } from 'axios';
import { get } from 'lodash';

import { IFormData } from 'components';
import { getUrlPrefix, to } from 'helpers';
import { URL_PATH } from 'helpers/constants';
import { IUserProfileResult } from 'models/ProfileData';
import {
  IUploadFinalResponse,
  IUploadFirstResponse,
  IUploadResponse,
} from 'models/ResponseData';
import {
  IApproverResult,
  IAuthorSearchResult,
  IDocDetail,
  ISearchReqData,
  IUserRoleSearchRequest,
  IUserRoleSearchResult,
  IViewerSearchResult,
} from 'models/SearchData';

const genericCaller = <T,>({
  url,
  method,
  headers,
  data,
  responseType,
}: {
  url: string;
  method: 'GET' | 'POST';
  headers?: AxiosRequestHeaders;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  responseType?: ResponseType;
}) => {
  const urlPrefix = '/okr/api/1.0';

  return axios.request<T>({
    url: `${urlPrefix}${url}`,
    method,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    data,
    headers: { ...headers },
    responseType,
  });
};

export const getUserProfile = async (): Promise<IUserProfileResult> => {
  const [err, response] = await to(
    genericCaller({
      url: '/user/profile',
      method: 'GET',
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IUserProfileResult;
};

export const postUploadDocumentData = async (
  data: FormData,
  userGroup?: string,
): Promise<
  | boolean
  | IUploadFirstResponse
  | IUploadResponse
  | IUploadFinalResponse
  | undefined
> => {
  const [err, response] = await to(
    genericCaller<boolean | IUploadResponse | IUploadFirstResponse>({
      url: `${getUrlPrefix(URL_PATH.AUTHOR, userGroup)}/submit`,
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);

    return {
      result: false,
      errMsg: get(err, 'response.data.detail') as string,
    };
  }

  if (response?.data === true) {
    return { result: true };
  }

  return response?.data;
};

export const getDocument = async ({
  fileName,
  isAuthor,
  isApprover,
  isRiskAccessAttachment,
  userGroup,
}: {
  fileName?: string;
  isAuthor?: boolean;
  isApprover?: boolean;
  isRiskAccessAttachment?: boolean;
  userGroup?: string;
}): Promise<Blob | null> => {
  if (!fileName) {
    return null;
  }

  let url = `${getUrlPrefix(URL_PATH.VIEWER, userGroup)}/download/${fileName}`;
  if (isAuthor) {
    if (isRiskAccessAttachment) {
      url = `${getUrlPrefix(
        URL_PATH.AUTHOR,
        userGroup,
      )}/downloadRiskAccessAttached/${fileName}`;
    } else {
      url = `${getUrlPrefix(URL_PATH.AUTHOR, userGroup)}/download/${fileName}`;
    }
  } else if (isApprover) {
    if (isRiskAccessAttachment) {
      url = `/approver/downloadRiskAccessAttached/${fileName}`;
    } else {
      url = `/approver/download/${fileName}`;
    }
  }

  const [err, response] = await to(
    genericCaller({
      url,
      method: 'GET',
      responseType: 'blob',
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as Blob;
};

export const postSearchData = async (
  data: ISearchReqData,
  userGroup?: string,
): Promise<IAuthorSearchResult[]> => {
  const [err, response] = await to(
    genericCaller({
      url: `${getUrlPrefix(URL_PATH.AUTHOR, userGroup)}/search`,
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IAuthorSearchResult[];
};

export const postViewerSearchData = async (
  data: ISearchReqData,
  userGroup?: string,
  isBookmark?: boolean,
): Promise<IViewerSearchResult[]> => {
  let urlPrefix = getUrlPrefix(URL_PATH.VIEWER, userGroup);
  if (isBookmark) {
    urlPrefix = `${urlPrefix}/bookmark`;
  }
  const [err, response] = await to(
    genericCaller({
      url: `${urlPrefix}/search`,
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IViewerSearchResult[];
};

export const getCreatorList = async (): Promise<string[]> => {
  const [err, response] = await to(
    genericCaller({
      url: '/creator/list',
      method: 'GET',
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data', []) as string[];
};

export const getAssigneeList = async (): Promise<string[]> => {
  const [err, response] = await to(
    genericCaller({
      url: '/assignee/list',
      method: 'GET',
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data', []) as string[];
};

export const postApproveData = async (
  data: ISearchReqData,
): Promise<IApproverResult[]> => {
  const [err, response] = await to(
    genericCaller({
      url: '/approver/search',
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IApproverResult[];
};

export const getApprovalDetail = async (
  documentId: string,
): Promise<IDocDetail> => {
  const [err, response] = await to(
    genericCaller({
      url: '/approver/detail',
      method: 'POST',
      data: { documentId },
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IDocDetail;
};

export const getAuthorDocDetail = async (
  documentId: string,
  userGroup?: string,
): Promise<IDocDetail> => {
  const [err, response] = await to(
    genericCaller({
      url: `${getUrlPrefix(URL_PATH.AUTHOR, userGroup)}/detail`,
      method: 'POST',
      data: { documentId },
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IDocDetail;
};

export const getViewerDocDetail = async (
  documentId: string,
  userGroup?: string,
): Promise<IDocDetail> => {
  const [err, response] = await to(
    genericCaller({
      url: `${getUrlPrefix(URL_PATH.VIEWER, userGroup)}/detail`,
      method: 'POST',
      data: { documentId },
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IDocDetail;
};

export const postApproval = async (
  documentId: string,
  isApproved: boolean,
  rejectReason: string,
  newDocNum?: string,
): Promise<boolean> => {
  const [err, response] = await to(
    genericCaller({
      url: '/approver/submit',
      method: 'POST',
      data: { documentId, isApproved, rejectReason, newDocNum },
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as boolean;
};

export const postCheckDocument = async (
  publication: string,
  docNum: string,
): Promise<boolean> => {
  const [err, response] = await to(
    genericCaller({
      url: '/document/check',
      method: 'POST',
      data: { publication, docNum },
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as boolean;
};

export const postPreviewPdf = async (
  newDocNum: string,
  documentId: string,
): Promise<Blob> => {
  const [err, response] = await to(
    genericCaller({
      url: '/approver/preview',
      method: 'POST',
      responseType: 'blob',
      data: { newDocNum, documentId },
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as Blob;
};

export const getExportData = async (
  year: number,
  userGroup?: string,
): Promise<{ file?: Blob; fileName?: string }> => {
  const [err, response] = await to(
    genericCaller({
      url: `${getUrlPrefix(URL_PATH.EXPORT, userGroup)}/download/${year}`,
      method: 'GET',
      responseType: 'blob',
    }),
  );

  if (err) {
    console.log(err);
  }

  const fileHeader = response?.headers['content-disposition'] as string;

  return {
    file: get(response, 'data') as Blob,
    fileName:
      fileHeader && fileHeader.length > 0
        ? fileHeader.split('filename=')[1]
        : undefined,
  };
};

export const submitCancel = async (
  documentId: string,
  isApproved: boolean,
  isAuthor?: boolean,
  cancelReason?: string,
  cancelAssignee?: string,
  userGroup?: string,
): Promise<{ result: boolean; errMsg?: string }> => {
  let url;
  if (isAuthor) {
    url = `${getUrlPrefix(URL_PATH.AUTHOR, userGroup)}/submitCancel`;
  } else {
    url = '/approver/submitCancel';
  }
  const [err, response] = await to(
    genericCaller({
      url: url,
      method: 'POST',
      data: { documentId, isApproved, cancelReason, cancelAssignee },
    }),
  );

  if (err) {
    console.log(err);

    return {
      result: false,
      errMsg: get(err, 'response.data.detail') as string,
    };
  }
  return { result: get(response, 'data') as boolean };
};

export const postEditDocumentData = async (
  data: IFormData,
  documentId: string,
  userGroup?: string,
): Promise<{ result: boolean; errMsg?: string }> => {
  const [err, response] = await to(
    genericCaller({
      url: `${getUrlPrefix(URL_PATH.AUTHOR, userGroup)}/edit/${documentId}`,
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);

    return {
      result: false,
      errMsg: get(err, 'response.data.detail') as string,
    };
  }
  return { result: get(response, 'data') as boolean };
};

export const postUserRoleSearchRequest = async (
  data: IUserRoleSearchRequest,
): Promise<IUserRoleSearchResult> => {
  const [err, response] = await to(
    genericCaller({
      url: '/userRole/search',
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);
  }

  return get(response, 'data') as IUserRoleSearchResult;
};

export const postEditUserRole = async (data: {
  galacxyId: string;
  userRoles: string[];
  userGroup: string;
}): Promise<{ result: boolean; errMsg?: string }> => {
  const [err, response] = await to(
    genericCaller({
      url: `/userRole/edit`,
      method: 'POST',
      data,
    }),
  );

  if (err) {
    console.log(err);

    return {
      result: false,
      errMsg: get(err, 'response.data.detail') as string,
    };
  }
  return { result: get(response, 'data') as boolean };
};

export const postUserRoleCheck = async (
  galacxyId: string,
): Promise<{
  galacxyId?: string;
  name?: string;
  errMsg?: string;
}> => {
  const [err, response] = await to(
    genericCaller({
      url: '/userRole/check',
      method: 'POST',
      data: { galacxyId },
    }),
  );

  if (err) {
    console.log(err);
    console.log(get(err, 'response.data.detail'));

    return {
      errMsg: get(err, 'response.data.detail') as string,
    };
  }

  return get(response, 'data') as {
    galacxyId: string;
    name: string;
  };
};

export const postDocFavorite = async ({
  documentId,
  isFavorite,
}: {
  documentId: string;
  isFavorite: boolean;
}): Promise<{ result?: boolean; errMsg?: string }> => {
  const [err, response] = await to(
    genericCaller({
      url: '/bookmark/favorite',
      method: 'POST',
      data: {
        documentId,
        isFavorite,
      },
    }),
  );

  if (err) {
    console.log(err);
    console.log(get(err, 'response.data.detail'));

    return {
      result: false,
      errMsg: get(err, 'response.data.detail') as string,
    };
  }

  return { result: get(response, 'data') as boolean };
};

export const postDocNotes = async ({
  documentId,
  note,
}: {
  documentId: string;
  note: string;
}): Promise<{ result?: boolean; errMsg?: string }> => {
  const [err, response] = await to(
    genericCaller({
      url: '/bookmark/note',
      method: 'POST',
      data: {
        documentId,
        note,
      },
    }),
  );

  if (err) {
    console.log(err);
    console.log(get(err, 'response.data.detail'));

    return {
      result: false,
      errMsg: get(err, 'response.data.detail') as string,
    };
  }

  return { result: get(response, 'data') as boolean };
};
