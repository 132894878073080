import ReactPlayer from 'react-player/file';

const VideoPlayer = ({ sourceUrl }: { sourceUrl: string }): JSX.Element => {
  return (
    <ReactPlayer
      url={sourceUrl}
      controls
      controlsList="nodownload"
      playsInline
      width="100%"
      height="25.625rem"
      config={{
        attributes: {
          controlsList: 'nodownload',
          onContextMenu: (e: Event) => e.preventDefault(),
        },
      }}
    />
  );
};

export default VideoPlayer;
