/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { Controller, FieldValues } from 'react-hook-form';

import { ErrorMessageWrapper, TextBox } from 'components';
import { ERROR_MSGS, PATTERNS } from 'helpers/constants';

import { CommonFormControlProps } from '../../@types/formControl';

export interface TextBoxProps<T extends FieldValues>
  extends CommonFormControlProps<T> {
  isTextArea?: boolean;
  isFloatingLabel?: boolean;
  isClearInput?: boolean;
}
const FormTextBox = <T extends FieldValues>({
  label = '',
  dataField,
  rules,
  control,
  disabled,
  isClearInput,
}: TextBoxProps<T>): JSX.Element => {
  // for security assessment, check for special characters in free text input
  const newRules =
    !rules || (rules && !rules.pattern)
      ? {
          ...rules,
          pattern: {
            value: PATTERNS.SPECIAL_CHAR,
            message: ERROR_MSGS.NO_SPECIAL_CHAR,
          },
        }
      : rules;

  return (
    <div css={{ flex: 1 }}>
      <Controller
        name={dataField}
        control={control}
        rules={newRules}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
          formState: { errors },
        }) => (
          <>
            <TextBox
              name={name}
              label={label}
              disabled={disabled}
              hasError={!!error}
              value={value || ''}
              isClearInput={isClearInput}
              onChange={onChange}
              onReset={onChange}
            />
            <ErrorMessageWrapper errors={errors} dataField={dataField} />
          </>
        )}
      />
    </div>
  );
};

export default FormTextBox;
