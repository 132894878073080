/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { CXButton, CommonModal, PreviewPdf } from 'components';
import { mq } from 'helpers';
import { postPreviewPdf } from 'helpers/apiCaller';
import { APPROVAL_DETAIL_VIEW_MODE } from 'helpers/constants';

const PreviewDocModal = ({
  isOpen,
  setIsOpen,
  documentId,
  newDocNum,
  documentType,
  submitApproval,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  documentId: string;
  newDocNum: string;
  documentType: string;
  submitApproval: (isApproved: boolean, approveType: string) => Promise<void>;
}): JSX.Element => {
  const [file, setFile] = useState<Blob>();

  useEffect(() => {
    const viewPdf = async () => {
      if (documentId && newDocNum && isOpen) {
        const result = await postPreviewPdf(newDocNum, documentId);
        if (result) {
          setFile(result);
        }
      }
    };

    void viewPdf();
  }, [documentId, newDocNum, isOpen]);

  return (
    <CommonModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`Preview and confirm the assigned ${documentType} number on the Document`}
      customTitleStyles={{
        [mq[1]]: {
          fontSize: '1.25rem',
        },
        [mq[2]]: {
          fontSize: '1rem',
        },
      }}
    >
      <div
        css={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'column' }}
      >
        <PreviewPdf file={file} modal={true} isShowSidebar={false} />
        <div
          css={{ display: 'flex', flexDirection: 'row', marginTop: '2.5rem' }}
        >
          <div css={{ flex: 1 }} />
          <CXButton
            variant="primary"
            type="button"
            customStyles={{
              [mq[2]]: {
                width: '100%',
              },
            }}
            onClick={async () => {
              await submitApproval(
                true,
                APPROVAL_DETAIL_VIEW_MODE.ASSIGN_DOC_NUM,
              );
            }}
          >
            Confirm and assign number
          </CXButton>
        </div>
      </div>
    </CommonModal>
  );
};

export default PreviewDocModal;
