/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { Svg, TextBox } from 'components';
import { COLORS } from 'helpers/colors';

import { CommonFormControlProps } from '../../@types/formControl';

const CXMultiChipSelect = <T,>({
  label = '',
  dataField,
  control,
  rules,
}: CommonFormControlProps<T>): JSX.Element => {
  const [tempInput, setTempInput] = useState<string>('');
  const onAdd = (onChange: (...event: unknown[]) => void) => () => {
    if (!tempInput) return;
    const items = control._formValues[dataField] as string[];
    if (items && items.length && items.indexOf(tempInput) === -1) {
      onChange([...items, tempInput]);
      setTempInput('');
    } else if (!items || (items && !items.length)) {
      onChange([tempInput]);
      setTempInput('');
    }
  };

  const onClear =
    (onChange: (...event: unknown[]) => void, item: string) => () => {
      const items = control._formValues[dataField] as string[];
      const arr = items.filter((s) => s !== item);
      onChange([...arr]);
    };

  return (
    <>
      <Controller
        name={dataField}
        control={control}
        rules={rules}
        render={({ field: { onChange, name }, fieldState: { error } }) => {
          return (
            <>
              <div
                css={{
                  position: 'relative',
                  display: 'flex',
                  flex: 1,
                  flexFlow: 'column',
                }}
              >
                <TextBox
                  name={name}
                  label={label}
                  hasError={!!error}
                  value={tempInput}
                  onChange={(e) => {
                    setTempInput((e.target as HTMLInputElement).value);
                  }}
                />
                <div
                  css={{
                    position: 'absolute',
                    right: '0.3rem',
                    top: '0.8rem',
                  }}
                >
                  <button
                    type="button"
                    css={{
                      backgroundColor: COLORS.white,
                      border: '0',
                      marginLeft: '0.75rem',
                    }}
                    onClick={onAdd(onChange)}
                  >
                    <Svg icon="add" />
                  </button>
                </div>
              </div>
              <div css={{ width: '1.5rem' }} />
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  flexFlow: 'wrap',
                }}
              >
                {control._formValues[dataField] &&
                (control._formValues[dataField] as string[]).length
                  ? (control._formValues[dataField] as string[]).map((s) => (
                      <span
                        key={s}
                        css={{
                          fontSize: '0.75rem',
                          padding: '0.125rem 0.2rem 0.125rem 0.2rem',
                          background: COLORS.grey,
                          color: COLORS.white,
                          borderRadius: '0.125rem',
                          display: 'flex',
                          alignSelf: 'flex-start',
                          marginRight: '0.5rem',
                        }}
                      >
                        {s}
                        <div
                          aria-hidden={!!s}
                          css={{
                            cursor: 'pointer',
                          }}
                          onClick={onClear(onChange, s)}
                        >
                          <Svg icon="white-cross" />
                        </div>
                      </span>
                    ))
                  : null}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default CXMultiChipSelect;
