import { MutableRefObject, Ref, useCallback, useEffect, useState } from 'react';

import {
  BREAK_POINTS,
  CANCEL_WORK_FLOW_STATUS,
  DOCUMENT_TYPE,
  URL_PATH,
  USER_GROUP,
  USER_ROLE,
  WORK_FLOW_STATUS,
} from 'helpers/constants';
import {
  IApprovalStatus,
  ICancelStatus,
  IStageApproval,
} from 'models/SearchData';

export const timeout = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const to = async <T>(
  promise: Promise<T>,
): Promise<[unknown, undefined] | [undefined, T]> => {
  try {
    const result = await promise;
    return [undefined, result];
  } catch (e) {
    return [e, undefined];
  }
};

export const mq = BREAK_POINTS.map((bp) => `@media (max-width: ${bp}px)`);

export const convertApprovalStatus = (
  status: string,
  approvalStatus: IStageApproval,
  isCancellation?: boolean,
): IApprovalStatus[] | ICancelStatus[] => {
  const approveObjArr = [];
  let allStatusArr = [];
  if (isCancellation) {
    if (status === CANCEL_WORK_FLOW_STATUS.CANCELLED.code) {
      approveObjArr.push({
        isCancelled: true,
      });
    } else {
      // bound to follow the order
      allStatusArr = Object.keys(CANCEL_WORK_FLOW_STATUS);

      allStatusArr.forEach((x) => {
        const statusObj = approvalStatus[CANCEL_WORK_FLOW_STATUS[x].code];

        if (statusObj) {
          approveObjArr.push({
            type: CANCEL_WORK_FLOW_STATUS[x].shortName,
            isRequired: statusObj.isRequired,
            isApproved: statusObj.isApproved,
            isCancelled: false,
          });
        }
      });
    }
  } else {
    if (status === WORK_FLOW_STATUS.PUBLISHED.code) {
      approveObjArr.push({
        isPublished: true,
      });
    } else {
      // bound to follow the order
      allStatusArr = Object.keys(WORK_FLOW_STATUS);

      allStatusArr.forEach((x) => {
        const statusObj = approvalStatus[WORK_FLOW_STATUS[x].code];

        if (statusObj) {
          approveObjArr.push({
            type: WORK_FLOW_STATUS[x].shortName,
            isRequired: statusObj.isRequired,
            isApproved: statusObj.isApproved,
            isPublished: false,
          });
        }
      });
    }
  }

  return isCancellation
    ? (approveObjArr as ICancelStatus[])
    : (approveObjArr as IApprovalStatus[]);
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = (): {
  width: number;
  height: number;
} => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const convertRemToPixels = (rem: number): number => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const useIsMobile = (): boolean => {
  const { width } = useWindowDimensions();

  return width <= BREAK_POINTS[2];
};

export const useIsTablet = (): boolean => {
  const { width } = useWindowDimensions();

  return width <= BREAK_POINTS[1];
};

export const useIsMounted = (): (() => boolean) => {
  let isMounted = true;

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return useCallback(() => isMounted, []);
};

export const getUrlPath = ({
  isApprover,
  isViewer,
}: {
  isApprover?: boolean;
  isViewer?: boolean;
}): string => {
  const urlPath = isApprover
    ? URL_PATH.APPROVER
    : isViewer
    ? URL_PATH.VIEWER
    : URL_PATH.AUTHOR;
  return urlPath;
};

export const getRedirectUrl = ({
  id,
  fileName,
  isApprover,
  isViewer,
  docType,
  userGroup,
}: {
  id: string;
  fileName: string;
  isApprover: boolean;
  isViewer: boolean;
  docType?: string;
  userGroup?: string;
}): string => {
  let url;
  let urlPath = getUrlPath({ isApprover, isViewer });
  if (!isApprover) {
    urlPath = getUrlPrefix(urlPath, userGroup).replace('/', '');
  }

  if (isViewer && docType && !isViewerDetailDocType(docType)) {
    url = `/download/${fileName}`;
  } else {
    url = `/${urlPath}/${id}`;
  }

  return url;
};

export const downloadFile = ({
  file,
  fileName,
}: {
  file: Blob;
  fileName?: string;
}): void => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  if (fileName) {
    a.download = fileName;
  }
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

export const localStorageSetItem = (key: string, data: unknown): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const localStorageGetItem = (key: string): unknown => {
  const item = localStorage.getItem(key);
  if (item) {
    return isJsonString(item) ? JSON.parse(item) : item;
  }
  return null;
};

export const localStorageRemoveItem = (key: string): void => {
  return localStorage.removeItem(key);
};

export const localStorageRemoveAllItem = (): void => {
  localStorage.clear();
};

const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const assignRefs = <T>(...refs: Ref<T | null>[]) => {
  return (node: T | null): void => {
    refs.forEach((r) => {
      if (typeof r === 'function') {
        r(node);
      } else if (r) {
        (r as MutableRefObject<T | null>).current = node;
      }
    });
  };
};

export const getAllDocTypeArr = (
  userGroup?: string,
  isViewer?: boolean,
): { name: string; value: string }[] =>
  Object.values(DOCUMENT_TYPE)
    .filter((x) =>
      !isViewer
        ? userGroup &&
          x.allowedUserGroup.includes(userGroup) &&
          !x.isAllowOnlyViewer
        : true,
    )
    .filter((x) => x.isEnable)
    .map((x) => ({ name: x.name, value: x.name }));

export const getDocTypeDisplay = (docType: string): string | undefined =>
  Object.values(DOCUMENT_TYPE).find((x) => x.name === docType)?.display;

export const getUserGroupName = (userGroup: string): string | undefined =>
  Object.values(USER_GROUP).find((x) => x.value === userGroup)?.display;

export const getApproverNames = (): string[] =>
  Object.values(USER_ROLE)
    .filter((x) => x.display === 'Approver')
    .map((val) => val.name);

export const getUrlPrefix = (baseUrl: string, userGroup?: string): string => {
  let urlPrefix = `/${baseUrl}`;
  if (userGroup && isNonCXUser(userGroup)) {
    const userGroupName = getUserGroupName(userGroup);
    if (userGroupName) {
      urlPrefix = `${urlPrefix}-${userGroupName.toLowerCase()}`;
    }
  }
  return urlPrefix;
};

export const isNonCXUser = (userGroup?: string) =>
  userGroup && ![USER_GROUP.CX.value, USER_GROUP.NONE.value].includes(userGroup)
    ? true
    : false;

export const isViewerDetailDocType = (docType: string) =>
  [DOCUMENT_TYPE.AOG.name, DOCUMENT_TYPE.VIDEO.name].includes(docType);
