/** @jsxImportSource @emotion/react */
import { CXButton, CommonModal } from 'components';
import { mq } from 'helpers';
import { MODAL_SIZES } from 'helpers/constants';

const UnsavedConfirmationModal = ({
  isOpen,
  setIsOpen,
  title,
  infoText,
  confirmationText,
  confirmBtnText,
}: {
  isOpen: boolean;
  setIsOpen: (isConfirm: boolean) => void;
  title: string;
  confirmationText: string;
  confirmBtnText: string;
  infoText?: string;
}): JSX.Element => {
  return (
    <CommonModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      width={MODAL_SIZES.SMALL}
    >
      <div
        css={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'column' }}
      >
        {infoText && (
          <span
            css={{
              fontWeight: 600,
              marginBottom: '1rem',
            }}
          >
            {infoText}
          </span>
        )}
        <span
          css={{
            fontWeight: 600,
          }}
        >
          {confirmationText}
        </span>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '2.5rem',
            justifyContent: 'flex-end',
            [mq[3]]: {
              flexDirection: 'column',
            },
          }}
        >
          <CXButton
            variant="primary-outline"
            type="button"
            onClick={() => setIsOpen(false)}
            customStyles={{
              fontSize: '0.813rem',
              [mq[3]]: {
                width: '100%',
              },
            }}
          >
            Cancel
          </CXButton>

          <div css={{ marginRight: '1rem' }} />
          <CXButton
            variant="primary"
            type="submit"
            onClick={() => setIsOpen(true)}
            customStyles={{
              fontSize: '0.813rem',
              [mq[3]]: {
                marginTop: '1rem',
                width: '100%',
              },
            }}
          >
            {confirmBtnText}
          </CXButton>
        </div>
      </div>
    </CommonModal>
  );
};

export default UnsavedConfirmationModal;
