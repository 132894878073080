import { useContext, useEffect, useState } from 'react';
import { Control, Path } from 'react-hook-form';

import { FormMultiSelect } from 'components';
import { useIsMounted } from 'helpers';
import { getCreatorList } from 'helpers/apiCaller';
import ProfileContext from 'store/ProfileContext';

interface CreatorSelectProps<T> {
  dataField: Path<T>;
  control: Control<T>;
}

const CreatorSelect = <T,>({
  dataField,
  control,
}: CreatorSelectProps<T>): JSX.Element => {
  const { galacxyId } = useContext(ProfileContext);
  const [dataList, setDataList] = useState<string[]>([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    const getCreators = async () => {
      const creatorList = await getCreatorList();

      if (isMounted()) {
        setDataList(creatorList);
      }
    };

    void getCreators();
  }, [galacxyId, isMounted]);

  return (
    <FormMultiSelect
      label="Creator"
      dataArr={dataList.map((x) => ({
        value: x,
        name: x,
      }))}
      control={control}
      dataField={dataField}
    />
  );
};

export default CreatorSelect;
