import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  GlobalStyles,
  Notification,
  SpinningLoader,
  initModal,
} from 'components';
import { loginRequest } from 'config/authConfig';
import serverConfig from 'config/serverConfig';
import { getUserGroupName, isNonCXUser, to } from 'helpers';
import { getUserProfile } from 'helpers/apiCaller';
import { LOADING, USER_GROUP } from 'helpers/constants';
import analytics from 'helpers/firebase';
import { IUserProfileResult } from 'models/ProfileData';
import {
  AccessDenied,
  ApprovalDetail,
  Approver,
  Author,
  Download,
  Export,
  Home,
  NotFound,
  UserRole,
} from 'screens';
import ProfileContext from 'store/ProfileContext';

initModal();

const RedirectDefaultHome = ({
  isProfileLoaded,
}: {
  isProfileLoaded: boolean;
}) => {
  const { userGroup } = useContext(ProfileContext);
  const location = useLocation();

  if (!isProfileLoaded) {
    return <SpinningLoader isNewLoadingIcon={true} loaderText={LOADING} />;
  }

  if (userGroup && isNonCXUser(userGroup)) {
    const userGroupName = getUserGroupName(userGroup)?.toLowerCase();
    if (userGroupName)
      return (
        <Navigate
          to={`/viewer-${userGroupName}`}
          state={{ from: location }}
          replace
        />
      );
  }

  return <Outlet />;
};

const Pages = () => {
  const [graphData, setGraphData] = useState<IUserProfileResult>();
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!graphData) {
        const [, graphResp] = await to(getUserProfile());

        if (graphResp) {
          setGraphData(graphResp);
        }
      }
    };

    void getData();
  }, [graphData]);

  return (
    <ProfileContext.Provider
      value={{
        name: graphData?.name || '',
        galacxyId: graphData?.galacxyId || '',
        email: graphData?.email || '',
        jobTitle: graphData?.jobTitle || '',
        department: graphData?.department || '',
        companyName: graphData?.companyName || '',
        userGroup: graphData?.userGroup || '',
        userRoles: graphData?.userRoles || [],
        isShowSidebar,
        setIsShowSidebar,
      }}
    >
      <GlobalStyles />
      <div id="root">
        <Routes>
          <Route
            element={<RedirectDefaultHome isProfileLoaded={!!graphData} />}
          >
            <Route
              path="/"
              element={<Home userGroup={USER_GROUP.CX.value} />}
            />
          </Route>
          <Route path="/viewer/:id" element={<ApprovalDetail />} />
          <Route path="/download/:fileName" element={<Download />} />

          <Route path="/user-role" element={<UserRole />} />
          <Route path="/export" element={<Export />} />

          <Route
            path="/bookmark"
            element={<Home userGroup={USER_GROUP.CX.value} isBookmark />}
          />

          {/* APPROVER ROUTES */}
          <Route
            path="/approver"
            element={<Approver userGroup={USER_GROUP.CX.value} />}
          />
          <Route
            path="/approver/download/:fileName"
            element={<Download isApprover />}
          />
          <Route
            path="/approver/download/riskAccessAttachment/:fileName"
            element={<Download isApprover isRiskAccessAttachment />}
          />
          <Route
            path="/approver/:id"
            element={
              <ApprovalDetail isApprover userGroup={USER_GROUP.CX.value} />
            }
          />

          {/* AUTHOR ROUTES */}
          <Route
            path="/author"
            element={<Author userGroup={USER_GROUP.CX.value} />}
          />
          <Route
            path="/author/download/riskAccessAttachment/:fileName"
            element={<Download isAuthor isRiskAccessAttachment />}
          />
          <Route
            path="/author/download/:fileName"
            element={<Download isAuthor />}
          />
          <Route path="/author/:id" element={<ApprovalDetail isAuthor />} />

          {/* CASL ROUTES */}
          <Route
            path="/viewer-casl"
            element={<Home userGroup={USER_GROUP.CASL.value} />}
          />
          <Route
            path="/viewer-casl/:id"
            element={<ApprovalDetail userGroup={USER_GROUP.CASL.value} />}
          />
          <Route
            path="/author-casl"
            element={<Author userGroup={USER_GROUP.CASL.value} />}
          />
          <Route
            path="/author-casl/:id"
            element={
              <ApprovalDetail isAuthor userGroup={USER_GROUP.CASL.value} />
            }
          />
          <Route
            path="/export-casl"
            element={<Export userGroup={USER_GROUP.CASL.value} />}
          />
          <Route
            path="/bookmark-casl"
            element={<Home userGroup={USER_GROUP.CASL.value} isBookmark />}
          />

          {/* HAECO ROUTES    */}
          <Route
            path="/viewer-haeco"
            element={<Home userGroup={USER_GROUP.HAECO.value} />}
          />
          <Route
            path="/viewer-haeco/:id"
            element={<ApprovalDetail userGroup={USER_GROUP.HAECO.value} />}
          />
          <Route
            path="/author-haeco"
            element={<Author userGroup={USER_GROUP.HAECO.value} />}
          />
          <Route
            path="/author-haeco/:id"
            element={
              <ApprovalDetail isAuthor userGroup={USER_GROUP.HAECO.value} />
            }
          />
          <Route
            path="/export-haeco"
            element={<Export userGroup={USER_GROUP.HAECO.value} />}
          />
          <Route
            path="/bookmark-haeco"
            element={<Home userGroup={USER_GROUP.HAECO.value} isBookmark />}
          />

          <Route path="/unauthorized" element={<AccessDenied />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </ProfileContext.Provider>
  );
};

const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => (
  <h1>An Error Occurred: {error ? error.errorCode : 'unknown error'}</h1>
);

interface RequestInterceptorProps {
  children: JSX.Element;
}

const RequestResInterCeptor: React.FC<RequestInterceptorProps> = ({
  children,
}: RequestInterceptorProps) => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const account = accounts[0];

  axios.interceptors.request.use(async (config) => {
    if (!account) {
      throw Error('No active account');
    }

    const accessTokenRequest = {
      ...loginRequest,
      account,
    };

    const [err, tokenResponse] = await to(
      instance.acquireTokenSilent(accessTokenRequest),
    );

    if (err instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(accessTokenRequest);
    }

    if (tokenResponse) {
      const bearer = `Bearer ${tokenResponse.accessToken}`;
      const { headers } = config;
      headers.Authorization = bearer;
      config.headers.Authorization = bearer;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        navigate('/unauthorized');
      }
      throw error;
    },
  );
  return <>{children}</>;
};

const App = ({
  msalInstance,
}: {
  msalInstance: IPublicClientApplication;
}): JSX.Element => {
  useEffect(() => {
    analytics();
  }, [analytics]);

  let siteTitle = 'Operation Knowledge Repository';

  if (serverConfig.isDev) {
    siteTitle = `[${serverConfig.environment}] ${siteTitle}`;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteTitle}</title>
      </Helmet>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        // loadingComponent={Loading}
      >
        <AuthenticatedTemplate>
          <Notification />
          <RequestResInterCeptor>
            <Pages />
          </RequestResInterCeptor>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <h1>Unauthenticated</h1>
        </UnauthenticatedTemplate>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default App;
