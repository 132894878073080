/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { COLORS } from 'helpers/colors';

export const notify = ({
  message,
  type,
}: {
  message: string;
  type: 'success' | 'error' | 'warning' | 'reminder';
}): void => {
  toast(message, {
    className: `toast-${type}`,
    closeButton: false,
  });
};

const CXNotification = (): JSX.Element => {
  return (
    <div
      css={css`
        .toast-success {
          --toastify-color-success: ${COLORS.green};
          --toastify-text-color-light: ${COLORS.white};
          background-color: ${COLORS.green4};
        }

        .toast-error {
          --toastify-color-error: ${COLORS.red3};
          --toastify-text-color-light: ${COLORS.white};
          background-color: ${COLORS.red};
        }
      `}
    >
      <ToastContainer hideProgressBar />
    </div>
  );
};

export default CXNotification;
