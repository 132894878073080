/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { Controller } from 'react-hook-form';

import { ErrorMessageWrapper, Select } from 'components';

import { CommonFormControlProps } from '../../@types/formControl';

interface CXSingleDropDownProps<T> extends CommonFormControlProps<T> {
  disabled?: boolean;
  dataArr: { name: string; value: string }[];
}

const CXSingleDropDown = <T,>({
  label,
  dataArr,
  dataField,
  control,
  rules,
  disabled,
}: CXSingleDropDownProps<T>): JSX.Element => {
  return (
    <div css={{ flex: 1 }}>
      <Controller
        name={dataField}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState: { errors },
        }) => (
          <>
            <Select
              label={label || ''}
              hasError={!!error}
              singleSelectValue={value as string}
              disabled={disabled}
              settings={{
                isTag: true,
              }}
              dataSource={[{ name: 'None', value: '' }, ...dataArr]}
              onSingleSelect={(e: string) => {
                onChange(e);
              }}
            />
            <ErrorMessageWrapper errors={errors} dataField={dataField} />
          </>
        )}
      />
    </div>
  );
};

export default CXSingleDropDown;
