import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(customParseFormat);
dayjs.extend(duration);

export const formatForDatePicker = (value: Date | null): string | undefined => {
  const convertedDate = dayjs(value);

  if (convertedDate.isValid()) {
    return convertedDate.format('YYYY-MM-DD');
  }
};

export const formatToDate = (value: string): string =>
  dayjs(value).format('DDMMMYYYY');

export const convertToDate = (value: string): Date | undefined => {
  if (value) {
    return dayjs(value).toDate();
  }
};

export const formatToFullDate = (value?: Date): string => {
  if (value) return dayjs(value).format('DD MMM YYYY');

  return '';
};

export const formatToFullDateTime = (value?: Date): string => {
  if (value) return dayjs(value).format('DD MMM YYYY HH:mm');

  return '';
};

export const getYear = (): number => dayjs().year();

export const formatInDaysHoursMins = (value: number): string =>
  dayjs.duration(value, 'minutes').format('D[d]H[hr]m[m]');
