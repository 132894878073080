/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import {
  CXButton,
  CommonContainer,
  FormSingleDropDown,
  notify,
} from 'components';
import { downloadFile, mq } from 'helpers';
import { getExportData } from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import { SUMMARY_DOWNLOAD_START_YEAR } from 'helpers/constants';
import { getYear } from 'helpers/date';

interface IExportSubmit {
  year: number;
}

const Export = ({ userGroup }: { userGroup?: string }): JSX.Element => {
  const { handleSubmit, control } = useForm<IExportSubmit>();

  const onSubmit: SubmitHandler<IExportSubmit> = async (data) => {
    const { file, fileName } = await getExportData(data.year, userGroup);

    if (!file) {
      console.log('submit error');
      notify({
        message: 'Cannot export the list.',
        type: 'error',
      });
    } else {
      downloadFile({ file, fileName });
    }
  };

  const onError: SubmitErrorHandler<IExportSubmit> = (errors) => {
    console.log('showing error', errors);
    notify({ message: 'Cannot export the list', type: 'error' });
  };

  const dataArr = [];

  for (let i = SUMMARY_DOWNLOAD_START_YEAR; i <= getYear(); i += 1) {
    dataArr.push(i.toString());
  }

  return (
    <CommonContainer>
      <div css={{ padding: '1rem 1rem 2rem 1rem', flex: 1 }}>
        <h1
          css={{
            fontSize: '2rem',
            fontWeight: 'bold',
            color: COLORS.grey3,
          }}
        >
          Export List
        </h1>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div css={{ marginTop: '1.5rem', display: 'flex' }}>
            <div css={{ flex: 1 }}>
              <FormSingleDropDown
                label="Select submission year to export"
                dataField="year"
                control={control}
                dataArr={dataArr.map((x) => ({ value: x, name: x }))}
                rules={{ required: true }}
              />
            </div>
            <div
              css={{
                flex: 3,
                [mq[1]]: {
                  flex: 0,
                },
              }}
            />
          </div>
          <div css={{ marginTop: '2.5rem' }}>
            <CXButton type="submit" variant="primary">
              Export List
            </CXButton>
          </div>
        </form>
      </div>
    </CommonContainer>
  );
};

export default Export;
