import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { CommonContainer } from 'components';

const AccessDenied = (): JSX.Element => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    navigate('/');
  }

  return <CommonContainer>Access Denied</CommonContainer>;
};

export default AccessDenied;
