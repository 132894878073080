/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { CSSProperties } from 'react';
import Modal from 'react-modal';

import { Svg } from 'components';
import { mq, useIsMobile, useIsTablet } from 'helpers';
import { COLORS } from 'helpers/colors';
import { MODAL_SIZES } from 'helpers/constants';

export const initModal = (): void => Modal.setAppElement('#root');

interface CommonModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  children: React.ReactNode;
  width?: string;
  customTitleStyles?: CSSProperties;
  customDialogStyles?: CSSProperties;
}

const CommonModal = ({
  isOpen,
  setIsOpen,
  title,
  children,
  width,
  customTitleStyles = {},
}: CommonModalProps): JSX.Element => {
  let contentStyles: CSSProperties = {
    position: 'absolute',
    top: '12%',
    bottom: '12%',
    backgroundColor: COLORS.white,
    borderRadius: '1rem',
    overflow: 'auto',
    height: 'fit-content',
    maxHeight: '80vh',
    padding: 0,
  };

  const isSmallWidth = width === MODAL_SIZES.SMALL;

  const isMobile = useIsMobile();
  const isTab = useIsTablet();

  if (isSmallWidth) {
    contentStyles = {
      ...contentStyles,
      borderTop: `0.25rem solid ${COLORS.red}`,
      left: isMobile ? '3%' : isTab ? '20%' : '25%',
      right: isMobile ? '3%' : isTab ? '20%' : '25%',
    };
  } else {
    contentStyles = {
      ...contentStyles,
      left: isMobile ? '3%' : isTab ? '10%' : '16%',
      right: isMobile ? '3%' : isTab ? '10%' : '16%',
    };
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: COLORS.grey15,
          backdropFilter: 'blur(2.71828px)',
          zIndex: 2,
        },
        content: contentStyles,
      }}
    >
      <div
        css={{
          paddingLeft: '3.75rem',
          paddingRight: '3.75rem',
          paddingTop: '2.5rem',
          paddingBottom: '3.5rem',
          marginTop: 0,
          [mq[1]]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
          ...(isSmallWidth && {
            paddingLeft: '2.75rem',
            paddingRight: '2.75rem',
            paddingTop: '1.5rem',
            paddingBottom: '2.5rem',
          }),
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <span
            css={{
              fontWeight: '700',
              fontSize: '1.5rem',
              ...customTitleStyles,
            }}
          >
            {title}
          </span>
          <div css={{ flex: 1 }} />
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            role="presentation"
          >
            <span
              css={{
                cursor: 'pointer',
              }}
            >
              <Svg icon="black-cross" size={2} />
            </span>
          </div>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default CommonModal;
