/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import { CommonContainer, SearchDocument } from 'components';

const Home = ({
  userGroup,
  isBookmark,
}: {
  userGroup: string;
  isBookmark?: boolean;
}): JSX.Element => {
  return (
    <CommonContainer>
      <SearchDocument
        isViewer={true}
        isApprover={false}
        userGroup={userGroup}
        isBookmark={isBookmark}
      />
    </CommonContainer>
  );
};

export default Home;
