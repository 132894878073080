/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import { CXButton, CommonModal } from 'components';
import { mq } from 'helpers';

const RejectModal = ({
  isOpen,
  setIsOpen,
  setValue,
  rejectReason,
  submitApproval,
  submitCancelApproval,
  isCancellation,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setValue?: () => void;
  rejectReason?: string;
  submitApproval?: (isApproved: boolean) => Promise<void>;
  submitCancelApproval?: () => Promise<void>;
  isCancellation?: boolean;
}): JSX.Element => {
  const title = `Are you sure you want to reject this ${
    isCancellation ? 'cancellation' : 'submission'
  }?`;
  return (
    <CommonModal isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      <div
        css={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'column' }}
      >
        <span css={{ fontWeight: '700', fontSize: '1.5rem' }}>
          Reject reason
        </span>
        <span css={{ fontWeight: '400', fontSize: '1.5rem' }}>
          {rejectReason}
        </span>
        <div
          css={{ display: 'flex', flexDirection: 'row', marginTop: '2.5rem' }}
        >
          <div css={{ flex: 1 }} />
          <CXButton
            variant="primary"
            type="button"
            customStyles={{
              [mq[2]]: {
                width: '100%',
              },
            }}
            onClick={async () => {
              if (setValue) {
                setValue();
              }
              if (submitCancelApproval) {
                await submitCancelApproval();
              } else if (submitApproval) {
                await submitApproval(false);
              }
              setIsOpen(false);
            }}
          >
            Reject {`${isCancellation ? 'Cancellation' : 'Document'}`}
          </CXButton>
        </div>
      </div>
    </CommonModal>
  );
};

export default RejectModal;
