/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as ExpandWindow } from 'assets/icons/expand_window.svg';
import { ReactComponent as Add } from 'assets/icons/icon_add.svg';
import { ReactComponent as Attachment } from 'assets/icons/icon_attachment.svg';
import { ReactComponent as Bookmark } from 'assets/icons/icon_bookmark.svg';
import { ReactComponent as Calendar } from 'assets/icons/icon_calendar.svg';
import { ReactComponent as BlackCross } from 'assets/icons/icon_cross_black.svg';
import { ReactComponent as WhiteCross } from 'assets/icons/icon_cross_white.svg';
import { ReactComponent as Doc } from 'assets/icons/icon_doc.svg';
import { ReactComponent as DownArrow } from 'assets/icons/icon_down_arrow.svg';
import { ReactComponent as Export } from 'assets/icons/icon_export.svg';
import { ReactComponent as Favorite } from 'assets/icons/icon_favorite.svg';
import { ReactComponent as FavoriteActive } from 'assets/icons/icon_favorite_active.svg';
import { ReactComponent as FilterCollapse } from 'assets/icons/icon_filter_collapse.svg';
import { ReactComponent as FilterExpand } from 'assets/icons/icon_filter_expand.svg';
import { ReactComponent as FilterFavorite } from 'assets/icons/icon_filter_favorite.svg';
import { ReactComponent as FilterFavoriteActive } from 'assets/icons/icon_filter_favorite_active.svg';
import { ReactComponent as Setting } from 'assets/icons/icon_setting.svg';
import { ReactComponent as Sorting } from 'assets/icons/icon_sorting.svg';
import { ReactComponent as SortingAsc } from 'assets/icons/icon_sorting_asc.svg';
import { ReactComponent as SortingDesc } from 'assets/icons/icon_sorting_desc.svg';
import { ReactComponent as Urgent } from 'assets/icons/icon_urgent.svg';
import { ReactComponent as UserRole } from 'assets/icons/icon_user_role.svg';

export const SvgComponents = {
  add: Add,
  edit: Edit,
  doc: Doc,
  setting: Setting,
  export: Export,
  attachment: Attachment,
  'white-cross': WhiteCross,
  'expand-window': ExpandWindow,
  favorite: Favorite,
  'favorite-active': FavoriteActive,
  'filter-collapse': FilterCollapse,
  'filter-expand': FilterExpand,
  'filter-favorite': FilterFavorite,
  'filter-favorite-active': FilterFavoriteActive,
  calendar: Calendar,
  'black-cross': BlackCross,
  'down-arrow': DownArrow,
  sorting: Sorting,
  'sorting-asc': SortingAsc,
  'sorting-desc': SortingDesc,
  urgent: Urgent,
  'user-role': UserRole,
  bookmark: Bookmark,
};

const Svg = ({
  icon,
  size,
  ...props
}: {
  icon: keyof typeof SvgComponents;
  size?: number;
}): JSX.Element => {
  const SvgComponent = SvgComponents[icon];

  return (
    <SvgComponent
      css={{ transform: size ? `scale(${size})` : undefined }}
      {...props}
    />
  );
};

export default Svg;
