import { useContext, useEffect, useState } from 'react';
import { Control, Path } from 'react-hook-form';

import { FormMultiSelect, ISourceGroupData } from 'components';
import { useIsMounted } from 'helpers';
import { getAssigneeList } from 'helpers/apiCaller';
import { USER_ROLE, WORK_FLOW_STATUS } from 'helpers/constants';
import ProfileContext from 'store/ProfileContext';

interface AssigneeSelectProps<T> {
  dataField: Path<T>;
  control: Control<T>;
}

const AssigneeSelect = <T,>({
  dataField,
  control,
}: AssigneeSelectProps<T>): JSX.Element => {
  const { galacxyId } = useContext(ProfileContext);
  const [dataList, setDataList] = useState<ISourceGroupData[]>([]);
  const isMounted = useIsMounted();

  useEffect(() => {
    const getAssignees = async () => {
      let assigneeList = await getAssigneeList();

      if (isMounted()) {
        const assigneeGpData: ISourceGroupData[] = [
          {
            groupName: 'Approver Group',
            listOfOptions: [
              {
                name: WORK_FLOW_STATUS.LV_C_MANAGER_PENDING_APPROVE.fullName,
                value: USER_ROLE.LV_C_MANAGER.name,
              },
              {
                name: WORK_FLOW_STATUS.QA_MANAGER_PENDING_APPROVE.fullName,
                value: USER_ROLE.QA_MANAGER.name,
              },
              {
                name: WORK_FLOW_STATUS.TPC_PENDING_APPROVE.fullName,
                value: USER_ROLE.TPC.name,
              },
              {
                name: WORK_FLOW_STATUS.HK_OPS_PENDING_APPROVE.fullName,
                value: USER_ROLE.HK_OPS.name,
              },
            ],
          },
          {
            groupName: 'Assignee',
            listOfOptions: [],
          },
        ];

        if (!assigneeList.find((x) => x === galacxyId)) {
          assigneeList = assigneeList.concat(galacxyId);
        }

        assigneeGpData[1].listOfOptions = assigneeList.map((x) => ({
          name: x,
          value: x,
        }));

        setDataList(assigneeGpData);
      }
    };

    void getAssignees();
  }, [galacxyId, isMounted]);

  return (
    <FormMultiSelect
      label="Assignee"
      dataArr={dataList}
      control={control}
      dataField={dataField}
      isGroup
    />
  );
};

export default AssigneeSelect;
