/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import { CommonContainer, SearchDocument } from 'components';

const Author = ({ userGroup }: { userGroup: string }): JSX.Element => {
  return (
    <CommonContainer>
      <SearchDocument
        isViewer={false}
        isApprover={false}
        userGroup={userGroup}
      />
    </CommonContainer>
  );
};

export default Author;
