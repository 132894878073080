/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { CSSProperties } from '@emotion/serialize';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import {
  FieldErrorsImpl,
  Path,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

import { ErrorMessageWrapper, Svg, SvgComponents } from 'components';
import { COLORS } from 'helpers/colors';

interface CXFileUploadProps<T> {
  watch: UseFormWatch<T>;
  register: UseFormRegister<T>;
  dataField: Path<T>;
  clearFile: () => void;
  errors: FieldErrorsImpl;
  isVideo?: boolean;
  text: string;
  icon?: keyof typeof SvgComponents;
  customBtnStyles?: CSSProperties;
  accept?: string;
  editFileName?: string;
}

const CXFileUpload = <T,>({
  watch,
  register,
  clearFile,
  dataField,
  errors,
  text,
  editFileName,
  icon,
  customBtnStyles,
  accept,
}: CXFileUploadProps<T>): JSX.Element => {
  const [fileName, setFileName] = useState<string>();

  useEffect(() => {
    const fileName =
      (get(watch(dataField), '[0].name') as string) || editFileName;
    setFileName(fileName);
  }, [watch(dataField), editFileName]);

  const hasError = !!get(errors, dataField);

  return (
    <>
      {fileName ? (
        <div css={{ display: 'inline-flex', flexDirection: 'column' }}>
          <div
            css={{
              padding: '0.75rem',
              border: `1px solid ${hasError ? COLORS.red : COLORS.grey7}`,
              color: hasError ? COLORS.red : undefined,
              borderRadius: '2px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span
              css={{
                fontSize: '1rem',
                lineHeight: '1.1875rem',
              }}
            >
              {fileName}
            </span>
            {!editFileName ? (
              <div
                onClick={() => {
                  clearFile();
                }}
                role="presentation"
              >
                <span
                  css={{
                    cursor: 'pointer',
                    marginLeft: '1.75rem',
                  }}
                >
                  <Svg icon="black-cross" size={1.5} />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <label
          css={{
            display: icon ? 'flex' : 'inline-block',
            border: `2px solid ${hasError ? COLORS.red : COLORS.blue5}`,
            boxSizing: 'border-box',
            borderRadius: '8px',
            padding: icon ? '0.5rem' : '1rem 4rem',
            width: icon ? '9rem' : 'auto',
            color: hasError ? COLORS.red : COLORS.blue5,
            fontWeight: 'bold',
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
            cursor: 'pointer',
            position: 'relative',
            ...customBtnStyles,
          }}
        >
          {icon ? (
            <span
              css={{
                margin: '0 0.2rem',
              }}
            >
              <Svg icon={icon} />
            </span>
          ) : null}
          <input
            css={{ display: 'none' }}
            {...register(dataField)}
            type="file"
            accept={accept}
          />
          <span>{text}</span>
        </label>
      )}
      {hasError && (
        <div>
          <ErrorMessageWrapper errors={errors} dataField={dataField} />
        </div>
      )}
    </>
  );
};

export default CXFileUpload;
