export const COLORS = {
  white: '#ffffff',
  white2: '#FEFBE6',
  white3: '#f9f9f9',
  white4: '#F5F5F5',
  grey: '#4c4c4c',
  grey2: '#737373',
  grey3: '#2d2d2d',
  grey4: '#999b9d',
  grey5: '#dee2e6',
  grey6: '#6c757d',
  grey7: '#C4C4C4',
  grey8: '#D8D8D8',
  grey9: '#E9EAEC',
  grey10: '#EDEDED',
  grey11: '#808285',
  grey12: '#F1F1F1',
  grey13: '#C0B498',
  grey14: '#C8CED3',
  grey15: '#4C4C4C80',
  grey16: '#8E8E9380',
  grey17: '#e0e0e0cc',
  grey18: '#C9CBCC',
  blue: '#11709A',
  blue2: '#DBE6EC',
  blue3: '#4C86A0',
  blue4: '#1B3668',
  blue5: '#116F9A',
  blue6: '#2D5060',
  red: '#D11F00',
  red2: '#FAE9E5',
  red3: '#c74141',
  yellow: '#AD9306',
  yellow2: '#FBF8E7',
  yellow3: '#EECC7E',
  yellow4: '#FDF7F6',
  green: '#006564',
  green2: '#EDF5F5',
  green3: '#E5EFEF',
  green4: '#387e79',
  maroon: '#822C42',
  black: '#000000',
  black2: '#0000005c',
  black3: '#000000cc',
};
