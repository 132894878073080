import { createContext } from 'react';

import { IUserProfileResult } from 'models/ProfileData';

const defaultState = {
  name: '',
  galacxyId: '',
  email: '',
  jobTitle: '',
  department: '',
  companyName: '',
  userGroup: '',
  userRoles: [],
  accessToken: '',
  isShowSidebar: false,
  setIsShowSidebar: () => null,
};

const ProfileContext = createContext<IUserProfileResult>(defaultState);

export default ProfileContext;
