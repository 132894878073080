import { Global, css } from '@emotion/react';

import { COLORS } from 'helpers/colors';

import fontBold from 'assets/fonts/CathaySans_WCorp_Bd.ttf';
import fontLight from 'assets/fonts/CathaySans_WCorp_Lt.ttf';
import fontMedium from 'assets/fonts/CathaySans_WCorp_Md.ttf';
import fontRegular from 'assets/fonts/CathaySans_WCorp_Rg.ttf';

const GlobalStyles = (): JSX.Element => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'CathaySans Light';
          src: local('CathaySans Light'), url(${fontLight}) format('truetype');
        }

        @font-face {
          font-family: 'CathaySans Regular';
          src: local('CathaySans Regular'),
            url(${fontRegular}) format('truetype');
        }

        @font-face {
          font-family: 'CathaySans Bold';
          src: local('CathaySans Bold'), url(${fontBold}) format('truetype');
        }

        @font-face {
          font-family: 'CathaySans Medium';
          src: local('CathaySans Medium'), url(${fontMedium}) format('truetype');
        }

        body {
          margin: 0;
          font-family: 'CathaySans Regular', -apple-system, BlinkMacSystemFont,
            'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: ${COLORS.white};
          font-weight: 400;
          line-height: 1.5;
        }

        *,
        :after,
        :before {
          box-sizing: border-box;
        }

        input,
        textarea,
        button {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
        }

        img,
        svg {
          vertical-align: middle;
        }

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          line-height: 1.2;
          margin-bottom: 0.5rem;
        }

        table {
          border-collapse: collapse;
          caption-side: bottom;
        }

        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
          border: 0 solid;
          border-color: inherit;
        }

        th {
          text-align: inherit;
          text-align: -webkit-match-parent;
        }

        dl,
        ol,
        ul {
          margin-bottom: 1rem;
          margin-top: 0;
        }

        [type='button']:not(:disabled),
        [type='reset']:not(:disabled),
        [type='submit']:not(:disabled),
        button:not(:disabled) {
          cursor: pointer;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }

        .app {
          background-color: ${COLORS.white};
          color: ${COLORS.grey};
          font-size: 1rem;
          .h1,
          .h2,
          .h3,
          .h4,
          .h5,
          .h6,
          .bold,
          strong {
            font-weight: 700;
            font-family: 'CathaySans Bold';
          }

          .container-fluid {
            width: 99%;
            padding: 32px 98px 32px 49px;
          }

          a {
            color: ${COLORS.grey};
            text-decoration: none;
            background-color: transparent;
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
