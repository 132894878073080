/** @jsxImportSource @emotion/react */
import { FC, forwardRef, useRef } from 'react';

import { assignRefs } from 'helpers';
import { COLORS } from 'helpers/colors';

interface InputProps {
  label: string;
  id: string;
  value: string;
  defaultChecked: boolean;
  onChange?(event: React.FormEvent<unknown>): void;
}

const RadioButton: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ label, id, value, defaultChecked, onChange, ...props }, ref) => {
    const localRef = useRef<HTMLInputElement | null>(null);

    return (
      <div
        css={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
        }}
        key={id}
      >
        <input
          css={{
            appearance: 'none',
            cursor: 'pointer',
            margin: 0,
            width: '1.25rem',
            height: '1.25rem',
            border: `0.125rem solid ${COLORS.grey8}`,
            borderRadius: '50%',
            ':after': {
              content: '""',
              display: 'block',
              borderRadius: '50%',
              width: '0.75rem',
              height: '0.75rem',
              margin: '0.125rem',
            },
            ':hover': {
              ':after': {
                backgroundColor: COLORS.grey8,
              },
            },
            ':checked': {
              border: `0.125rem solid ${COLORS.grey}`,
              ':after': {
                backgroundColor: COLORS.grey,
              },
              ':hover': {
                backgroundColor: 'white',
                border: `0.125rem solid ${COLORS.grey}`,
                ':after': {
                  backgroundColor: COLORS.grey,
                },
              },
            },
          }}
          type="radio"
          id={id}
          value={value}
          ref={assignRefs(localRef, ref)}
          {...props}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <label
          css={{
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: 400,
            color: COLORS.grey,
          }}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    );
  },
);

export default RadioButton;
