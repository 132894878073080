import serverConfig from 'config/serverConfig';
import { COLORS } from 'helpers/colors';

export const USER_GROUP: {
  [k: string]: {
    value: string;
    display: string;
    color?: string;
    background?: string;
  };
} = {
  HAECO: {
    value: '1',
    display: 'HAECO',
    color: '#1D93CA',
    background: '#DAF1FB',
  },
  CASL: {
    value: '2',
    display: 'CASL',
    color: '#E2931D',
    background: '#F8ECD9',
  },
  CX: {
    value: '3',
    display: 'CX',
    color: '#006564',
    background: '#DAF2F2',
  },
  NONE: {
    value: '0',
    display: 'None',
  },
};

export const DOCUMENT_TYPE: {
  [k: string]: {
    name: string;
    display: string;
    isAllowOnlyViewer: boolean;
    isEnable: boolean;
    allowedUserGroup: string[];
  };
} = {
  ANL: {
    name: 'ANL',
    display: 'ANL',
    isAllowOnlyViewer: false,
    isEnable: true,
    allowedUserGroup: [USER_GROUP.CX.value],
  },
  QN: {
    name: 'QN',
    display: 'QN',
    isAllowOnlyViewer: false,
    isEnable: true,
    allowedUserGroup: [USER_GROUP.CX.value],
  },
  LESSON_LEARN: {
    name: 'Lesson Learned',
    display: 'LL',
    isAllowOnlyViewer: false,
    isEnable: true,
    allowedUserGroup: Object.keys(USER_GROUP).map(
      (key) => USER_GROUP[key].value,
    ),
  },
  OTHERS: {
    name: 'Others',
    display: 'Others',
    isAllowOnlyViewer: false,
    isEnable: true,
    allowedUserGroup: Object.keys(USER_GROUP).map(
      (key) => USER_GROUP[key].value,
    ),
  },
  TR: {
    name: 'TR',
    display: 'TR',
    isAllowOnlyViewer: false,
    isEnable: false,
    allowedUserGroup: [USER_GROUP.CX.value],
  },
  VIDEO: {
    name: 'Video',
    display: 'Video',
    isAllowOnlyViewer: false,
    isEnable: true,
    allowedUserGroup: Object.keys(USER_GROUP).map(
      (key) => USER_GROUP[key].value,
    ),
  },
  INV: {
    name: 'Inventory Notice',
    display: 'INV',
    isAllowOnlyViewer: false,
    isEnable: true,
    allowedUserGroup: [USER_GROUP.CX.value],
  },
  AOG: {
    name: 'AOG',
    display: 'AOG',
    isAllowOnlyViewer: true,
    isEnable: !serverConfig.isDisableAog,
    allowedUserGroup: Object.keys(USER_GROUP).map(
      (key) => USER_GROUP[key].value,
    ),
  },
};

export const FLEET_ARR = [
  'A300',
  'A320/A321',
  'A330',
  'A350',
  'B744',
  'B748',
  'B777',
  'B777-9',
];

export const OPERATOR_ARR = ['CX', 'KA', 'AHK'];

export const ATA_CHAPTER_ARR = [
  { value: '00', name: 'General' },
  { value: '05', name: 'Time Limits/Maintenance Checks' },
  { value: '10', name: 'Parking, Mooring, Storage And Return To Service' },
  { value: '11', name: 'Placards And Markings' },
  { value: '12', name: 'Servicing' },
  { value: '20', name: 'Standard Practices- Airframe' },
  { value: '21', name: 'Air Conditioning' },
  { value: '22', name: 'Auto Flight' },
  { value: '23', name: 'Communication' },
  { value: '24', name: 'Electrical Power' },
  { value: '25', name: 'Equipment /Furnishings' },
  { value: '26', name: 'Fire Protection' },
  { value: '27', name: 'Flight Controls' },
  { value: '28', name: 'Fuel' },
  { value: '29', name: 'Hydraulic Power' },
  { value: '30', name: 'Ice And Rain Protection' },
  { value: '31', name: 'Indicating / Recording System' },
  { value: '32', name: 'Landing Gear' },
  { value: '33', name: 'Lights' },
  { value: '34', name: 'Navigation' },
  { value: '35', name: 'Oxygen' },
  { value: '36', name: 'Pneumatic' },
  { value: '38', name: 'Water / Waste' },
  { value: '42', name: 'Integrated Modular Avionics' },
  { value: '44', name: 'Cabin Systems' },
  { value: '45', name: 'Onboard Maintenance Systems (Oms)' },
  { value: '46', name: 'Information Systems' },
  { value: '47', name: 'Inert Gas System' },
  { value: '49', name: 'Airborne Auxiliary Power' },
  { value: '50', name: 'Cargo And Accessory Compartments' },
  { value: '51', name: 'Standard Practices And Structures - General' },
  { value: '52', name: 'Doors' },
  { value: '53', name: 'Fuselage' },
  { value: '54', name: 'Nacelles/Pylons' },
  { value: '55', name: 'Stabilizers' },
  { value: '56', name: 'Windows' },
  { value: '57', name: 'Wings' },
  { value: '71', name: 'Power Plant' },
  { value: '72', name: 'Engine' },
  { value: '73', name: 'Engine - Fuel And Control' },
  { value: '74', name: 'Ignition' },
  { value: '75', name: 'Bleed Air' },
  { value: '76', name: 'Engine Controls' },
  { value: '77', name: 'Engine Indicating' },
  { value: '78', name: 'Exhaust' },
  { value: '79', name: 'Oil' },
  { value: '80', name: 'Starting' },
  { value: '92', name: 'Electrical Power Multiplexing' },
];

export const RISK_ASSESSMENT_ARR = [
  { name: 'Yes, Safety Risk Assessment (SRA) attached', value: 'Y1' },
  { name: 'Yes, Risk Register Form attached', value: 'Y2' },
  { name: 'No, reason', value: 'N' },
];

export const ATTACHMENT_LINK_TEXT = 'attached';

export const AUDIENCE_GROUP_ARR = [
  { value: '01', name: 'Fleet Specific Certifying Staff' },
  { value: '02', name: 'All Certifying Staff' },
  { value: '03', name: 'All Internal Staff' },
  { value: '04', name: 'All External Staff' },
  { value: '05', name: 'CASL_TSG' },
  { value: '06', name: 'CX_IOCT' },
  { value: '07', name: 'FST_A350' },
  { value: '08', name: 'FST-AHK' },
  { value: '09', name: 'FST_AIRBUS' },
  { value: '10', name: 'FST_BOEING' },
  { value: '11', name: 'FST_PP' },
  { value: '12', name: 'HX_CGO' },
  { value: '13', name: 'HX_CMC' },
  { value: '14', name: 'HX_COE' },
  { value: '15', name: 'HX_DYM' },
  { value: '16', name: 'HX_ITM' },
  { value: '17', name: 'HX_PML' },
  { value: '18', name: 'HX_TMC' },
  { value: '19', name: 'HX_TS' },
  { value: '20', name: 'MC_COE' },
  { value: '21', name: 'MC_IOC' },
  { value: '22', name: 'MS_AIRBUS' },
  { value: '23', name: 'MS_BOEING' },
];

export const WORK_FLOW_STATUS: {
  [k: string]: { code: string; shortName: string; fullName: string };
} = {
  LV_C_MANAGER_PENDING_APPROVE: {
    code: '2',
    shortName: 'Lvl C',
    fullName: 'Level C Manager',
  },
  QA_MANAGER_PENDING_APPROVE: { code: '3', shortName: 'QA', fullName: 'QA' },
  TPC_PENDING_APPROVE: { code: '4', shortName: 'TPC', fullName: 'TPC' },
  PUBLISHED: { code: '5', shortName: 'Published', fullName: 'Published' },
  CANCELLED: { code: '9', shortName: 'Cancelled', fullName: 'Cancelled' },
  HK_OPS_PENDING_APPROVE: {
    code: '10',
    shortName: 'HKO',
    fullName: 'HK Ops',
  },
};

export const CANCEL_WORK_FLOW_STATUS: {
  [k: string]: { code: string; fullName: string; shortName: string };
} = {
  START: {
    code: 'c1',
    fullName: 'Author',
    shortName: 'Author',
  },
  LV_C_PENDING_APPROVE: {
    code: 'c2',
    fullName: 'Level C Manager',
    shortName: 'Lvl C',
  },
  QA_PENDING_APPROVE: {
    code: 'c3',
    fullName: 'QA',
    shortName: 'QA',
  },
  TPC_PENDING_APPROVE: {
    code: 'c4',
    fullName: 'TPC',
    shortName: 'TPC',
  },
  CANCELLED: {
    code: 'c5',
    fullName: 'Cancelled',
    shortName: 'Cancelled',
  },
  REJECTED: {
    code: 'c6',
    fullName: 'REJECTED',
    shortName: 'Rejected',
  },
};

export const STATUS_ARR = [
  {
    value: WORK_FLOW_STATUS.LV_C_MANAGER_PENDING_APPROVE.code,
    name: 'Lvl C Pending',
  },
  { value: 'LV_C_APPROVED', name: 'Lvl C Approved' },
  { value: 'LV_C_REJECTED', name: 'Lvl C Rejected' },
  {
    value: WORK_FLOW_STATUS.QA_MANAGER_PENDING_APPROVE.code,
    name: 'QA Pending',
  },
  { value: 'QA_APPROVED', name: 'QA Approved' },
  { value: 'QA_REJECTED', name: 'QA Rejected' },
  {
    value: WORK_FLOW_STATUS.TPC_PENDING_APPROVE.code,
    name: 'TPC Pending',
  },
  { value: 'TPC_APPROVED', name: 'TPC Approved' },
  { value: 'TPC_REJECTED', name: 'TPC Rejected' },
  {
    value: WORK_FLOW_STATUS.HK_OPS_PENDING_APPROVE.code,
    name: 'HK Ops Pending',
  },
  { value: 'HK_OPS_APPROVED', name: 'HK Ops Approved' },
  { value: 'HK_OPS_REJECTED', name: 'HK Ops Rejected' },
  {
    value: WORK_FLOW_STATUS.PUBLISHED.code,
    name: 'Published',
    isViewer: true,
    isBookmark: true,
  },
  { value: 'EXPIRED', name: 'Expired', isBookmark: true },
  { value: 'URGENT', name: 'Urgent' },
  { value: 'NEW', name: 'New Submission', isViewer: true, isBookmark: true },
  {
    value: WORK_FLOW_STATUS.CANCELLED.code,
    name: 'Cancelled',
    isViewer: false,
    isBookmark: true,
  },
];

export const USER_ROLE: {
  [k: string]: {
    name: string;
    display: string;
    gridDisplay: string;
    isShowInRoleAssignment?: boolean;
  };
} = {
  AUTHOR: { name: 'author', display: 'Author', gridDisplay: 'Author' },
  LV_C_MANAGER: {
    name: 'lvCManager',
    display: 'Approver',
    gridDisplay: 'Level C',
  },
  QA_MANAGER: {
    name: 'qaManager',
    display: 'Approver',
    gridDisplay: 'QA Manager',
  },
  TPC: { name: 'tpc', display: 'Approver', gridDisplay: 'TPC' },
  VIEWER: {
    name: 'viewer',
    display: 'Viewer',
    gridDisplay: 'Viewer',
    isShowInRoleAssignment: false,
  },
  ADMIN: { name: 'admin', display: 'Admin', gridDisplay: 'Admin' },
  HK_OPS: { name: 'hkOps', display: 'Approver', gridDisplay: 'HKG Ops' },
};

export const DOC_STATUS: {
  [k: string]: {
    name: string;
    color: string;
    fontColor: string;
    value: string;
  };
} = {
  IS_NEW: {
    name: 'IS_NEW',
    color: COLORS.green,
    fontColor: COLORS.white,
    value: 'New',
  },
  IS_URGENT: {
    name: 'IS_URGENT',
    color: COLORS.yellow,
    fontColor: COLORS.white,
    value: 'Urgent',
  },
  IS_EXPIRED: {
    name: 'IS_EXPIRED',
    color: COLORS.red2,
    fontColor: COLORS.red,
    value: 'Expired',
  },
  DEFAULT: {
    name: 'DEFAULT',
    color: COLORS.white,
    fontColor: COLORS.white,
    value: '',
  },
};

export const EXTRA_STATUS = [
  {
    text: 'MOR Related',
    value: 'MOR',
  },
  {
    text: 'AD Related',
    value: 'AD',
  },
  {
    text: 'Delay Driver',
    value: 'DELAY',
  },
  {
    text: 'AOG Driver',
    value: 'AOG',
  },
];

export const TABLE_SIZE = 30;

export const BREAK_POINTS = [1024, 834, 480, 360];

export const APPROVAL_DETAIL_VIEW_MODE = {
  READ_ONLY: 'readOnly',
  APPROVE_REJECT: 'approveReject',
  ASSIGN_DOC_NUM: 'assignDocNum',
  PUBLISH: 'publish',
  ALLOW_CANCEL: 'allowCancel',
  CANCEL_IN_PROGRESS: 'cancelInProgress',
  CANCEL_APPROVE_REJECT: 'cancelApproveReject',
  CANCELLED: 'cancelled',
};

export const CUSTOM_LABELS: {
  [k: string]: {
    name: string;
    label: string;
    backgroundColor: string;
  };
} = {
  MOR_RELATED: {
    name: 'MOR related',
    label: 'M',
    backgroundColor: COLORS.maroon,
  },
  AD_RELATED: {
    name: 'AD related',
    label: 'A',
    backgroundColor: COLORS.blue3,
  },
  DELAY_DRIVER: {
    name: 'Delay Driver',
    label: 'D',
    backgroundColor: COLORS.blue4,
  },
  TECHNICAL_TIPS: {
    name: 'Technical Tips',
    label: 'T',
    backgroundColor: COLORS.grey13,
  },
  URGENT_SUBMISSION: {
    name: 'Urgent Submission',
    label: '!',
    backgroundColor: COLORS.yellow,
  },
  AOG_DRIVER: {
    name: 'AOG Driver',
    label: 'AOG',
    backgroundColor: COLORS.yellow3,
  },
  RETURN_TO_BLOCK: {
    name: 'Return to block',
    label: 'RTB',
    backgroundColor: COLORS.green4,
  },
};

export const ERROR_MSGS = {
  REQUIRED: (field: string) => `Please input ${field}`,
  FILE_UPLOAD_REQUIRED: 'Please upload a file',
  VALID_NUMBER: 'Please enter a valid number',
  MUST_SELECT: 'Please select at least one option',
  VALID_UPPERCASE: 'Please use uppercase',
  MAX_LENGTH: (maxChar: string) => `Maximum ${maxChar} chars only allowed`,
  MIN_LENGTH: (minChar: string) => `Minimum ${minChar} chars only allowed`,
  NO_SPECIAL_CHAR:
    "Please do not include special characters * | < > [ ] { }  ' ; @ $ # % ^ ~",
  VIDEO_SIZE_LIMIT: 'Video file cannot be larger than 200 MB',
  PDF_SIZE_LIMIT: 'PDF file cannot be larger than 20 MB',
};

export const PATTERNS = {
  VALID_NUMBER: /^[0-9]+$/,
  VALID_UPPERCASE: /^[^a-z]*$/,
  SPECIAL_CHAR: /^[^*|<>[\]{}`\\';@$#%^~]*$/,
};

export const SUMMARY_DOWNLOAD_START_YEAR = 2021;

export const LOADING = 'Loading';

export const SEARCH_PARAMS = {
  VIEWER: 'viewerSearchParams',
  BOOKMARK: 'bookmarkSearchParams',
  APPROVER: 'approverSearchParams',
  AUTHOR: 'authorSearchParams',
};

export const URL_PATH = {
  VIEWER: 'viewer',
  APPROVER: 'approver',
  AUTHOR: 'author',
  EXPORT: 'summary',
};

export const FILE_CONFIG = {
  PART_SIZE: 5242880, // 5 MB
  VIDEO_SIZE_LIMIT: 209715200, // 200 MB
  PDF_SIZE_LIMIT: 20971520, // 20 MB
};

export const USER_ROLE_HIGHLIGHT_DURATION = 3000;

export const ADD_USER_ROLE_STAGES = {
  VERIFY_USER: 'VERIFY_USER',
  ALLOW_ADD: 'ALLOW_ADD',
};

export const FILE_TYPE = {
  MP4: 'mp4',
  PDF: 'pdf',
};

export const PAGINATION_LABEL = {
  PREV: 'Prev',
  NEXT: 'Next',
  ELLIPSIS: '...',
};

export const enum Positions {
  Top,
  Bottom,
  Left,
  Right,
}

export const SORT_BY_CONFIG = {
  NAME: 'name',
  GALACXY_ID: 'galacxyId',
  USER_GROUP: 'group',
};

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const DOC_NOTES_STAGES = {
  VIEW_NOTES: 'VIEW_NOTES',
  ADD_NOTES: 'ADD_NOTES',
  EDIT_NOTES: 'EDIT_NOTES',
};

export const MODAL_SIZES = {
  SMALL: 'SM',
};
