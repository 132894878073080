/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  CXButton,
  FormTextBox,
  RejectModal,
  TextBox,
  notify,
} from 'components';
import { mq } from 'helpers';
import { submitCancel } from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import {
  APPROVAL_DETAIL_VIEW_MODE,
  CANCEL_WORK_FLOW_STATUS,
  DOCUMENT_TYPE,
} from 'helpers/constants';
import { formatToFullDateTime } from 'helpers/date';
import { IDocDetail } from 'models/SearchData';
import { CancelDocumentModal } from 'screens/ApprovalDetail/components';
import ProfileContext from 'store/ProfileContext';

interface ICancelFormData {
  cancelReason: string;
  cancelAssignee: string;
  isApproved: boolean;
}

const CancelFlowSection = ({
  data,
  setSubmitTime,
  isAuthor,
  isShowAllowCancelSection,
}: {
  data: IDocDetail;
  setSubmitTime: () => void;
  isAuthor?: boolean;
  isShowAllowCancelSection?: boolean;
}): JSX.Element => {
  const {
    formState: { isValid },
    handleSubmit,
    setValue,
    control,
    watch,
  } = useForm<ICancelFormData>({
    mode: 'onChange',
  });
  const { id } = useParams();
  const { userGroup } = useContext(ProfileContext);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isAllowCancelOpen, setIsAllowCancelOpen] = useState(false);
  const [cancelRejectReason, setCancelRejectReason] = useState<string>('');

  const onCancelSubmit = handleSubmit(async () => {
    if (id) {
      let resp;

      const cancelAssignee = watch('cancelAssignee');
      const isApproved = watch('isApproved');
      const cancelReason = watch('cancelReason');

      if (isAuthor) {
        resp = await submitCancel(
          id,
          isApproved,
          isAuthor,
          cancelReason,
          cancelAssignee,
          userGroup,
        );
      } else {
        resp = await submitCancel(id, isApproved, isAuthor, cancelRejectReason);
      }

      if (!resp.result) {
        notify({
          message: `Cannot submit for cancellation. ${
            (resp.errMsg as string) || ''
          }`,
          type: 'error',
        });
      } else {
        setSubmitTime();
        let msg;
        if (isApproved) {
          msg = isAuthor
            ? 'Document has been successfully submitted for cancellation'
            : 'Document cancellation has been successfully approved';
          notify({
            message: msg,
            type: 'success',
          });
        }
      }
    }
  });

  let pendingText;
  let nextLevelOrFinalApproval;
  if (data?.viewMode === APPROVAL_DETAIL_VIEW_MODE.CANCEL_APPROVE_REJECT) {
    if (data?.nextLevelApprover) {
      nextLevelOrFinalApproval = `The document will be reviewed by ${
        CANCEL_WORK_FLOW_STATUS[data.nextLevelApprover].fullName
      } after your approval`;
    } else {
      nextLevelOrFinalApproval = `The document will be hidden from the Repository and cannot be found by users`;
    }
  }

  if (data?.pendingApprover) {
    pendingText = `Pending ${
      CANCEL_WORK_FLOW_STATUS[data.pendingApprover].fullName
    } approval`;
  }

  const isANL = data.docType === DOCUMENT_TYPE.ANL.name;

  return (
    <>
      {isShowAllowCancelSection && (
        <>
          <div
            css={{
              marginTop: '1rem',
            }}
          >
            <hr />
          </div>
          <div css={{ marginTop: '1rem' }}>
            <h4 css={{ fontSize: '1.25rem', fontWeight: '700' }}>
              Document Cancellation
            </h4>
            <form>
              <div
                css={{
                  marginTop: '1rem',
                }}
              >
                <FormTextBox
                  label="Cancel Reason*"
                  dataField="cancelReason"
                  control={control}
                  rules={{ required: true }}
                />
              </div>
              {isANL && (
                <div
                  css={{
                    marginTop: '1rem',
                    width: '22rem',
                  }}
                >
                  <FormTextBox
                    label="Assign to Level C manager*"
                    dataField="cancelAssignee"
                    control={control}
                    rules={{ required: true }}
                  />
                </div>
              )}
            </form>
          </div>
          <div css={{ marginTop: '1rem' }}>
            <CXButton
              variant="primary-outline"
              type="button"
              disabled={!isValid}
              customStyles={{
                [mq[2]]: {
                  width: '100%',
                },
              }}
              onClick={() => {
                setIsAllowCancelOpen(true);
              }}
            >
              Submit Cancellation
            </CXButton>
          </div>
          <CancelDocumentModal
            isOpen={isAllowCancelOpen}
            setIsOpen={setIsAllowCancelOpen}
            setValue={() => {
              setValue('isApproved', true);
            }}
            submitCancellation={onCancelSubmit}
          />
        </>
      )}
      {!isShowAllowCancelSection &&
        data.cancelFlowArr &&
        data.cancelFlowArr.length && (
          <div
            css={{
              background: COLORS.yellow4,
              borderRadius: '8px',
              padding: '1rem',
              marginTop: '1rem',
            }}
          >
            <div css={{ marginBottom: '0.5rem' }}>
              {data.cancelFlowArr.map((x, index) => {
                let actionText = '';

                const roleText =
                  Object.values(CANCEL_WORK_FLOW_STATUS).find(
                    (c) => c.code === x.stage,
                  )?.fullName || '';

                switch (x.stage) {
                  case CANCEL_WORK_FLOW_STATUS.START.code:
                    actionText = `${x.createdBy} has submitted this document for cancellation`;
                    break;
                  case CANCEL_WORK_FLOW_STATUS.LV_C_PENDING_APPROVE.code:
                    actionText = `${
                      x.isApproved
                        ? 'Cancellation approved by'
                        : 'Cancellation rejected by'
                    } ${x.createdBy} (${roleText})`;
                    break;
                  case CANCEL_WORK_FLOW_STATUS.TPC_PENDING_APPROVE.code:
                  case CANCEL_WORK_FLOW_STATUS.QA_PENDING_APPROVE.code:
                    actionText = `${
                      x.isApproved
                        ? 'Cancellation reviewed and unpublished by'
                        : 'Cancellation rejected by'
                    } ${x.createdBy} (${roleText})`;
                    break;
                  default:
                    break;
                }

                return (
                  <div
                    key={index}
                    css={{
                      marginTop: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '1rem',
                      }}
                    >
                      <span css={{ fontWeight: '700' }}>{actionText}</span>
                      <span>{formatToFullDateTime(x.createdAt)}</span>
                    </div>
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      {x.comment && (
                        <>
                          <span css={{ fontWeight: '700' }}>
                            Cancellation Reason
                          </span>
                          <span css={{ marginTop: '0.5rem' }}>{x.comment}</span>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {pendingText && (
              <span css={{ fontStyle: 'italic', marginTop: '1rem' }}>
                {pendingText}
              </span>
            )}
            {data.viewMode ===
              APPROVAL_DETAIL_VIEW_MODE.CANCEL_APPROVE_REJECT && (
              <>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '1rem',
                    alignItems: 'flex-start',
                  }}
                >
                  <span css={{ fontWeight: '700' }}>
                    Please review and confirm the cancellation
                  </span>
                  <CXButton
                    customStyles={{
                      marginTop: '0.5rem',
                    }}
                    type="submit"
                    onClick={() => {
                      setValue('isApproved', true);
                      void onCancelSubmit();
                    }}
                    variant="primary"
                  >
                    Approve Cancellation
                  </CXButton>
                </div>
              </>
            )}
            {nextLevelOrFinalApproval && (
              <div css={{ marginTop: '0.5rem' }}>
                <span>{nextLevelOrFinalApproval}</span>
              </div>
            )}
            {data.viewMode ===
              APPROVAL_DETAIL_VIEW_MODE.CANCEL_APPROVE_REJECT && (
              <div css={{ marginTop: '1rem' }}>
                <div css={{ marginBottom: '1rem' }}>
                  <TextBox
                    name="cancelRejectReason"
                    label="Reject reason*"
                    onChange={(e) =>
                      setCancelRejectReason(
                        (e.target as HTMLInputElement).value,
                      )
                    }
                  />
                </div>
                <CXButton
                  variant="primary-outline"
                  type="button"
                  disabled={!cancelRejectReason}
                  customStyles={{
                    [mq[2]]: {
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    setIsRejectModalOpen(true);
                  }}
                >
                  Reject Cancellation
                </CXButton>
                <RejectModal
                  isOpen={isRejectModalOpen}
                  setIsOpen={setIsRejectModalOpen}
                  setValue={() => {
                    setValue('isApproved', false);
                  }}
                  rejectReason={cancelRejectReason}
                  submitCancelApproval={onCancelSubmit}
                  isCancellation={true}
                />
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default CancelFlowSection;
