/** @jsxImportSource @emotion/react */
import { CSSProperties } from 'react';

import { USER_GROUP } from 'helpers/constants';

const UserGroupLabel = ({
  userGroup,
  customTextStyles,
}: {
  userGroup?: string;
  customTextStyles?: CSSProperties;
}) => {
  const currentUserGroup = Object.values(USER_GROUP).find(
    (u) => u.value === userGroup,
  );

  return currentUserGroup &&
    currentUserGroup.value !== USER_GROUP.NONE.value ? (
    <div
      css={{
        position: 'relative',
        display: 'flex',
      }}
    >
      <span
        css={{
          background: currentUserGroup.background,
          borderRadius: '6.25rem',
          padding: '0rem 0.5rem',
          color: currentUserGroup.color,
          fontSize: '0.813rem',
          fontWeight: 600,
          display: 'inline-block',
          marginTop: '3px',
          ...customTextStyles,
        }}
      >
        {currentUserGroup.display}
      </span>
    </div>
  ) : (
    <span css={{ marginLeft: '0.5rem' }}>--</span>
  );
};

export default UserGroupLabel;
