import * as yup from 'yup';

import {
  DOCUMENT_TYPE,
  ERROR_MSGS,
  FILE_CONFIG,
  PATTERNS,
} from 'helpers/constants';

const stringCheck = (isHideErrorMessage?: boolean) =>
  yup.string().matches(PATTERNS.SPECIAL_CHAR, {
    message: isHideErrorMessage ? ' ' : ERROR_MSGS.NO_SPECIAL_CHAR,
  });

const anlOnlyStringCheck = (field: string) =>
  yup.string().when('publication', {
    is: (val: string) =>
      val === DOCUMENT_TYPE.ANL.name || val === DOCUMENT_TYPE.TR.name,
    then: stringCheck().required(ERROR_MSGS.REQUIRED(field)),
  });

const fileCheck = yup
  .mixed()
  .test(
    'required',
    ERROR_MSGS.FILE_UPLOAD_REQUIRED,
    (value: File[]) => value?.length > 0,
  );

const videoCheck = yup
  .mixed()
  .test('type', 'Only mp4 file supported', (value: File[]) => {
    return !!value?.length && value[0].type === 'video/mp4';
  })
  .test('fileSize', ERROR_MSGS.VIDEO_SIZE_LIMIT, (value: File[]) => {
    return (
      !!value.length &&
      value[0].type === 'video/mp4' &&
      value[0].size <= FILE_CONFIG.VIDEO_SIZE_LIMIT
    );
  });

const pdfCheck = yup
  .mixed()
  .test('type', 'Only pdf file supported', (value: File[]) => {
    return !!value?.length && value[0].type === 'application/pdf';
  })
  .test('fileSize', ERROR_MSGS.PDF_SIZE_LIMIT, (value: File[]) => {
    return !!value?.length && value[0].size <= FILE_CONFIG.PDF_SIZE_LIMIT;
  });

const trOnlyNumberCheck = yup.string().when('publication', {
  is: (val: string) => val === DOCUMENT_TYPE.TR.name,
  then: stringCheck()
    .matches(PATTERNS.VALID_NUMBER, ERROR_MSGS.VALID_NUMBER)
    .required(),
});

export const uploadDocumentSchema = yup.object().shape({
  files: yup
    .mixed()
    .when('documentId', {
      is: (val: string) => {
        return !val;
      },
      then: fileCheck,
    })
    .when('publication', {
      is: (val: string) => val === DOCUMENT_TYPE.VIDEO.name,
      then: yup.mixed().when('documentId', {
        is: (val: string) => {
          return !val;
        },
        then: videoCheck,
      }),
      otherwise: yup.mixed().when('documentId', {
        is: (val: string) => {
          return !val;
        },
        then: pdfCheck,
      }),
    }),
  riskAttachmentFiles: yup.mixed().when(['riskAssessment', 'documentId'], {
    is: (riskAssessment: string, documentId?: string) =>
      riskAssessment && riskAssessment !== 'N' && !documentId,
    then: fileCheck.concat(pdfCheck),
  }),
  publication: yup.string().required(ERROR_MSGS.REQUIRED('publication type')),
  documentNumber: yup.string().when('publication', {
    is: (val: string) =>
      !(val === DOCUMENT_TYPE.ANL.name || val === DOCUMENT_TYPE.QN.name),
    then: stringCheck().required(ERROR_MSGS.REQUIRED('document number')),
  }),
  applicability: anlOnlyStringCheck('applicability'),
  documentTitle: stringCheck().required(ERROR_MSGS.REQUIRED('title')),
  ataChapterArr: yup
    .array()
    .required(ERROR_MSGS.MUST_SELECT)
    .min(1, ERROR_MSGS.MUST_SELECT),
  ataSection: stringCheck(true).max(2, ''),
  ataPage: stringCheck(true).max(2, ''),
  ataFigure: stringCheck(true),
  operatorArr: yup
    .array()
    .required(ERROR_MSGS.MUST_SELECT)
    .min(1, ERROR_MSGS.MUST_SELECT),
  fleetArr: yup
    .array()
    .required(ERROR_MSGS.MUST_SELECT)
    .min(1, ERROR_MSGS.MUST_SELECT),
  description: stringCheck().max(100, ERROR_MSGS.MAX_LENGTH('100')),
  effectiveAt: yup.date().required(ERROR_MSGS.REQUIRED('effective at')),
  reasonsForIssue: stringCheck().required(
    ERROR_MSGS.REQUIRED('Reasons for Issue'),
  ),
  affectedProcess: anlOnlyStringCheck('affected process'),
  tempAmendYears: trOnlyNumberCheck,
  tempAmendMonths: trOnlyNumberCheck,
  riskAssessment: yup.string().when('publication', {
    is: DOCUMENT_TYPE.ANL.name,
    then: yup.string().required(ERROR_MSGS.REQUIRED('risk assessment')),
  }),
  riskAssessmentReason: yup.string().when('riskAssessment', {
    is: 'N',
    then: stringCheck().required(ERROR_MSGS.REQUIRED('risk assessment reason')),
  }),
  assignee: yup.string().when('publication', {
    is: (val: string) =>
      val === DOCUMENT_TYPE.ANL.name || val === DOCUMENT_TYPE.TR.name,
    then: yup
      .string()
      .required(ERROR_MSGS.REQUIRED('approver'))
      .matches(PATTERNS.VALID_UPPERCASE, ERROR_MSGS.VALID_UPPERCASE),
  }),
});

export const documentNotesSchema = yup.object().shape({
  note: stringCheck(),
});
