/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { default as LibraryDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Svg, TextBox } from 'components';
import { COLORS } from 'helpers/colors';

interface DatePickerProps {
  name: string;
  label: string;
  value?: Date | null;
  disabled?: boolean;
  hasError?: boolean;
  onChange(
    date: Date | null,
    event: React.SyntheticEvent<unknown, Event> | undefined,
  ): void;
}

const DatePicker = ({
  name,
  label,
  value,
  disabled,
  hasError,
  onChange,
}: DatePickerProps): JSX.Element => {
  return (
    <div
      css={css`
        fontsize: 0.875rem;
        flex: 1;

        .react-datepicker {
          border: 0.063rem solid ${COLORS.grey14};
          font-family: 'CathaySans Regular';
        }

        .react-datepicker__header {
          border: none;
          display: flex;
          flex-direction: column-reverse;
        }

        .react-datepicker__day {
          font-weight: 500;
          font-size: 0.875rem;
        }

        .react-datepicker__day-name {
          font-size: 0.75rem;
        }

        .react-datepicker__day-names::after {
          content: '';
          height: 0.063rem;
          display: block;
          margin: 0 0.625rem;
          margin-bottom: 1rem;
          marigin: 0 1.5rem;
          background: ${COLORS.grey10};
        }

        .react-datepicker__navigation--previous,
        .react-datepicker__navigation--next {
          top: 3.1rem;
        }
        .react-datepicker__navigation-icon::before,
        .react-datepicker__navigation-icon::before {
          border-color: ${COLORS.grey};
          border-width: 0.125rem 0.125rem 0 0;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--selected:hover,
        .react-datepicker__day--keyboard-selected {
          background: ${COLORS.grey3};
          border-radius: 4px;
        }

        .react-datepicker__day--today {
          font-weight: 500;
          border: 1px solid ${COLORS.grey4};
          box-sizing: border-box;
          border-radius: 4px;
        }

        .react-datepicker__header {
          background-color: ${COLORS.white};
        }
      `}
    >
      <LibraryDatePicker
        selected={value}
        onChange={onChange}
        disabled={disabled}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        calendarStartDay={1}
        customInput={
          <TextBox
            placeholderText="DD/MM/YY"
            name={name}
            label={label}
            hasError={hasError}
            disabled={disabled}
            icon={<Svg icon="calendar" />}
          />
        }
        dateFormat="dd/MM/yy"
      />
    </div>
  );
};

export default DatePicker;
