/** @jsxImportSource @emotion/react */
import { Controller } from 'react-hook-form';

import { DatePicker, ErrorMessageWrapper } from 'components';
import { convertToDate, formatForDatePicker } from 'helpers/date';

import { CommonFormControlProps } from '../../@types/formControl';

const FormDatePicker = <T,>({
  label,
  dataField,
  control,
  rules,
  defaultValue,
}: CommonFormControlProps<T>): JSX.Element => {
  return (
    <Controller
      name={dataField}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field: { onChange, value, name },
        fieldState: { error },
        formState: { errors },
      }) => (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
          }}
        >
          <DatePicker
            name={name}
            label={label || ''}
            value={convertToDate(value as string)}
            hasError={!!error}
            onChange={(date) => {
              onChange(formatForDatePicker(date));
            }}
          />
          <ErrorMessageWrapper errors={errors} dataField={dataField} />
        </div>
      )}
    />
  );
};

export default FormDatePicker;
