import { CommonContainer, SearchDocument } from 'components';

const Approver = ({ userGroup }: { userGroup?: string }): JSX.Element => {
  return (
    <CommonContainer>
      <SearchDocument
        isViewer={false}
        isApprover={true}
        userGroup={userGroup}
      />
    </CommonContainer>
  );
};

export default Approver;
