/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import { CXButton, CommonModal } from 'components';
import { mq } from 'helpers';
import { COLORS } from 'helpers/colors';

const CancelDocumentModal = ({
  isOpen,
  setIsOpen,
  setValue,
  submitCancellation,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setValue: () => void;
  submitCancellation: () => Promise<void>;
}): JSX.Element => {
  return (
    <CommonModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Are you sure you want to approve the cancellation for this document?"
    >
      <div
        css={{ marginTop: '1.5rem', display: 'flex', flexDirection: 'column' }}
      >
        <span
          css={{ fontWeight: '700', fontSize: '1.5rem', color: COLORS.red }}
        >
          Warning: Document cannot be reactivated once cancelled. Cancellation
          approval is required for ANL and QN; Cancellation request will be
          reviewed and TPC/QA Admin will be notified for the request upon
          approval.
        </span>
        <div
          css={{ display: 'flex', flexDirection: 'row', marginTop: '2.5rem' }}
        >
          <div css={{ flex: 1 }} />
          <CXButton
            variant="primary"
            type="button"
            customStyles={{
              [mq[2]]: {
                width: '100%',
              },
            }}
            onClick={async () => {
              setIsOpen(false);
              setValue();
              await submitCancellation();
            }}
          >
            Cancel Document
          </CXButton>
        </div>
      </div>
    </CommonModal>
  );
};

export default CancelDocumentModal;
