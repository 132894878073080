import { useContext, useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import { SpinningLoader } from 'components';
import { downloadFile } from 'helpers';
import { getDocument } from 'helpers/apiCaller';
import { LOADING } from 'helpers/constants';
import ProfileContext from 'store/ProfileContext';

const Download = ({
  isAuthor,
  isApprover,
  isRiskAccessAttachment,
}: {
  isAuthor?: boolean;
  isApprover?: boolean;
  isRiskAccessAttachment?: boolean;
}): JSX.Element => {
  const params = useParams();
  const { userGroup, galacxyId } = useContext(ProfileContext);

  useEffect(() => {
    const getData = async () => {
      const fileResp = await getDocument({
        fileName: params.fileName,
        isAuthor,
        isApprover,
        isRiskAccessAttachment,
        userGroup,
      });

      if (!fileResp) {
        return;
      }

      const url = window.URL.createObjectURL(fileResp);

      if (isAndroid) {
        downloadFile({ file: fileResp });
      } else if (isIOS) {
        const a = document.createElement('a');
        a.href = url;
        //   a.download = params.fileName || '';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      } else {
        window.document.write(
          `<body style="margin:0;">
                <iframe 
                src="${url}" 
                frameborder="0" 
                style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
                allowfullscreen
                >
                </iframe>
              </body>`,
        );
      }
    };

    if (galacxyId) {
      void getData();
    }
  }, [
    isApprover,
    isAuthor,
    isRiskAccessAttachment,
    params.fileName,
    userGroup,
  ]);

  return (
    <>
      {isAndroid ? (
        'Downloaded'
      ) : (
        <SpinningLoader isNewLoadingIcon={true} loaderText={LOADING} />
      )}
    </>
  );
};

export default Download;
