declare global {
  interface Window {
    AZURE_CLIENT_ID: string;
    AZURE_TENANT_ID: string;
    IS_DISABLE_AOG: string;
    CX_DEPLOY_ENV: string;
    NODE_ENV: string;
    MSStream: unknown;
    FIREBASE_APP_ID: string;
    FIREBASE_MEASUREMENT_ID: string;
  }
}

const serverConfig = {
  azure: {
    // clientId: window.AZURE_CLIENT_ID || 'bbe6188c-953b-493f-8020-27c1c0549338',
    // tenantId: window.AZURE_TENANT_ID || 'f62aca8c-2ba2-417b-a0c1-ab3f1020ccc4',
    clientId: window.AZURE_CLIENT_ID || '184c506b-c2fe-4ae8-829d-ce5b5ccd17f1',
    tenantId: window.AZURE_TENANT_ID || '4266ec6c-fe9f-4893-82e9-996189e0b81b',
  },
  environment: window.CX_DEPLOY_ENV || 'local',
  isDev: window.CX_DEPLOY_ENV !== 'p0',
  isDisableAog: window.IS_DISABLE_AOG === 'true' || false,
  firebase: {
    apiKey: 'AIzaSyAOYgcCiDAWVFnNsfzOfUIeXh4EmqyrrFY',
    authDomain: 'operation-knowledge-repository.firebaseapp.com',
    projectId: 'operation-knowledge-repository',
    storageBucket: 'operation-knowledge-repository.appspot.com',
    messagingSenderId: '76961466891',
    appId: window.FIREBASE_APP_ID || '1:76961466891:web:0039e506fdb471806fd50e',
    measurementId: window.FIREBASE_MEASUREMENT_ID || 'G-P5L4CG30V3',
  },
};

export default serverConfig;
