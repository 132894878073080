/** @jsxImportSource @emotion/react */
import {
  ChangeEvent,
  FC,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Svg, SvgComponents } from 'components';
import { assignRefs } from 'helpers';
import { COLORS } from 'helpers/colors';

export interface InputProps {
  label: string;
  icon?: keyof typeof SvgComponents;
  activeIcon?: keyof typeof SvgComponents;
  defaultValue?: boolean;
  onChange?(event: React.FormEvent<unknown>): void;
}

const ButtonCheckBox: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ label, icon, activeIcon, defaultValue, onChange, ...props }, ref) => {
    const [checked, setChecked] = useState(defaultValue || false);
    const localRef = useRef<HTMLInputElement | null>(null);
    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
      setChecked(e.target.checked);
      if (onChange) onChange(e);
    };

    useEffect(() => {
      if (defaultValue !== undefined && defaultValue !== checked)
        setChecked(defaultValue);
    }, [defaultValue]);

    return (
      <div
        css={{
          background: COLORS.white,
          border: `0.063rem solid ${COLORS.grey7}`,
          width: '100%',
          height: '3.125rem',
          borderRadius: '0.25rem',
          textAlign: 'center',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          ...(checked && {
            border: `0.063rem solid ${COLORS.grey}`,
          }),
        }}
      >
        <label
          css={{
            display: 'flex',
            position: 'relative',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <input
            css={{
              position: 'absolute',
              display: 'none',
              color: '#fff !important',
            }}
            type="checkbox"
            checked={checked}
            ref={assignRefs(localRef, ref)}
            {...props}
            onChange={handleChange}
          />
          {icon && activeIcon && (
            <span
              css={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '0.5rem',
              }}
            >
              <Svg icon={checked ? activeIcon : icon} />
            </span>
          )}
          <span
            css={{
              fontWeight: 400,
              fontSize: '0.875rem',
              lineHeight: '1.188rem',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
              height: '100%',
              alignItems: 'center',
              color: COLORS.grey2,
              '::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
              ...(checked && {
                color: COLORS.grey,
              }),
            }}
          >
            {label}
          </span>
        </label>
      </div>
    );
  },
);

export default ButtonCheckBox;
