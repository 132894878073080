/** @jsxImportSource @emotion/react */
import { Svg, UserGroupLabel } from 'components';
import { COLORS } from 'helpers/colors';
import { IUserRoleSearchResultData } from 'models/SearchData';

const CommonDisplayItem = ({
  label,
  value,
  children,
}: {
  label: string;
  value?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
      }}
    >
      <span
        css={{
          color: COLORS.grey4,
          fontSize: '0.75rem',
        }}
      >
        {label}
      </span>
      {value && (
        <span
          css={{
            fontSize: '0.875rem',
            color: COLORS.grey,
            fontWeight: 700,
          }}
        >
          {value}
        </span>
      )}
      {children && children}
    </div>
  );
};

const MobileResultItem = ({
  item,
  handleUpdateClick,
  getUserRoleGridDisplay,
}: {
  item: IUserRoleSearchResultData;
  handleUpdateClick: (item: IUserRoleSearchResultData) => void;
  getUserRoleGridDisplay: (role: string) => string;
}): JSX.Element => {
  return (
    <>
      <div
        css={{
          background: COLORS.white,
          border: `0.063rem solid ${COLORS.grey7}`,
          borderRadius: '0.25rem',
          padding: '0.875rem 0.5rem',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '0.5rem',
        }}
      >
        <div
          css={{
            display: 'flex',
            marginBottom: '0.825rem',
          }}
        >
          <CommonDisplayItem label="Name" value={item.name} />
          <div css={{ flex: 1 }} />
          <CommonDisplayItem label="GalaCXy ID" value={item.galacxyId} />
          <div
            role="presentation"
            onClick={() => handleUpdateClick(item)}
            css={{
              cursor: 'pointer',
            }}
          >
            <Svg icon="edit" />
          </div>
        </div>
        <div
          css={{
            marginBottom: '0.825rem',
          }}
        >
          <CommonDisplayItem label="User Group">
            <UserGroupLabel userGroup={item.group} />
          </CommonDisplayItem>
        </div>
        <CommonDisplayItem label="Roles">
          <div css={{ display: 'flex' }}>
            {item.roles ? (
              item.roles.map((role) => (
                <span
                  css={{
                    padding: '0.25rem',
                    borderRadius: '0.25rem',
                    background: COLORS.blue2,
                    fontSize: '0.75rem',
                    color: COLORS.blue6,
                    fontWeight: '600',
                    marginRight: '0.625rem',
                  }}
                  key={role}
                >
                  {getUserRoleGridDisplay(role)}
                </span>
              ))
            ) : (
              <span>--</span>
            )}
          </div>
        </CommonDisplayItem>
      </div>
    </>
  );
};

export default MobileResultItem;
