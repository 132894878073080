/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Svg, SvgComponents } from 'components';
import { getUserGroupName, isNonCXUser, mq, useIsTablet } from 'helpers';
import { COLORS } from 'helpers/colors';
import { USER_ROLE } from 'helpers/constants';
import ProfileContext from 'store/ProfileContext';

const NavTitle = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <div
      css={{
        color: COLORS.grey3,
        textTransform: 'initial',
        fontSize: '0.875rem',
        fontFamily: 'CathaySans Bold',
        marginTop: '2rem',
        marginLeft: '1rem',
      }}
    >
      {children}
    </div>
  );
};

const SidebarItem = ({
  link,
  name,
  icon,
}: {
  link: string;
  name: string;
  icon: keyof typeof SvgComponents;
}) => {
  const navigate = useNavigate();
  const { setIsShowSidebar } = useContext(ProfileContext);
  const isTablet = useIsTablet();

  return (
    <div
      role="presentation"
      css={{
        marginLeft: '1rem',
        marginTop: '1.25rem',
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(link);

        if (isTablet) {
          setIsShowSidebar(false);
        }
      }}
    >
      <Svg icon={icon} />
      <span
        css={{
          fontSize: '0.875rem',
          fontFamily: 'CathaySans Bold',
          marginLeft: '0.8rem',
        }}
      >
        {name}
      </span>
    </div>
  );
};

const Sidebar = (): JSX.Element => {
  const { userRoles, userGroup } = useContext(ProfileContext);

  let isAuthorAccessible = false;
  let isApproveAccessible = false;
  let isUserRoleAccessible = false;

  if (userRoles.includes(USER_ROLE.ADMIN.name)) {
    isAuthorAccessible = true;
    isApproveAccessible = true;
    isUserRoleAccessible = true;
  }

  if (userRoles.includes(USER_ROLE.AUTHOR.name)) {
    isAuthorAccessible = true;
  }

  if (
    userRoles.includes(USER_ROLE.LV_C_MANAGER.name) ||
    userRoles.includes(USER_ROLE.TPC.name) ||
    userRoles.includes(USER_ROLE.QA_MANAGER.name) ||
    userRoles.includes(USER_ROLE.HK_OPS.name)
  ) {
    isApproveAccessible = true;
  }

  let authorLink = '/author';
  let viewerLink = '/';
  let exportLink = '/export';
  let bookmarkLink = '/bookmark';
  if (userGroup && isNonCXUser(userGroup)) {
    const userGroupName = getUserGroupName(userGroup);
    if (userGroupName) {
      authorLink = `${authorLink}-${userGroupName.toLowerCase()}`;
      viewerLink = `${viewerLink}viewer-${userGroupName.toLowerCase()}`;
      exportLink = `${exportLink}-${userGroupName.toLowerCase()}`;
      bookmarkLink = `${bookmarkLink}-${userGroupName.toLowerCase()}`;
    }
  }

  return (
    <div
      css={{
        width: '14rem',
        backgroundColor: COLORS.white3,
        zIndex: 1,
        [mq[1]]: { width: '100%', height: '100vh' },
      }}
    >
      <div css={{ [mq[1]]: { width: '100% !important' } }}>
        <div css={{ [mq[1]]: { width: '100% !important' } }}>
          {isAuthorAccessible || isApproveAccessible ? (
            <NavTitle>Documents</NavTitle>
          ) : null}
          {isAuthorAccessible ? (
            <SidebarItem link={authorLink} name="Author" icon="setting" />
          ) : null}
          {isApproveAccessible ? (
            <SidebarItem link="/approver" name="Approver" icon="setting" />
          ) : null}
          {isAuthorAccessible || isApproveAccessible ? (
            <SidebarItem link={exportLink} name="Export" icon="export" />
          ) : null}
          {isUserRoleAccessible ? (
            <>
              <NavTitle>Admin</NavTitle>
              <SidebarItem
                link="/user-role"
                name="All Users"
                icon="user-role"
              />
            </>
          ) : null}
          <>
            <NavTitle>Repository</NavTitle>
            <SidebarItem link={viewerLink} name="Published docs" icon="doc" />
            <SidebarItem
              link={bookmarkLink}
              name="My Bookmark"
              icon="bookmark"
            />
          </>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
