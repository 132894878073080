/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { CSSProperties } from 'react';

import Svg from 'components/Svg';
import { COLORS } from 'helpers/colors';

const CustomLabel = ({
  labelType,
  customStyles,
  customContainerStyles,
  isNameVisible,
  isUrgent,
}: {
  labelType: {
    name: string;
    label: string;
    backgroundColor: string;
  };
  customStyles?: CSSProperties;
  customContainerStyles?: CSSProperties;
  isNameVisible?: boolean;
  isUrgent?: boolean;
}): JSX.Element => {
  return (
    <div
      css={{
        display: 'flex',
        padding: `${isNameVisible || isUrgent ? '0.3rem' : '0'}`,
        justifyContent: `${isUrgent ? 'end' : 'unset'}`,
        ...customContainerStyles,
      }}
    >
      {isUrgent ? (
        <Svg icon="urgent" />
      ) : (
        <div
          css={{
            width: '0.8125rem',
            height: '1rem',
            borderRadius: `${isUrgent ? '2rem' : '0.125rem'}`,
            fontSize: '0.625rem',
            color: COLORS.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: labelType.backgroundColor,
            ...customStyles,
          }}
        >
          {labelType.label}
        </div>
      )}
      {isNameVisible && (
        <span
          css={{
            fontSize: '0.7rem',
            paddingLeft: '0.5rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {labelType.name}
        </span>
      )}
    </div>
  );
};

export default CustomLabel;
