/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import {
  CXButton,
  CheckBox,
  CommonModal,
  FormDatePicker,
  FormFileUpload,
  FormMultiChipSelect,
  FormMultiSelect,
  FormSingleDropDown,
  FormTextBox,
  SpinningLoader,
  notify,
} from 'components';
import { getAllDocTypeArr } from 'helpers';
import {
  postEditDocumentData,
  postUploadDocumentData,
} from 'helpers/apiCaller';
import {
  ATA_CHAPTER_ARR,
  AUDIENCE_GROUP_ARR,
  DOCUMENT_TYPE,
  FILE_CONFIG,
  FLEET_ARR,
  OPERATOR_ARR,
  RISK_ASSESSMENT_ARR,
} from 'helpers/constants';
import { uploadDocumentSchema } from 'helpers/validation';
import {
  IUploadFinalResponse,
  IUploadFirstResponse,
  IUploadResponse,
} from 'models/ResponseData';
import ProfileContext from 'store/ProfileContext';

export interface IFormData {
  publication?: string;
  isUrgent?: boolean;
  documentNumber?: string;
  documentTitle?: string;
  description?: string;
  reasonsForIssue?: string;
  operatorArr?: string[];
  fleetArr?: string[];
  ataChapterArr?: string[];
  ataSection?: string;
  ataPage?: string;
  ataFigure?: string;
  isMorRelated?: boolean;
  isAdRelated?: boolean;
  isDelayDriver?: boolean;
  isAogDriver?: boolean;
  isCxCabinManual?: boolean;
  isPcrCic?: boolean;
  isPermanent?: boolean;
  affectedProcess?: string;
  riskAssessment?: string;
  assignee?: string;
  files?: FileList;
  riskAttachmentFiles?: FileList;
  supersedeArr?: string[];
  riskAssessmentReason?: string;
  audienceGroupArr?: string[];
  effectiveAt?: string;
  applicability?: string;
  tempAmendYears?: string;
  tempAmendMonths?: string;
}

interface IEditFormData extends IFormData {
  documentId: string;
  fileName?: string;
  riskAttachmentFileName?: string;
}

interface UploadDocumentModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  editFormData?: IEditFormData;
  isEdit?: boolean;
}

const FormRow = ({ children }: { children: React.ReactNode }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: '1.5rem',
    }}
  >
    {children}
  </div>
);

const FormText = ({ children }: { children: React.ReactNode }) => (
  <span
    css={{
      fontWeight: '500',
      fontSize: '1.125rem',
    }}
  >
    {children}
  </span>
);

const UploadDocumentModal = ({
  isOpen,
  setIsOpen,
  editFormData,
  isEdit,
}: UploadDocumentModalProps): JSX.Element => {
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<IFormData>({
    defaultValues: editFormData ? { ...editFormData } : undefined,
    resolver: yupResolver(uploadDocumentSchema),
  });

  useEffect(() => {
    if (editFormData) {
      reset(editFormData);
    } else {
      reset();
    }
  }, [isOpen]);

  const { userGroup } = useContext(ProfileContext);

  const onSubmit: SubmitHandler<IFormData> = async (data) => {
    if (isEdit && editFormData?.documentId) {
      const response = await postEditDocumentData(
        data,
        editFormData?.documentId,
        userGroup,
      );
      if (response.result) {
        setIsOpen(false);
        const msg = 'Document has been successfully edited and resubmitted';
        notify({
          message: msg,
          type: 'success',
        });
      } else {
        notify({
          message: `Cannot update document. ${
            (response.errMsg as string) || ''
          }`,
          type: 'error',
        });
      }
      return;
    }
    const file =
      data.files && data.files.length > 0 ? data.files[0] : undefined;
    let response;

    if (!file) {
      return;
    }

    if (data.publication === DOCUMENT_TYPE.VIDEO.name) {
      // do multipart upload
      const numParts = Math.ceil(file.size / FILE_CONFIG.PART_SIZE);
      const uploadedParts: { partNumber: number; etag: string }[] = [];

      let uploadId = '';
      let newFileName = '';
      let beginningOfChunk = 0;
      let isLast = false;

      for (let i = 0; i <= numParts + 1; i += 1) {
        const formData = new FormData();

        formData.append('fileName', file.name);
        formData.append('fileSize', file.size.toString());
        formData.append('fileType', file.type);

        Object.keys(data).forEach((x) => {
          const dataContent = data[x as keyof IFormData];

          if (dataContent instanceof FileList) {
            // skip
          } else if (typeof dataContent === 'boolean') {
            formData.append(x, String(dataContent));
          } else if (Array.isArray(dataContent)) {
            dataContent.forEach((y) => formData.append(`${x}[]`, y));
          } else if (dataContent !== undefined && dataContent !== '') {
            formData.append(x, dataContent);
          }
        });

        if (newFileName && uploadId) {
          formData.append('newFileName', newFileName);
          formData.append('uploadId', uploadId);
        }

        if (isLast) {
          formData.append('isToComplete', 'true');

          uploadedParts.forEach((x) => {
            formData.append('uploadedParts[]', JSON.stringify(x));
          });

          if (data.riskAttachmentFiles) {
            formData.append(
              'riskAttachmentFile',
              data.riskAttachmentFiles[0],
              data.riskAttachmentFiles[0].name,
            );
          }
        } else if (uploadId) {
          const chunk = file.slice(
            beginningOfChunk,
            beginningOfChunk + FILE_CONFIG.PART_SIZE,
          );

          formData.append('partNumber', i.toString());
          formData.append('file', chunk);
        }

        response = await postUploadDocumentData(formData, userGroup);

        if ((response as IUploadFinalResponse).result === false) {
          break;
        }

        if (isLast) {
          break;
        }

        if (uploadId) {
          const { etag } = response as IUploadResponse;

          uploadedParts.push({ partNumber: i, etag });
          beginningOfChunk = beginningOfChunk + FILE_CONFIG.PART_SIZE;
          isLast = i === numParts;

          continue;
        }
        const result = response as IUploadFirstResponse;

        uploadId = result.uploadId;
        newFileName = result.newFileName;
      }
    } else {
      const formData = new FormData();

      Object.keys(data).forEach((x) => {
        const dataContent = data[x as keyof IFormData];

        if (dataContent instanceof FileList) {
          if (
            dataContent.length &&
            (x === 'files' || x === 'riskAttachmentFiles')
          ) {
            formData.append(
              x === 'files' ? 'file' : 'riskAttachmentFile',
              dataContent[0],
              dataContent[0].name,
            );
          }
        } else if (typeof dataContent === 'boolean') {
          formData.append(x, String(dataContent));
        } else if (Array.isArray(dataContent)) {
          dataContent.forEach((y) => formData.append(`${x}[]`, y));
        } else if (dataContent !== undefined && dataContent !== '') {
          formData.append(x, dataContent);
        }
      });

      response = await postUploadDocumentData(formData, userGroup);
    }

    const { errMsg, result } = response as IUploadFinalResponse;

    if (!result) {
      console.log('submit error');

      notify({
        message: `Cannot submit the document. ${errMsg || ''}`,
        type: 'error',
      });
    } else {
      setIsOpen(false);

      notify({ message: 'Document submitted successfully', type: 'success' });
    }
  };

  const onError: SubmitErrorHandler<IFormData> = (errors) => {
    console.log('showing error', errors);
    notify({ message: 'Cannot submit the document', type: 'error' });
  };

  const isANL = watch('publication') === DOCUMENT_TYPE.ANL.name;
  const isQN = watch('publication') === DOCUMENT_TYPE.QN.name;
  const isTR = watch('publication') === DOCUMENT_TYPE.TR.name;
  const isNoRiskAssessment = watch('riskAssessment') === 'N';

  let isAllowDocNum = false;

  if (watch('publication') !== '' && !(isANL || isQN)) {
    isAllowDocNum = true;
  }

  return (
    <CommonModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={isEdit ? 'Edit Document' : 'Upload New Document'}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormRow>
          <div css={{ flex: 1 }}>
            <FormText>Document</FormText>
          </div>
          <div css={{ flex: 4 }}>
            <FormFileUpload
              dataField="files"
              watch={watch}
              register={register}
              clearFile={() => {
                setValue('files', undefined);
              }}
              errors={errors}
              editFileName={editFormData?.fileName}
              text="Upload File"
              accept=".pdf,.mp4"
            />
          </div>
        </FormRow>
        <FormRow>
          <div css={{ flex: 1 }}>
            <FormText>Additional info</FormText>
          </div>
          <div css={{ flex: 4 }}>
            <div css={{ display: 'flex', flexDirection: 'row' }}>
              <FormSingleDropDown
                label="Publication Type*"
                dataArr={getAllDocTypeArr(userGroup)}
                control={control}
                disabled={isEdit}
                dataField="publication"
              />
              {isAllowDocNum && (
                <>
                  <div css={{ width: '1.5rem' }} />
                  <FormTextBox
                    control={control}
                    label="Document Number*"
                    dataField="documentNumber"
                  />
                </>
              )}
            </div>
            <div css={{ marginTop: '1rem' }}>
              <FormTextBox
                control={control}
                label="Title*"
                dataField="documentTitle"
              />
            </div>
            <div
              css={{
                marginTop: '1.5rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormMultiSelect
                label="ATA Chapter*"
                dataArr={ATA_CHAPTER_ARR.map((x) => ({
                  value: x.value,
                  name: x.value === 'ALL' ? x.name : `${x.value}-${x.name}`,
                }))}
                control={control}
                selectAll={false}
                dataField="ataChapterArr"
              />
              <div
                css={{
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  display: 'flex',
                  alignSelf: 'center',
                }}
              >
                <span>-</span>
              </div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                }}
              >
                <FormTextBox
                  control={control}
                  label=""
                  dataField="ataSection"
                />
                <div
                  css={{
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    alignSelf: 'center',
                  }}
                >
                  <span>-</span>
                </div>
                <FormTextBox label="" control={control} dataField="ataPage" />
                <div
                  css={{
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    alignSelf: 'center',
                  }}
                >
                  <span>-</span>
                </div>
                <div css={{ flex: 3 }}>
                  <FormTextBox
                    control={control}
                    label=""
                    dataField="ataFigure"
                  />
                </div>
              </div>
            </div>
            <div css={{ flex: 1 }} />
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormMultiSelect
                label="Effectivity*"
                dataArr={OPERATOR_ARR.map((x) => ({ name: x, value: x }))}
                control={control}
                dataField="operatorArr"
              />

              <div css={{ width: '1.5rem' }} />
              <FormMultiSelect
                label="Fleet Type*"
                dataArr={FLEET_ARR.map((x) => ({ name: x, value: x }))}
                control={control}
                dataField="fleetArr"
              />
            </div>
            {(isANL || isTR) && (
              <div
                css={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormTextBox
                  label="Applicability*"
                  dataField="applicability"
                  control={control}
                />
              </div>
            )}
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormTextBox
                label="Description"
                control={control}
                dataField="description"
              />
            </div>
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormDatePicker
                label="Effective Date*"
                dataField="effectiveAt"
                control={control}
                defaultValue={undefined}
              />
              <div css={{ width: '1.5rem' }} />
              <div css={{ flex: 1 }} />
            </div>
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormTextBox
                label="Reasons for Issue*"
                control={control}
                dataField="reasonsForIssue"
              />
            </div>
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormMultiChipSelect
                label="Supersedes Document (Enter Doc No)"
                dataField="supersedeArr"
                control={control}
              />
            </div>
            {(isANL || isTR) && (
              <div
                css={{
                  marginTop: '1.5rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormTextBox
                  label="Affected Process and Sections*"
                  control={control}
                  dataField="affectedProcess"
                />
              </div>
            )}
            {isTR && (
              <>
                <div
                  css={{
                    marginTop: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <CheckBox
                    label="CX Cabin Manual"
                    defaultChecked={false}
                    {...register('isCxCabinManual')}
                  />
                  <CheckBox
                    label="PCR/CIC/COC"
                    defaultChecked={false}
                    {...register('isPcrCic')}
                  />
                  <CheckBox
                    label="Permanent"
                    defaultChecked={false}
                    {...register('isPermanent')}
                  />
                  <div css={{ flex: '1' }} />
                </div>
                <div css={{ display: 'flex', marginTop: '1rem' }}>
                  <div css={{ flex: 2, display: 'flex', flexFlow: 'wrap' }}>
                    <span css={{ fontSize: '0.875rem' }}>
                      Temporary amendment to be removed after*
                    </span>
                  </div>
                  <div css={{ width: '1.5rem' }} />
                  <div css={{ position: 'relative', flex: 1 }}>
                    <FormTextBox
                      label="Years"
                      dataField="tempAmendYears"
                      control={control}
                      defaultValue="0"
                    />
                  </div>
                  <div css={{ width: '1.5rem' }} />
                  <div css={{ position: 'relative', flex: 1 }}>
                    <FormTextBox
                      label="Months"
                      dataField="tempAmendMonths"
                      control={control}
                      defaultValue="0"
                    />
                  </div>
                </div>
              </>
            )}
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormSingleDropDown
                label={`Risk Assessment${isANL ? '*' : ''}`}
                dataField="riskAssessment"
                disabled={isEdit}
                control={control}
                dataArr={RISK_ASSESSMENT_ARR}
              />
            </div>
            {control._formValues.riskAssessment && !isNoRiskAssessment && (
              <div
                css={{
                  margin: '0.5rem 0',
                }}
              >
                <FormFileUpload
                  dataField="riskAttachmentFiles"
                  errors={errors}
                  watch={watch}
                  register={register}
                  clearFile={() => {
                    setValue('riskAttachmentFiles', undefined);
                  }}
                  text="Add Attachment"
                  icon="attachment"
                  editFileName={editFormData?.riskAttachmentFileName}
                  customBtnStyles={{
                    display: 'flex',
                    width: '10rem',
                    padding: '0.5rem',
                    fontSize: '0.875rem',
                    fontWeight: '400',
                  }}
                  accept=".pdf"
                />
              </div>
            )}
            {isNoRiskAssessment && (
              <div
                css={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormTextBox
                  label="Reason* (if no)"
                  control={control}
                  disabled={isEdit}
                  dataField="riskAssessmentReason"
                />
              </div>
            )}
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <CheckBox
                label="MOR Related"
                defaultChecked={(isEdit && editFormData?.isMorRelated) || false}
                {...register('isMorRelated')}
              />
              <CheckBox
                label="AD Related"
                defaultChecked={(isEdit && editFormData?.isAdRelated) || false}
                {...register('isAdRelated')}
              />
              <CheckBox
                label="Delay Driver"
                defaultChecked={
                  (isEdit && editFormData?.isDelayDriver) || false
                }
                {...register('isDelayDriver')}
              />
              <CheckBox
                label="AOG related"
                defaultChecked={(isEdit && editFormData?.isAogDriver) || false}
                {...register('isAogDriver')}
              />
            </div>
            <div css={{ marginTop: '1.5rem', flex: 1 }} />
            {(isANL || isTR) && (
              <FormTextBox
                label="Approver (GalaCXy ID)*"
                dataField="assignee"
                control={control}
                disabled={isEdit}
              />
            )}
            <div
              css={{
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormMultiSelect
                label="Audience/Recipient Groups"
                dataArr={AUDIENCE_GROUP_ARR}
                control={control}
                dataField="audienceGroupArr"
              />
            </div>
            <div
              css={{
                marginTop: '2.5rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <CheckBox
                label="Urgent - Request approval ASAP"
                disabled={isEdit}
                defaultChecked={(isEdit && editFormData?.isUrgent) || false}
                {...register('isUrgent')}
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div css={{ flex: 1 }}></div>
          {!isSubmitting && (
            <CXButton type="submit" variant="primary">
              Submit
            </CXButton>
          )}
          {isSubmitting && (
            <SpinningLoader
              customTextStyles={{
                fontStyle: 'italic',
              }}
            />
          )}
        </FormRow>
        {!isEdit && isSubmitting && (
          <FormRow>
            <div css={{ flex: 1 }} />
            <span>Document upload will take approximately 1 minute.</span>
          </FormRow>
        )}
      </form>
    </CommonModal>
  );
};

export default UploadDocumentModal;
