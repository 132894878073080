/** @jsxImportSource @emotion/react */
import { Svg, SvgComponents } from 'components';

const SortingIcon = ({
  isSorted,
  isSortedDesc,
}: {
  isSorted: boolean;
  isSortedDesc: boolean | undefined;
}): JSX.Element => {
  let src: keyof typeof SvgComponents = 'sorting';

  if (isSorted) {
    if (isSortedDesc) {
      src = 'sorting-asc';
    } else {
      src = 'sorting-desc';
    }
  }

  return (
    <span
      css={{
        marginLeft: '0.25rem',
      }}
    >
      <Svg icon={src} size={1.2} />
    </span>
  );
};
export default SortingIcon;
