/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import { CancelExpiredTag } from 'components';
import { convertApprovalStatus } from 'helpers';
import { COLORS } from 'helpers/colors';
import { WORK_FLOW_STATUS } from 'helpers/constants';
import {
  IApprovalStatus,
  ICancelStatus,
  IStageApproval,
} from 'models/SearchData';

const ApprovalStatus = ({
  values,
  status,
  cancelStatus,
  isExpired,
  isCancellation,
}: {
  values: IStageApproval;
  status: string;
  cancelStatus?: string;
  isExpired?: boolean;
  isCancellation?: boolean;
}): JSX.Element => {
  let cancellationStatus;
  if (values && cancelStatus) {
    cancellationStatus = convertApprovalStatus(
      cancelStatus,
      values,
      isCancellation,
    ) as ICancelStatus[];
  }

  const approvalStatus = convertApprovalStatus(
    status,
    values,
  ) as IApprovalStatus[];

  return isExpired ? (
    <CancelExpiredTag isExpired />
  ) : status === WORK_FLOW_STATUS.CANCELLED.code ? (
    <CancelExpiredTag />
  ) : (
    <div css={{ display: 'flex', flexDirection: 'row' }}>
      {cancellationStatus
        ? cancellationStatus.map((status, idx: number) => {
            let backgroundColor = COLORS.yellow2;
            let textColor = COLORS.yellow;

            if (status.isApproved === true) {
              backgroundColor = COLORS.green3;
              textColor = COLORS.green;
            } else if (status.isApproved === false) {
              backgroundColor = COLORS.red2;
              textColor = COLORS.red;
            }

            return (
              <div
                key={idx}
                css={{
                  width: '2rem',
                  height: '2rem',
                  backgroundColor,
                  borderRadius: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '0.25rem',
                }}
              >
                <span
                  css={{
                    color: textColor,
                    fontSize: '0.625rem',
                    fontWeight: 'bold',
                    lineHeight: '13px',
                  }}
                >
                  {status.type}
                </span>
              </div>
            );
          })
        : approvalStatus.map((status, idx: number) => {
            if (status.isPublished) {
              return (
                <div
                  key={idx}
                  css={{
                    padding: '0.25rem',
                    backgroundColor: COLORS.green,
                    borderRadius: '4px',
                  }}
                >
                  <span
                    css={{
                      fontSize: '0.75rem',
                      fontWeight: '500',
                      lineHeight: '14px',
                      color: COLORS.white,
                    }}
                  >
                    {WORK_FLOW_STATUS.PUBLISHED.shortName}
                  </span>
                </div>
              );
            }

            let backgroundColor = COLORS.grey10;
            let textColor = COLORS.grey;

            if (status.isApproved === true) {
              backgroundColor = COLORS.green3;
              textColor = COLORS.green;
            } else if (status.isApproved === false) {
              backgroundColor = COLORS.red2;
              textColor = COLORS.red;
            }

            return (
              <div
                key={idx}
                css={{
                  width: '2rem',
                  height: '2rem',
                  backgroundColor,
                  borderRadius: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '0.25rem',
                }}
              >
                <span
                  css={{
                    color: textColor,
                    fontSize: '0.625rem',
                    fontWeight: 'bold',
                    lineHeight: '13px',
                  }}
                >
                  {status.type}
                </span>
              </div>
            );
          })}
    </div>
  );
};

export default ApprovalStatus;
