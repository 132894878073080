/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React, { CSSProperties, ReactNode } from 'react';

import {
  ApprovalStatus,
  CancelExpiredTag,
  CustomLabel,
  Svg,
  UserGroupLabel,
} from 'components';
import serverConfig from 'config/serverConfig';
import { getRedirectUrl, isNonCXUser, mq } from 'helpers';
import { COLORS } from 'helpers/colors';
import { CUSTOM_LABELS, DOC_STATUS, WORK_FLOW_STATUS } from 'helpers/constants';
import { formatInDaysHoursMins, formatToDate } from 'helpers/date';
import { ISearchTableResult } from 'models/SearchData';

const StatusRow = ({
  item,
  dateDisplay,
  isViewer,
  isBookmark,
}: {
  item: ISearchTableResult;
  dateDisplay: string;
  isViewer: boolean;
  isBookmark?: boolean;
}): JSX.Element => {
  const isExpired = item.isExpired;

  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
      }}
    >
      <>
        <div
          css={{
            flex: 1,
            flexDirection: 'column',
            marginRight: '0.375rem',
            marginBottom: '0.75rem',
          }}
        >
          <div css={{ display: 'flex' }}>
            <CellItem
              title={isViewer ? 'Effective Date' : 'Submission Date'}
              value={dateDisplay}
              customStyles={{
                marginRight: '3rem',
                [mq[2]]: {
                  marginRight: '2rem',
                },
              }}
            />
            {isViewer ? (
              <>
                {!serverConfig.isDisableAog && (
                  <>
                    <CellItem
                      title="AOG Hours"
                      value={
                        item.aogDelayTimeMin
                          ? formatInDaysHoursMins(item.aogDelayTimeMin)
                          : '--'
                      }
                      flexNum={0.5}
                    />
                    <CellItem
                      title="A/C Reg"
                      value={item.aogTailRegistration || '--'}
                      flexNum={1}
                    />
                  </>
                )}
              </>
            ) : (
              <CellItem title="Creator" value={item.creator} flexNum={1} />
            )}

            {!isBookmark && (
              <div
                css={{
                  display: 'flex',
                  height: 'max-content',
                  flex: isExpired ? 1 : 'none',
                }}
              >
                {isExpired && <CancelExpiredTag isExpired />}
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

const ApprovalStatusRow = ({
  item,
  dateDisplay,
}: {
  item: ISearchTableResult;
  dateDisplay: string;
}): JSX.Element => {
  return item.isExpired ? (
    <StatusRow item={item} dateDisplay={dateDisplay} isViewer={false} />
  ) : (
    <div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
        }}
      >
        <div
          css={{
            flex: 1,
            flexDirection: 'column',
            marginRight: '0.375rem',
            marginBottom: '0.75rem',
          }}
        >
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                flex: 1,
              }}
            >
              <div
                css={{
                  textAlign: 'start',
                  fontSize: '0.75rem',
                  color: COLORS.grey4,
                  marginBottom: '0.1875rem',
                }}
              >
                Approval Status
              </div>
              <div css={{ display: 'flex' }}>
                <ApprovalStatus
                  values={
                    item.cancellationStatus
                      ? item.cancellationStatus
                      : item.approvalStatus
                  }
                  status={item.status}
                  cancelStatus={item.cancelStatus}
                  isCancellation={!!item.cancellationStatus}
                />
                {item.isUrgent &&
                item.status !== WORK_FLOW_STATUS.PUBLISHED.code ? (
                  <CustomLabel
                    labelType={CUSTOM_LABELS.URGENT_SUBMISSION}
                    isUrgent={true}
                  />
                ) : null}
              </div>
            </div>

            <div css={{ display: 'flex', flex: 1 }}>
              <CellItem
                title="Submit Date"
                value={dateDisplay}
                flexNum={1}
                customStyles={{ padding: '0 0.5rem' }}
              />
              <CellItem title="Creator" value={item.creator} flexNum={1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CellItem = ({
  title,
  value,
  flexNum,
  customStyles,
  children,
}: {
  title: string;
  value: string;
  flexNum?: number;
  customStyles?: CSSProperties;
  children?: ReactNode;
}): JSX.Element => {
  return (
    <div
      css={{
        flex: flexNum ? flexNum : 'none',
        flexDirection: 'column',
        ...customStyles,
      }}
    >
      <div
        css={{ textAlign: 'start', fontSize: '0.75rem', color: COLORS.grey4 }}
      >
        {title}
      </div>
      <div
        css={{
          textAlign: 'start',
          fontSize: '0.875rem',
          height: 'auto',
          overflowWrap: 'break-word',
        }}
      >
        {value}
      </div>
      {children}
    </div>
  );
};

interface LabelWrapElementProps {
  children?: React.ReactNode;
}

const MobileResultItem = ({
  item,
  isViewer,
  isBookmark,
  isApprover,
  userGroup,
  rowOnClick,
  favoriteDoc,
  handleMobileDocNoteClick,
}: {
  item: ISearchTableResult;
  isViewer: boolean;
  isBookmark?: boolean;
  isApprover: boolean;
  userGroup?: string;
  rowOnClick: (
    e: React.MouseEvent,
    id: string,
    fileName: string,
    docType?: string,
  ) => void;
  favoriteDoc: (documentId: string, isFavorite: boolean) => Promise<void>;
  handleMobileDocNoteClick: (item: ISearchTableResult) => void;
}): JSX.Element => {
  const isCancelled = item.status === WORK_FLOW_STATUS.CANCELLED.code;

  let bgColor = COLORS.white;
  if (item.status !== undefined && (item.isExpired || isCancelled)) {
    bgColor = COLORS.grey12;
  } else if (
    item.status !== undefined &&
    item.status === DOC_STATUS.IS_NEW.name
  ) {
    bgColor = COLORS.green2;
  }

  const redirectUrl = getRedirectUrl({
    id: item._id,
    fileName: item.fileName,
    isApprover,
    isViewer,
    docType: item.docType,
    userGroup,
  });

  const LabelWrapElement: React.FC<LabelWrapElementProps> = ({
    children,
  }: LabelWrapElementProps): JSX.Element => {
    return (
      <div
        css={{
          marginRight: '0.1rem',
          display: 'flex',
        }}
      >
        <div
          css={{
            fontSize: '0.1rem',
            height: '1.5rem',
            width: '1rem',
            paddingTop: '0.25rem',
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  let dateDisplay = '';

  const dateField = isViewer ? item.effectiveDate : item.submissionDate;

  if (dateField) {
    dateDisplay = formatToDate(dateField);
  }

  return (
    <div
      css={{
        cursor: 'pointer',
        backgroundColor: bgColor,
        borderColor: COLORS.grey7,
        borderWidth: '0.0625rem',
        borderRadius: '0.25rem',
        borderStyle: 'solid',
        paddingLeft: '0.5rem',
        paddingTop: '0.75rem',
        marginBottom: '0.5rem',
        ...(isViewer &&
          !item.isRead && {
            borderLeft: `3px solid ${COLORS.green}`,
          }),
      }}
    >
      <a
        css={{
          ':hover': {
            textDecoration: 'none',
          },
        }}
        onClick={(e: React.MouseEvent) =>
          rowOnClick(e, item._id, item.fileName, item.docType)
        }
        href={redirectUrl}
      >
        <>
          {!isViewer && (
            <ApprovalStatusRow item={item} dateDisplay={dateDisplay} />
          )}
          {isViewer && (
            <StatusRow
              item={item}
              dateDisplay={dateDisplay}
              isViewer={isViewer}
              isBookmark={isBookmark}
            />
          )}
          <div
            css={{
              flex: 1,
              flexDirection: 'column',
              marginBottom: '0.8125rem',
            }}
          >
            <div
              css={{
                textAlign: 'start',
                fontSize: '0.75rem',
                color: COLORS.grey4,
              }}
            >
              Title
            </div>
            {item.isExpired && item.supersededByArr.length > 0 ? (
              <div
                css={{
                  color: COLORS.grey3,
                  textAlign: 'start',
                  fontSize: '0.625rem',
                  overflowWrap: 'break-word',
                  fontWeight: '400',
                }}
              >
                {`Superseded by ${item.supersededByArr.join(', ')}`}
              </div>
            ) : null}
            <div
              css={{
                color: COLORS.grey3,
                textAlign: 'start',
                fontSize: '0.875rem',
                overflowWrap: 'break-word',
                fontWeight: '700',
              }}
            >
              {item.title}
            </div>
            {!item.isExpired && item.supersedeArr.length > 0 ? (
              <div
                css={{
                  color: COLORS.grey3,
                  textAlign: 'start',
                  fontSize: '0.625rem',
                  overflowWrap: 'break-word',
                  fontWeight: '400',
                }}
              >
                {`Supersedes ${item.supersedeArr.join(', ')}`}
              </div>
            ) : null}
            {isBookmark && (item.isExpired || isCancelled) && (
              <span
                css={{
                  display: 'flex',
                }}
              >
                {item.isExpired && <CancelExpiredTag isExpired />}
                {isCancelled && <CancelExpiredTag />}
              </span>
            )}
          </div>
          <div></div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {(item.isAdRelated ||
              item.isMorRelated ||
              item.isAogDriver ||
              item.isDelayDriver ||
              (item.aogIsRtb && !serverConfig.isDisableAog)) && (
              <div
                css={{
                  display: 'flex',
                  width: '3rem',
                  marginRight: '5rem',
                  flexWrap: 'wrap',
                  [mq[2]]: {
                    marginRight: '3.7rem',
                  },
                }}
              >
                {item.isMorRelated && (
                  <LabelWrapElement>
                    <CustomLabel labelType={CUSTOM_LABELS.MOR_RELATED} />
                  </LabelWrapElement>
                )}
                {item.isAdRelated && (
                  <LabelWrapElement>
                    <CustomLabel labelType={CUSTOM_LABELS.AD_RELATED} />
                  </LabelWrapElement>
                )}
                {item.isDelayDriver && (
                  <LabelWrapElement>
                    <CustomLabel labelType={CUSTOM_LABELS.DELAY_DRIVER} />
                  </LabelWrapElement>
                )}
                {item.isAogDriver && (
                  <LabelWrapElement>
                    <CustomLabel
                      customStyles={{
                        fontSize: '0.75rem',
                        width: 'auto',
                        color: COLORS.black,
                      }}
                      labelType={CUSTOM_LABELS.AOG_DRIVER}
                    />
                  </LabelWrapElement>
                )}
                {item.aogIsRtb && (
                  <LabelWrapElement>
                    <CustomLabel
                      customStyles={{
                        fontSize: '0.75rem',
                        width: 'auto',
                        padding: '0.2rem',
                      }}
                      labelType={CUSTOM_LABELS.RETURN_TO_BLOCK}
                    />
                  </LabelWrapElement>
                )}
              </div>
            )}
            <CellItem
              title="ATA"
              value={item.ata.chapterArr?.join(', ') || ''}
              flexNum={1}
            />
            <CellItem title="Doc Type" value={item.docType} flexNum={1}>
              {item.userGroup && isNonCXUser(item.userGroup) && (
                <span>
                  <UserGroupLabel
                    userGroup={item.userGroup}
                    customTextStyles={{
                      fontSize: '0.5rem',
                    }}
                  />
                </span>
              )}
            </CellItem>
            <CellItem
              title="Doc Ref"
              value={item.documentNumber || '--'}
              flexNum={1}
            />
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              marginTop: '0.8125rem',
              marginBottom: isViewer ? '0.5rem' : '1.25rem',
            }}
          >
            <CellItem
              title="Fleet"
              value={item.fleetArr.join(', ')}
              flexNum={2}
            />
          </div>
        </>
      </a>
      {item.isAllowFavoriteNote && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '1rem',
          }}
        >
          <div
            role="presentation"
            css={{
              marginBottom: '1rem',
            }}
            onClick={async () => {
              await favoriteDoc(item._id, !item.isFavorite);
            }}
          >
            <Svg icon={item.isFavorite ? 'favorite-active' : 'favorite'} />
          </div>
          <div
            role="presentation"
            onClick={() => handleMobileDocNoteClick(item)}
            css={{
              textAlign: 'center',
            }}
          >
            <span
              css={{
                background: COLORS.white,
                border: `1px solid ${COLORS.grey8}`,
                borderRadius: '4px',
                padding: '0.5rem',
                fontSize: ' 0.625rem',
                fontWeight: '600',
                color: COLORS.grey,
                '&:hover': {
                  background: COLORS.white4,
                },
                ...(item.note && {
                  color: COLORS.blue5,
                  border: `0.063rem solid ${COLORS.blue5}`,
                  '&:hover': {
                    background: COLORS.green3,
                  },
                }),
              }}
            >
              Notes
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default MobileResultItem;
