/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { CSSProperties } from 'react';
import { Controller } from 'react-hook-form';

import {
  ErrorMessageWrapper,
  ISourceData,
  ISourceGroupData,
  Select,
} from 'components';

import { CommonFormControlProps } from '../../@types/formControl';

interface FormMultiSelectProps<T> extends CommonFormControlProps<T> {
  dataArr: ISourceData[] | ISourceGroupData[];
  isGroup?: boolean;
  selectAll?: boolean;
  customContainerStyles?: CSSProperties;
}

const FormMultiSelect = <T,>({
  label,
  dataArr,
  dataField,
  control,
  rules,
  isGroup = false,
  selectAll = true,
  customContainerStyles,
}: FormMultiSelectProps<T>): JSX.Element => {
  return (
    <div
      css={{
        flex: 1,
        fontSize: '0.875rem',
        ...customContainerStyles,
      }}
    >
      <Controller
        name={dataField}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState: { errors },
        }) => (
          <>
            <Select
              label={label || ''}
              hasError={!!error}
              value={value as string[]}
              settings={{
                isTag: true,
                selectAll,
                isGroupingData: isGroup,
                isMultiSelect: true,
              }}
              dataSource={dataArr}
              onMultiSelect={onChange}
            />
            <ErrorMessageWrapper dataField={dataField} errors={errors} />
          </>
        )}
      />
    </div>
  );
};

export default FormMultiSelect;
