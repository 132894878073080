/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';

import {
  AssigneeSelect,
  ButtonCheckBox,
  CXButton,
  CreatorSelect,
  FormDatePicker,
  FormMultiSelect,
  FormTextBox,
  Svg,
  notify,
} from 'components';
import serverConfig from 'config/serverConfig';
import {
  getAllDocTypeArr,
  localStorageGetItem,
  localStorageSetItem,
  mq,
  useIsMounted,
} from 'helpers';
import {
  postApproveData,
  postSearchData,
  postViewerSearchData,
} from 'helpers/apiCaller';
import { COLORS } from 'helpers/colors';
import {
  ATA_CHAPTER_ARR,
  DOCUMENT_TYPE,
  EXTRA_STATUS,
  FLEET_ARR,
  SEARCH_PARAMS,
  STATUS_ARR,
  USER_GROUP,
  USER_ROLE,
} from 'helpers/constants';
import {
  ISearchReqData,
  ISearchResult,
  ISearchTableResult,
} from 'models/SearchData';
import ProfileContext from 'store/ProfileContext';

const FormRow = ({ children }: { children: React.ReactNode }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    {children}
  </div>
);

interface SearchBarProps {
  setData: (result: ISearchTableResult[]) => void;
  setSearchingLoading: (state: boolean) => void;
  openModalHandler: () => void;
  isViewer: boolean;
  isApprover: boolean;
  isModalOpen: boolean;
  isNotesModalOpen: boolean;
  isBookmark?: boolean;
  userGroup?: string;
}

const SearchBar = ({
  setData,
  setSearchingLoading,
  openModalHandler,
  isViewer,
  isApprover,
  isBookmark,
  isModalOpen,
  isNotesModalOpen,
  userGroup,
}: SearchBarProps): JSX.Element => {
  const searchParamKey = isBookmark
    ? SEARCH_PARAMS.BOOKMARK
    : isViewer
    ? SEARCH_PARAMS.VIEWER
    : isApprover
    ? SEARCH_PARAMS.APPROVER
    : SEARCH_PARAMS.AUTHOR;

  const [isOpen, setIsOpen] = useState(false);

  const { galacxyId, userRoles } = useContext(ProfileContext);

  const isMounted = useIsMounted();

  const searchDefaultValues: ISearchReqData = {
    keywords: '',
    dateFrom: '',
    dateTo: '',
    isFavorite: false,
    isNote: false,
    fleetArr: [],
    docTypeArr: [],
    ataChapterArr: [],
    creatorArr: [],
    statusArr: [],
    extraStatusArr: [],
    assigneeArr: [],
    userGroupArr: [],
  };

  const currentSearchParams = localStorageGetItem(
    searchParamKey,
  ) as ISearchReqData;

  const { handleSubmit, control, reset, setValue, getValues, register, watch } =
    useForm<ISearchReqData>({
      defaultValues: searchDefaultValues,
    });

  const getPageData = useCallback(
    async (isSubmitMode: boolean, paramData: ISearchReqData) => {
      setSearchingLoading(true);
      let resp: ISearchResult[] = [];

      if (isViewer) {
        resp = await postViewerSearchData(paramData, userGroup, isBookmark);
      }

      if (isApprover) {
        resp = await postApproveData(paramData);
      }

      if (!isViewer && !isApprover) {
        resp = await postSearchData(paramData, userGroup);
      }
      if (isMounted()) {
        if (resp) {
          let tableArr = JSON.parse(
            JSON.stringify(resp),
          ) as ISearchTableResult[];
          if (serverConfig.isDisableAog) {
            tableArr = tableArr.filter(
              (d) => d.docType !== DOCUMENT_TYPE.AOG.name,
            );
          }
          setData(tableArr);
          setSearchingLoading(false);

          if (isSubmitMode) {
            notify({ message: 'Search successful', type: 'success' });
          }
        } else {
          setData([]);
          setSearchingLoading(false);

          if (isSubmitMode) {
            notify({
              message: `Cannot find any documents`,
              type: 'error',
            });
          }
        }
      }
    },
    [isApprover, isViewer, setData, setSearchingLoading, userGroup, isBookmark],
  );

  useEffect(() => {
    if (currentSearchParams) {
      reset(currentSearchParams);
    }
  }, []);

  const onSubmit: SubmitHandler<ISearchReqData> = async (data) => {
    setSearchingLoading(true);
    localStorageSetItem(searchParamKey, data);
    await getPageData(true, data);
  };

  const onError: SubmitErrorHandler<ISearchReqData> = (errors) => {
    console.log('showing error', errors);
    notify({ message: 'Cannot find any documents', type: 'error' });
  };

  useEffect(() => {
    if (isModalOpen || !galacxyId || isNotesModalOpen) {
      return;
    }

    const currentUserArr: string[] = [];

    const currentGroupArr: string[] = [];

    let searchFormValues: ISearchReqData = {};

    if (isMounted() && !currentSearchParams) {
      if (!isViewer && !isApprover) {
        currentUserArr.push(galacxyId);
        setValue('creatorArr', currentUserArr, {
          shouldDirty: true,
        });
      }

      if (isApprover) {
        for (let i = 0; i <= userRoles.length; i++) {
          const role = userRoles[i];

          if (role === USER_ROLE.VIEWER.name) {
            continue;
          }

          if (
            role === USER_ROLE.LV_C_MANAGER.name ||
            role === USER_ROLE.ADMIN.name
          ) {
            currentGroupArr.push(galacxyId);
          }

          if (
            role === USER_ROLE.QA_MANAGER.name ||
            role === USER_ROLE.ADMIN.name
          ) {
            currentGroupArr.push(USER_ROLE.QA_MANAGER.name);
          }

          if (role === USER_ROLE.TPC.name || role === USER_ROLE.ADMIN.name) {
            currentGroupArr.push(USER_ROLE.TPC.name);
          }

          if (role === USER_ROLE.HK_OPS.name || role === USER_ROLE.ADMIN.name) {
            currentGroupArr.push(USER_ROLE.HK_OPS.name);
          }
        }

        setValue('assigneeArr', currentGroupArr, { shouldDirty: true });
      }

      searchFormValues = {
        creatorArr: currentUserArr,
        assigneeArr: currentGroupArr,
      };
    }

    if (currentSearchParams) {
      searchFormValues = {
        ...getValues(),
      };
    }

    void getPageData(false, searchFormValues);
  }, [
    getPageData,
    isViewer,
    galacxyId,
    setValue,
    isModalOpen,
    isNotesModalOpen,
    isApprover,
    userRoles,
    isMounted,
  ]);

  const setValues = () => {
    reset(searchDefaultValues);
  };

  return (
    <div
      css={{
        paddingTop: '1.5rem',
        paddingBottom: '2.5rem',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormRow>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              [mq[1]]: {
                flexDirection: 'column',
              },
              flex: 1,
            }}
          >
            <div
              css={{
                display: 'flex',
                flex: 2,
              }}
            >
              <div
                css={{
                  [mq[1]]: {
                    flex: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                  flex: 1,
                }}
              >
                <FormTextBox
                  label="Search keywords (content only)"
                  dataField="keywords"
                  control={control}
                />
              </div>
              <div
                css={{
                  display: 'none',
                  [mq[1]]: {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                  height: '3.125rem',
                  marginLeft: '0.75rem',
                }}
              >
                <div
                  css={{
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    backgroundColor: isOpen ? COLORS.grey : COLORS.white,
                    borderRadius: '0.25rem',
                    borderColor: COLORS.grey7,
                    borderWidth: '0.0625rem',
                    borderStyle: 'solid',
                  }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  role="presentation"
                >
                  <span
                    css={{
                      cursor: 'pointer',
                    }}
                  >
                    <Svg icon={isOpen ? 'filter-expand' : 'filter-collapse'} />
                  </span>
                </div>
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                marginLeft: '0.7rem',
                [mq[1]]: {
                  flexDirection: 'row',
                  marginTop: '0.5rem',
                  marginLeft: '0',
                  display: isOpen ? 'flex' : 'none',
                },
                flex: 2,
              }}
            >
              <FormDatePicker
                label="Date From"
                dataField="dateFrom"
                control={control}
                defaultValue={undefined}
              />
              <FormDatePicker
                label="Date To"
                dataField="dateTo"
                control={control}
                defaultValue={undefined}
              />
            </div>

            <div
              css={{
                display: 'flex',
                marginLeft: '0.7rem',
                [mq[1]]: {
                  flexDirection: 'row',
                  marginTop: '0.5rem',
                  marginLeft: '0',
                  display: isOpen ? 'flex' : 'none',
                },
                flex: isBookmark ? 1 : 2,
              }}
            >
              {!isBookmark && (
                <>
                  <div
                    css={{
                      display: 'flex',
                      flex: 1,
                      marginRight: '0.5rem',
                      [mq[1]]: {
                        marginLeft: '0',
                      },
                    }}
                  >
                    <ButtonCheckBox
                      label="Favourited"
                      icon="filter-favorite"
                      activeIcon="filter-favorite-active"
                      defaultValue={watch('isFavorite')}
                      {...register('isFavorite')}
                    />
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      flex: 1,
                    }}
                  >
                    <ButtonCheckBox
                      label="Notes"
                      defaultValue={watch('isNote')}
                      {...register('isNote')}
                    />
                  </div>
                </>
              )}
            </div>

            {!isViewer && !isApprover && (
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingLeft: '2rem',
                  [mq[1]]: {
                    display: 'block',
                    paddingLeft: '0rem',
                  },
                }}
              >
                <CXButton
                  customStyles={{
                    minWidth: '12rem',
                    [mq[0]]: {
                      fontSize: '0.75rem',
                      minWidth: '7rem',
                      padding: '0.1rem 0.75rem',
                    },
                  }}
                  variant="primary"
                  onClick={openModalHandler}
                >
                  Upload New Document
                </CXButton>
              </div>
            )}
          </div>
        </FormRow>
        <FormRow>
          <div
            css={{
              flex: 1,
              borderBottom: `1px solid ${COLORS.grey7}`,
            }}
          >
            <div
              css={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                [mq[1]]: {
                  flexDirection: 'column',
                },
              }}
            >
              {/* Expand / Collapse */}
              <div
                css={{
                  marginTop: '0.725rem',
                  marginBottom: '0.725rem',
                  display: 'flex',
                  flexDirection: 'row',
                  [mq[1]]: {
                    marginTop: '0.5rem',
                    marginBottom: '0rem',
                    flexDirection: 'column',
                  },
                  flex: 4,
                }}
              >
                <FormMultiSelect
                  label="Fleet"
                  dataArr={FLEET_ARR.map((x) => ({
                    value: x,
                    name: x,
                  }))}
                  control={control}
                  dataField="fleetArr"
                />
                <div
                  css={{
                    width: '0.7rem',
                    [mq[1]]: {
                      marginTop: '0.5rem',
                    },
                  }}
                />
                <FormMultiSelect
                  label="Doc Type"
                  dataArr={getAllDocTypeArr(userGroup, isViewer)}
                  control={control}
                  dataField="docTypeArr"
                />
                <div
                  css={{
                    display: 'contents',
                    [mq[1]]: {
                      display: isOpen ? 'contents' : 'none',
                    },
                  }}
                >
                  <div
                    css={{
                      width: '0.7rem',
                      [mq[1]]: {
                        marginTop: '0.5rem',
                      },
                    }}
                  />
                  <FormMultiSelect
                    label="User Group"
                    dataField="userGroupArr"
                    control={control}
                    dataArr={Object.values(USER_GROUP)
                      .filter((x) => x.value !== USER_GROUP.NONE.value)
                      .map((userGroup) => ({
                        name: userGroup.display,
                        value: userGroup.value,
                      }))}
                  />
                </div>
                {!isApprover && (
                  <>
                    <div
                      css={{
                        width: '0.7rem',
                        [mq[1]]: {
                          marginTop: '0.5rem',
                          [mq[1]]: {
                            display: isOpen ? 'flex' : 'none',
                          },
                        },
                      }}
                    />
                    <FormMultiSelect
                      label="ATA"
                      dataArr={ATA_CHAPTER_ARR.map((x) => ({
                        value: x.value,
                        name: x.value === 'ALL' ? x.name : `${x.value}`,
                      }))}
                      control={control}
                      dataField="ataChapterArr"
                      customContainerStyles={{
                        [mq[1]]: {
                          display: isOpen ? 'initial' : 'none',
                        },
                      }}
                    />
                  </>
                )}
                <div
                  css={{
                    display: 'contents',
                    [mq[1]]: {
                      display: isOpen ? 'initial' : 'none',
                    },
                  }}
                >
                  {isApprover && (
                    <>
                      <div
                        css={{
                          width: '0.7rem',
                          [mq[1]]: {
                            marginTop: '0.5rem',
                          },
                        }}
                      />
                      <AssigneeSelect
                        control={control}
                        dataField="assigneeArr"
                      />
                    </>
                  )}
                  {!isViewer && (
                    <>
                      <div
                        css={{
                          width: '0.7rem',
                          [mq[1]]: {
                            marginTop: '0.5rem',
                          },
                        }}
                      />
                      <CreatorSelect control={control} dataField="creatorArr" />
                    </>
                  )}
                </div>
                <div
                  css={{
                    width: '0.7rem',
                    [mq[1]]: {
                      marginTop: '0.5rem',
                      display: isOpen ? 'flex' : 'none',
                    },
                  }}
                />
                <FormMultiSelect
                  label="Status"
                  dataArr={STATUS_ARR.filter((x) =>
                    isBookmark ? x.isBookmark : isViewer ? x.isViewer : true,
                  ).map((x) => ({
                    name: x.name,
                    value: x.value,
                  }))}
                  control={control}
                  dataField="statusArr"
                  customContainerStyles={{
                    [mq[1]]: {
                      display: isOpen ? 'initial' : 'none',
                    },
                  }}
                />
                <div
                  css={{
                    width: '0.7rem',
                    [mq[1]]: {
                      marginTop: '0.5rem',
                      display: isOpen ? 'flex' : 'none',
                    },
                  }}
                />

                <FormMultiSelect
                  label="Label"
                  dataArr={EXTRA_STATUS.map(
                    (x: { text: string; value: string }) => ({
                      value: x.value,
                      name: x.text,
                    }),
                  )}
                  control={control}
                  dataField="extraStatusArr"
                  customContainerStyles={{
                    [mq[1]]: {
                      display: isOpen ? 'initial' : 'none',
                    },
                  }}
                />
              </div>
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  [mq[1]]: {
                    marginTop: isOpen ? '0rem' : '1rem',
                    flexDirection: 'column-reverse',
                  },
                }}
              >
                <div
                  css={{
                    width: '0.7rem',
                    [mq[1]]: {
                      marginTop: '0.5rem',
                    },
                  }}
                />

                <CXButton
                  customStyles={{
                    marginTop: '0.8rem',
                    flex: 1,
                    [mq[0]]: {
                      minWidth: '6rem',
                    },
                    [mq[1]]: {
                      marginTop: 0,
                    },
                  }}
                  type="submit"
                  variant="primary-outline"
                >
                  Search
                </CXButton>
                <button
                  type="button"
                  css={{
                    backgroundColor: COLORS.white,
                    border: '0',
                    marginLeft: '0.75rem',
                    fontSize: '0.8rem',
                    color: COLORS.blue5,
                    flex: 1,
                    [mq[1]]: {
                      margin: '1rem',
                      textAlign: isOpen ? 'right' : 'center',
                      display: isOpen ? 'block' : 'none',
                    },
                  }}
                  onClick={() => {
                    setValues();
                  }}
                >
                  Clear all filters
                </button>
              </div>
            </div>
          </div>
        </FormRow>
      </form>
    </div>
  );
};

export default SearchBar;
